import { bindActionCreators } from "redux"

function request(actionType) {
    return {
        type: actionType,
    }
}
function success(actionType, response) {
    return {
        type: actionType,
        payload: response
    }
}
function failure(actionType, error) {
    return {
        type: actionType,
        payload: error
    }
}

const NEW_PATIENT = {
    ADD_NEW_PATIENT_REQUEST: "ADD_NEW_PATIENT_REQUEST",
    ADD_NEW_PATIENT_SUCCESS: "ADD_NEW_PATIENT_SUCCESS",
    ADD_NEW_PATIENT_FAILURE: "ADD_NEW_PATIENT_FAILURE",

    VERIFY_NEW_PATIENT_OTP_REQUEST: "VERIFY_NEW_PATIENT_OTP_REQUEST",
    VERIFY_NEW_PATIENT_OTP_SUCCESS: "VERIFY_NEW_PATIENT_OTP_SUCCESS",
    VERIFY_NEW_PATIENT_OTP_FAILURE: "VERIFY_NEW_PATIENT_OTP_FAILURE",
}
const LOGIN = {
    POST_LOGIN_REQUEST:'POST_LOGIN_REQUEST',
    POST_LOGIN_SUCCESS:'POST_LOGIN_SUCCESS',
    POST_LOGIN_FAILURE:'POST_LOGIN_FAILURE',
    
    POST_LOGIN_VERIFY_REQUEST:'POST_LOGIN_VERIFY_REQUEST',
    POST_LOGIN_VERIFY_SUCCESS:'POST_LOGIN_VERIFY_SUCCESS',
    POST_LOGIN_VERIFY_FAILURE:'POST_LOGIN_VERIFY_FAILURE',

    
}
const GET_PLANS = {
    GET_PLANS_REQUEST: "GET_PLANS_REQUEST",
    GET_PLANS_SUCCESS: "GET_PLANS_SUCCESS",
    GET_PLANS_FAILURE: "GET_PLANS_FAILURE",
 
    GET_PLANS_BY_ID_REQUEST: "GET_PLANS_BY_ID_REQUEST",
    GET_PLANS_BY_ID_SUCCESS: "GET_PLANS_BY_ID_SUCCESS",
    GET_PLANS_BY_ID_FAILURE: "GET_PLANS_BY_ID_FAILURE",
}
const PAYMENT={
    ADD_NEW_PAYMENT_REQUEST:"ADD_NEW_PAYMENT_REQUEST",
    ADD_NEW_PAYMENT_SUCCESS:"ADD_NEW_PAYMENT_SUCCESS",
    ADD_NEW_PAYMENT_FAILURE:"ADD_NEW_PAYMENT_FAILURE",

    PUT_NEW_PAYMENT_REQUEST:"PUT_NEW_PAYMENT_REQUEST",
    PUT_NEW_PAYMENT_SUCCESS:"PUT_NEW_PAYMENT_SUCCESS",
    PUT_NEW_PAYMENT_FAILURE:"PUT_NEW_PAYMENT_FAILURE",
}
const DASHBOARD={
    GET_PATIENT_RECORD_REQUEST:"GET_PATIENT_RECORD_REQUEST",
    GET_PATIENT_RECORD_SUCCESS:"GET_PATIENT_RECORD_SUCCESS",
    GET_PATIENT_RECORD_FAILURE:"GET_PATIENT_RECORD_FAILURE",
}
const CARDDETAILS={
    GET_CARDS_DETAILS_REQUEST:"GET_CARDS_DETAILS_REQUEST",
    GET_CARDS_DETAILS_SUCCESS:"GET_CARDS_DETAILS_SUCCESS",
    GET_CARDS_DETAILS_FAILURE:"GET_CARDS_DETAILS_FAILURE",
}
const SUBSCRIPTION={
    GET_SUBSCRIPTION_REQUEST:"GET_SUBSCRIPTION_REQUEST",
    GET_SUBSCRIPTION_SUCCESS:"GET_SUBSCRIPTION_SUCCESS",
    GET_SUBSCRIPTION_FAILURE:"GET_SUBSCRIPTION_FAILURE",

    DEL_SUBSCRIPTION_REQUEST:"DEL_SUBSCRIPTION_REQUEST",
    DEL_SUBSCRIPTION_SUCCESS:"DEL_SUBSCRIPTION_SUCCESS",
    DEL_SUBSCRIPTION_FAILURE:"DEL_SUBSCRIPTION_FAILURE",
}

const UPDATE_USER={
    SEND_USER_EMAIL_OTP_REQUEST: "SEND_USER_EMAIL_OTP_REQUEST",
    SEND_USER_EMAIL_OTP_SUCCESS: "SEND_USER_EMAIL_OTP_SUCCESS",
    SEND_USER_EMAIL_OTP_FAILURE: "SEND_USER_EMAIL_OTP_FAILURE",

    VERIFY_USER_EMAIL_OTP_REQUEST: "VERIFY_USER_EMAIL_OTP_REQUEST",
    VERIFY_USER_EMAIL_OTP_SUCCESS: "VERIFY_USER_EMAIL_OTP_SUCCESS",
    VERIFY_USER_EMAIL_OTP_FAILURE: "VERIFY_USER_EMAIL_OTP_FAILURE",

    UPDATE_USER_EMAIL_OTP_REQUEST: "UPDATE_USER_EMAIL_OTP_REQUEST",
    UPDATE_USER_EMAIL_OTP_SUCCESS: "UPDATE_USER_EMAIL_OTP_SUCCESS",
    UPDATE_USER_EMAIL_OTP_FAILURE: "UPDATE_USER_EMAIL_OTP_FAILURE",

    //Phone otp actions
    SEND_USER_PHONE_OTP_REQUEST: "SEND_USER_PHONE_OTP_REQUEST",
    SEND_USER_PHONE_OTP_SUCCESS: "SEND_USER_PHONE_OTP_SUCCESS",
    SEND_USER_PHONE_OTP_FAILURE: "SEND_USER_PHONE_OTP_FAILURE",

    VERIFY_USER_PHONE_OTP_REQUEST: "VERIFY_USER_PHONE_OTP_REQUEST",
    VERIFY_USER_PHONE_OTP_SUCCESS: "VERIFY_USER_PHONE_OTP_SUCCESS",
    VERIFY_USER_PHONE_OTP_FAILURE: "VERIFY_USER_PHONE_OTP_FAILURE",

    UPDATE_USER_PHONE_OTP_REQUEST: "UPDATE_USER_PHONE_OTP_REQUEST",
    UPDATE_USER_PHONE_OTP_SUCCESS: "UPDATE_USER_PHONE_OTP_SUCCESS",
    UPDATE_USER_PHONE_OTP_FAILURE: "UPDATE_USER_PHONE_OTP_FAILURE",
}

const USER_DETAILS={
    GET_USER_DETAILS_REQUEST: "GET_USER_DETAILS_REQUEST",
    GET_USER_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",
    GET_USER_DETAILS_FAILURE: "GET_USER_DETAILS_FAILURE",
    
    PUT_USER_DETAILS_REQUEST: "PUT_USER_DETAILS_REQUEST",
    PUT_USER_DETAILS_SUCCESS: "PUT_USER_DETAILS_SUCCESS",
    PUT_USER_DETAILS_FAILURE: "PUT_USER_DETAILS_FAILURE",
}

export {
    request,
    success,
    failure,
    NEW_PATIENT,
    GET_PLANS,
    USER_DETAILS,
    PAYMENT,
    LOGIN,
    DASHBOARD,
    SUBSCRIPTION,
    UPDATE_USER,
    CARDDETAILS,
}