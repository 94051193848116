const ERROR = {
  SYSTEM_ERROR: "System error. Please try again later!",
};
const PATH = {
  NOPAGE: "*",
  LOGIN: "/",
  SIGNUP: "/SignUp",
  VERIFY: "/Verify",
  SUBSCRIPTION: "/subscription",
  DASHBOARD: '/Dashboard',
  PAYMENT: '/Payment',
  PROFILE: '/MyProfile',
  CHANGEPLAN: '/MyPlan',
  LOGOUT: '/Logout',
};

const baseUrl = "https://sageapis.azurewebsites.net/api/";
const APP_SETTINGS = {
  API_PATH: {
    LOGIN: {
      login: baseUrl + "login/post/patientLoginbyNumber",
      verifyLogin: baseUrl + "login/post/patientLoginbyNumberVerify",
    },
    SIGNUP: {
      addPatient: baseUrl + "register/patient",
      verifyOtp: baseUrl + "register/patient/verifyOTP",
    },
    CARDDETAILS: {
      getCardDetails: baseUrl + "userCard/get",
    },
    PLANS: {
      getPlansList: baseUrl + "plan/get",
      getPlansById: baseUrl + "plan/getSubscriptionPlanId/",
    },
    SUBSCRIPTION: {
      getSubscription: baseUrl + "subscription/get",
      deleteSubscription: baseUrl + "subscription/cancel",
    },
    PAYMENT: {
      addNewPayment: baseUrl + "subscription/post",
      putNewPayment: baseUrl + "subscription/put",
    },
    DASHBOARD: {
      getDashboardrecord: baseUrl + "subscription/get",
    },
    PATIENTPROFILE: {
      getPatientRecordById: baseUrl + "patient/get/",
      puPatientRecordById: baseUrl + "patient/put/",
      sendEmailOtp: baseUrl + "user/send/emailOTP",
      verifyEmailOtp: baseUrl + "user/verify/emailOTP/",
      updateEmail: baseUrl + "user/updateEmail",
      sendPhoneOtp: baseUrl + "user/send/phoneOTP",
      verifyPhoneOtp: baseUrl + "user/verify/phoneOTP/",
      updatePhone: baseUrl + "user/updatePhone",
    },
  },
};
const SALUTATION_LOOKUP = [
  {
    lookupId: 400,
    type: 'Salutations',
    text: 'Mr.',
    value: 'Mr.',
    order: 1
  },
  {
    lookupId: 401,
    type: 'Salutations',
    text: 'Mrs.',
    value: 'Mrs.',
    order: 2
  },
  {
    lookupId: 402,
    type: 'Salutations',
    text: 'Ms',
    value: 'Ms',
    order: 3
  }
]
const GENDER_LOOKUP = [
  {
    lookupId: 100,
    type: 'Genders',
    text: 'Male',
    value: 'Male',
    order: 1
  },
  {
    lookupId: 101,
    type: 'Genders',
    text: 'Female',
    value: 'Female',
    order: 2
  },
  {
    lookupId: 102,
    type: 'Genders',
    text: 'Transgender',
    value: 'Transgender',
    order: 3
  },
  {
    lookupId: 103,
    type: 'Genders',
    text: 'Not prefer to say',
    value: 'Prefer not to say',
    order: 4
  }
]
const STATES_LOOKUP = [
  {
    value: "Alabama",
    label: "AL"
  },
  {
    value: "Alaska",
    label: "AK"
  },
  {
    value: "American Samoa",
    label: "AS"
  },
  {
    value: "Arizona",
    label: "AZ"
  },
  {
    value: "Arkansas",
    label: "AR"
  },
  {
    value: "California",
    label: "CA"
  },
  {
    value: "Colorado",
    label: "CO"
  },
  {
    value: "Connecticut",
    label: "CT"
  },
  {
    value: "Delaware",
    label: "DE"
  },
  {
    value: "District Of Columbia",
    label: "DC"
  },
  {
    value: "Federated States Of Micronesia",
    label: "FM"
  },
  {
    value: "Florida",
    label: "FL"
  },
  {
    value: "Georgia",
    label: "GA"
  },
  {
    value: "Guam",
    label: "GU"
  },
  {
    value: "Hawaii",
    label: "HI"
  },
  {
    value: "Idaho",
    label: "ID"
  },
  {
    value: "Illinois",
    label: "IL"
  },
  {
    value: "Indiana",
    label: "IN"
  },
  {
    value: "Iowa",
    label: "IA"
  },
  {
    value: "Kansas",
    label: "KS"
  },
  {
    value: "Kentucky",
    label: "KY"
  },
  {
    value: "Louisiana",
    label: "LA"
  },
  {
    value: "Maine",
    label: "ME"
  },
  {
    value: "Marshall Islands",
    label: "MH"
  },
  {
    value: "Maryland",
    label: "MD"
  },
  {
    value: "Massachusetts",
    label: "MA"
  },
  {
    value: "Michigan",
    label: "MI"
  },
  {
    value: "Minnesota",
    label: "MN"
  },
  {
    value: "Mississippi",
    label: "MS"
  },
  {
    value: "Missouri",
    label: "MO"
  },
  {
    value: "Montana",
    label: "MT"
  },
  {
    value: "Nebraska",
    label: "NE"
  },
  {
    value: "Nevada",
    label: "NV"
  },
  {
    value: "New Hampshire",
    label: "NH"
  },
  {
    value: "New Jersey",
    label: "NJ"
  },
  {
    value: "New Mexico",
    label: "NM"
  },
  {
    value: "New York",
    label: "NY"
  },
  {
    value: "North Carolina",
    label: "NC"
  },
  {
    value: "North Dakota",
    label: "ND"
  },
  {
    value: "Northern Mariana Islands",
    label: "MP"
  },
  {
    value: "Ohio",
    label: "OH"
  },
  {
    value: "Oklahoma",
    label: "OK"
  },
  {
    value: "Oregon",
    label: "OR"
  },
  {
    value: "Palau",
    label: "PW"
  },
  {
    value: "Pennsylvania",
    label: "PA"
  },
  {
    value: "Puerto Rico",
    label: "PR"
  },
  {
    value: "Rhode Island",
    label: "RI"
  },
  {
    value: "South Carolina",
    label: "SC"
  },
  {
    value: "South Dakota",
    label: "SD"
  },
  {
    value: "Tennessee",
    label: "TN"
  },
  {
    value: "Texas",
    label: "TX"
  },
  {
    value: "Utah",
    label: "UT"
  },
  {
    value: "Vermont",
    label: "VT"
  },
  {
    value: "Virgin Islands",
    label: "VI"
  },
  {
    value: "Virginia",
    label: "VA"
  },
  {
    value: "Washington",
    label: "WA"
  },
  {
    value: "West Virginia",
    label: "WV"
  },
  {
    value: "Wisconsin",
    label: "WI"
  },
  {
    value: "Wyoming",
    label: "WY"
  }
]
export {
  ERROR, PATH,
  APP_SETTINGS,
  SALUTATION_LOOKUP,
  GENDER_LOOKUP,
  STATES_LOOKUP
};