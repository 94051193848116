import Male from "./MaleDoc.png";
import SageLogo from "./Sage_logo.png";
import Slake from "./slake.png";
import SideBarBg from "./sidebarbg.png"
import slakeLogo from "./Slake-logo.svg";
import UserImage from "./UserImg.png";
import BodyBg from "./Sage_body_bg.png";
import GoldBg from "./goldBg.png";
import SilverBg from "./silverBg.png";
import GoldTag from "./goldTag.png";
import SilverTag from "./silverTag.png";
import YelloPhoneImage from "./yellow-mobile-code-imabe.png";
import Email_Env from "./email_env.png";
import Visa from "./visa.png";
import Master from "./master.png";
import AmericanExpress from "./AmericanExpress.jpg";
import Paypal from "./paypal.png";
import PlanImage from "./Account.svg";
import Bg_1 from "./bg_1.jpg";
import Bg_2 from "./bg_2.jpg";
import ChangePlanImage from "./PlanChange.png";


export const IMAGES = {
  SLAKELOGO: slakeLogo,
  SIDEBARBG: SideBarBg,
  MALEDOC: Male,
  SAGELOGO: SageLogo,
  SLAKE: Slake,
  USERIMG: UserImage,
  BODYBACKGROUND:BodyBg,
  GOLDBG:GoldBg,
  GOLDTAG:GoldTag,
  SILVERBG:SilverBg,
  SILVERTAG:SilverTag,
  YELLOW_PHONE:YelloPhoneImage,
  EMAIL_ENV:Email_Env,
  VISA:Visa,
  MASTER:Master,
  PAYPAL:Paypal,
  AMERICANEXPRESS:AmericanExpress,
  PLANIMAGE:PlanImage,
  CHANGEPLANIMAGE:ChangePlanImage,
  BG_1:Bg_1,
  BG_2:Bg_2,
};
