import { NEW_PATIENT } from "../actions/utilities";

const initialState = {
    addNewPatientLoading: false,
    addNewPatientSuccess: false,
    addNewPatientFailure: false,
    addNewPatientError: null,
    addNewPatient: null,

    verifyOtpLoading: false,
    verifyOtpSuccess: false,
    verifyOtpFailure: false,
    verifyOtpError: null,
    verifyOtp: null,
}

export const newPatientReducer = (state = initialState, action) => {
    switch (action.type) {
        case NEW_PATIENT.ADD_NEW_PATIENT_REQUEST:
            return {
                ...state,
                addNewPatientLoading: true,
                addNewPatientSuccess: false,
                addNewPatientFailure: false,
                addNewPatientError: null,
            }
        case NEW_PATIENT.ADD_NEW_PATIENT_SUCCESS:
            return {
                ...state,
                addNewPatientLoading: false,
                addNewPatientSuccess: true,
                addNewPatientFailure: false,
                addNewPatientError: null,
                addNewPatient: action.payload
            }
        case NEW_PATIENT.ADD_NEW_PATIENT_FAILURE:
            return {
                ...state,
                addNewPatientLoading: false,
                addNewPatientSuccess: false,
                addNewPatientFailure: true,
                addNewPatientError: action.payload,
            }
        case NEW_PATIENT.VERIFY_NEW_PATIENT_OTP_REQUEST:
            return {
                ...state,
                verifyOtpLoading: true,
                verifyOtpSuccess: false,
                verifyOtpFailure: false,
                verifyOtpError: null,
                verifyOtp: null,
            }
        case NEW_PATIENT.VERIFY_NEW_PATIENT_OTP_SUCCESS:
            return {
                ...state,
                verifyOtpLoading: false,
                verifyOtpSuccess: true,
                verifyOtpFailure: false,
                verifyOtpError: null,
                verifyOtp: action.payload,
            }
        case NEW_PATIENT.VERIFY_NEW_PATIENT_OTP_FAILURE:
            return {
                ...state,
                verifyOtpLoading: false,
                verifyOtpSuccess: false,
                verifyOtpFailure: true,
                verifyOtpError: action.payload,
            }
        default: return state
    }
}