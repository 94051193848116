import { APP_SETTINGS } from "../../config";
import axios from "axios";

export const signUpUserApi = (userData) => {
    return axios.post(APP_SETTINGS.API_PATH.SIGNUP.addPatient, userData, {
        headers: {
            // Authorization: 'Bearer ' + auth.yodha_user.token
        }
    })
}

//signup or new user otp verification function
export const VerifyOtpApi = (codes,auth) => {
    return axios.post(APP_SETTINGS.API_PATH.SIGNUP.verifyOtp, codes, {
        headers: {
            Authorization: 'Bearer ' + auth,
            ContentType: 'application/json'
        }
    })
}