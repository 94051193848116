import { GET_PLANS } from "../actions/utilities";

const initialState = {
    getPlansLoading: false,
    getPlansSuccess: false,
    getPlansFailure: false,
    getPlansError: null,
    getPlans: [],

    getPlansByIdLoading: false,
    getPlansByIdSuccess: false,
    getPlansByIdFailure: false,
    getPlansByIdError: null,
    getPlansById: [],
}

export const getPlansByIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PLANS.GET_PLANS_REQUEST:
            return {
                ...state,
               getPlansLoading: true,
               getPlansSuccess: false,
               getPlansFailure: false,
               getPlansError: null,
            }
        case GET_PLANS.GET_PLANS_SUCCESS:
            return {
                ...state,
               getPlansLoading: false,
               getPlansSuccess: true,
               getPlansFailure: false,
               getPlansError: null,
               getPlans: action.payload
            }
        case GET_PLANS.GET_PLANS_FAILURE:
            return {
                ...state,
               getPlansLoading: false,
               getPlansSuccess: false,
               getPlansFailure: true,
               getPlansError: action.payload,
            }
        case GET_PLANS.GET_PLANS_BY_ID_REQUEST:
            return {
                ...state,
               getPlansByIdLoading: true,
               getPlansByIdSuccess: false,
               getPlansByIdFailure: false,
               getPlansByIdError: null,
            }
        case GET_PLANS.GET_PLANS_BY_ID_SUCCESS:
            return {
                ...state,
               getPlansByIdLoading: false,
               getPlansByIdSuccess: true,
               getPlansByIdFailure: false,
               getPlansByIdError: null,
               getPlansById: action.payload
            }
        case GET_PLANS.GET_PLANS_BY_ID_FAILURE:
            return {
                ...state,
               getPlansByIdLoading: false,
               getPlansByIdSuccess: false,
               getPlansByIdFailure: true,
               getPlansByIdError: action.payload,
            }
        default: return state
    }
}