import React, { useRef, useState } from "react";
import { Component } from "react";
import { Link, useHistory } from "react-router-dom";
import { PATH } from "../../config";
import { IMAGES } from '../images';
import { SidebarData } from "./SideBarData";
import { RiStethoscopeFill } from "react-icons/ri";
import { CgClipboard } from "react-icons/cg";
import SubMenu from "./SubMenu";
import { AiOutlineHome, AiOutlineMail } from "react-icons/ai"
import {FaUserCircle } from "react-icons/fa";
import {GiPriceTag } from "react-icons/gi";
import {MdLogout } from "react-icons/md";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import { useCookies } from "react-cookie";


export default function Sidebar(props) {
    let ref = useRef();
    const [cookies, remove] = useCookies(['sage_user_patient']);
    const [style, setstyle] = useState(false)
    let history = useHistory();
    let auth = useAuth();
    const parseJwt = (token) => {
      try {
        return JSON.parse(atob(token.split('.')[1]));
      } catch (e) {
        return null;
      }
    };
    const decodedJwt = parseJwt(auth?.sage_user_patient.token);
    if (decodedJwt && decodedJwt.exp) {
      if (decodedJwt.exp * 1000 < Date.now()) {
        localStorage.removeItem("sage_user_patient")
        window.localStorage.clear();
        remove('sage_user_patient', { path: "/" })
      }
    }
    // useEffect(() => {
    //     function handleClickOutside(event) {
    //         if (event.target.name === "menu-buttn" || event.target.parentNode.name === "menu-buttn") {
    //             return;
    //         }
    //         if (ref.current && !ref.current.contains(event.target) && (event.target.name !== "menu-buttn" || event.target.parentNode.name !== "menu-buttn")) {
    //             props.setShow(true)
    //         }
    //     }
    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, [ref, props]);
    // constructor(props){
    //     super(props);
    //     this.state={
    //       show: null
    //     }
    //   }

    // handleShow(id){
    //   this.setState({
    //       show: id
    //   })
    // }
    // const { location } = this.props
    // let ' ' = location.' '
    return (

        <div className="sidebar" id="sidebar">
            <div className="sidebar-inner slimscroll">
                <div id="sidebar-menu" className="sidebar-menu">
                    <ul>
                        <li className="menu-title">
                            <div className={style ? 'dashboard_image-slake' : 'dashboard_image'}> <img src={IMAGES.SLAKELOGO} alt="sidebar-Logo" /></div>
                        </li>
                        {/* <li className="menu-title-slake">
                            <div className="dashboard_image"> <img src={IMAGES.SLAKE} alt="sidebar-Logo" /></div>
                        </li> */}
                        <li className={history.location.pathname === PATH.DASHBOARD ? "active" : ""} >
                            <Link to="/Dashboard" ><AiOutlineHome className="sidebar_menu_icon" /> <span >Home</span></Link>
                        </li>
                         <li className={history.location.pathname === PATH.PROFILE ? "active" : ""} >
                            <Link to="/MyProfile"><FaUserCircle className="sidebar_menu_icon" /> <span>My Profile</span></Link>
                        </li>
                         <li className={history.location.pathname === PATH.CHANGEPLAN ? "active" : ""} >
                            <Link to="/MyPlan"><GiPriceTag className="sidebar_menu_icon" /> <span>My Plan</span></Link>
                        </li>
                         <li className={history.location.pathname === PATH.LOGOUT ? "active" : ""} >
                            <Link to="/Logout"><MdLogout className="sidebar_menu_icon" /><span>Sign Out</span></Link>
                        </li>
                        {/*<li className={history.location.pathname === PATH.TRACK ? "active" : ""} >
                            <Link to="/Tracking"><GoLocation className="icon_sidebar" /> <span>Tracking</span></Link>
                        </li>
                        <li className={history.location.pathname === PATH.MESSAGES ? "active" : ""} >
                            <Link to="/Messages"><AiOutlineMail className="icon_sidebar" /> <span>Messages</span></Link>
                        </li>
                        <li className={history.location.pathname === PATH.REPORT ? "active" : ""} >
                            <Link to="/Reports"> <CgClipboard className="icon_sidebar" /> <span>Reports</span></Link>
                        </li>
                        {/* <li className={history.location.pathname === PATH.CALENDAR ? "active" : ""} >
                            <Link to="/Calendar"><BiCalendar className="icon_sidebar" /> <span>Calendar</span></Link>
                        </li> */}
                        {/* <li className={history.location.pathname === PATH.PRICE ? "active" : ""} >
                            <Link to="/Price"><ImPriceTag className="icon_sidebar" /> <span>Price</span></Link>
                        </li>  */}
                    </ul>
                </div>
            </div>
        </div>

    );
}