import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { PATH } from '../../config'
import { useForm } from 'react-hook-form'
import { Button, Card, Modal } from 'react-bootstrap'
import PhoneInput from 'react-phone-number-input'
import { IMAGES } from '../../assets/images/index'
import ReactCodeInput from 'react-verification-code-input'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from '../../Navigation/Auth/ProvideAuth';
import { userLogin, verifyUserLogin } from '../../redux/actions/login'
import { FieldError } from '../../assets'
import Loader from 'react-loader-spinner'
import { success } from '../../redux/actions/utilities'
import { ToastContainer, toast } from 'react-toastify';
import { useCookies } from 'react-cookie'
import { getCountryTimeZone } from "../../assets";

export default function Login() {
  let history = useHistory()
  let dispatch = useDispatch();
  let auth = useAuth();
  let loginUser = useSelector(state => state.login)
  const { register, handleSubmit, errors, watch } = useForm()
  const [phone, setPhone] = useState('')
  const [VerificationOtpToggler, setVerificationOtpToggler] = useState('Login')

  const loginHandler = data => {

    dispatch(userLogin(data, moveToNext))

  }
  const redirectHandler = () => {
    history.push(PATH.SUBSCRIPTION)
  }
  const moveToNext = (data) => {
    toast.success("Verification code has been sent", { position: toast.POSITION.TOP_RIGHT })
    setVerificationOtpToggler('VerifyOtp')

    // history.push(PATH.SUBSCRIPTION)
  }
  useEffect(() => {
    document.title = "Patient Login || Sage";
  }, []);
  return (
    <div className='content_body'>
      <div className='row'>
        <div className='doc_image'>
          {/* <span className='sage_mini_logo'>
            <img className='mini_logo' src={IMAGES.SLAKELOGO} alt='' />
          </span> */}
          {/* <img src={IMAGES.MALEDOC} className="rightside_image" alt='' /> */}
          {/* <img src={IMAGES.BG_1} className="w-100" alt='' /> */}
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
          {VerificationOtpToggler === 'Login' && (
            <div className='card_box_div'>
            <Card className='custom_card_inner_styling_login_page'>
              <Card.Body>
                <section>
                  <div className='row'>
                    <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                      <div className='Login_heading_div'>
                        <h1>Sign in</h1>
                      </div>
                    </div>
                  </div>
                  <div className='row mt-4'>
                    <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                      <form onSubmit={handleSubmit(loginHandler)}>
                        <div className='row'>
                          <div className='form-group col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                            
                            <PhoneInput
                              defaultCountry='US'
                              name='phoneNumber'
                              className='form-control'
                              placeholder='Phone number'
                              value={phone}
                              onChange={setPhone}
                            />
                            <input
                              type='text'
                              name='phoneNumber'
                              ref={register({ required: true })}
                              value={phone}
                              style={{
                                display: 'none'
                              }}
                            />
                            {
                              errors && errors.phoneNumber &&
                              <FieldError message={"Phone number is required"} />
                            }
                          </div>
                        </div>
                        <div className='row'>
                          <div className='form-group col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                            {
                              loginUser && loginUser.userLoginLoading === true ?
                                <Loader type="TailSpin" className="text-right" color="#005d38" height={40} width={40} /> :
                                <Button className='signUp_button'
                                  type='submit'
                                >
                                  Sign in
                                </Button>
                            }
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center'>
                      <div className='link_div'>
                        <label>
                          Don't have account ?
                          <span
                            className='signIn_link'
                            onClick={redirectHandler}
                          >
                            {' '}
                            Subscribe Now
                          </span>
                        </label>
                      </div>
                      {
                        loginUser && loginUser.userLoginFailure === true &&
                        <FieldError message={loginUser.userLoginError} />
                      }
                    </div>
                  </div>
                </section>
              </Card.Body>
            </Card>
            </div>
          )}
          {VerificationOtpToggler === 'VerifyOtp' &&
            <Card className='custom_card_inner_styling_login_page'>
              <Card.Body>
                <VerificationOtp toggle={setVerificationOtpToggler} phoneNumb={phone} />
              </Card.Body>
            </Card>
          }
        </div>
      </div>
      {  /*    {VerificationOtpToggler === 'success' && <SuccessScreen toggle={setVerificationOtpToggler} />}*/}
      <footer>
        <div className='row'>
          <div className='login_footer col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-0'>
            Designed and developed by <b style={{ color: '#015D38' }}>XEVEN SOLUTIONS</b>
          </div>
        </div>
      </footer>
    </div>
  )
}

/**
 * 
 * @param {*} toggle param will toggle the screen state is declared in main functional component 
 * and phoneNumb param passes phone number below in payload
 * @returns  set next screen active if code is verified..
 */
function VerificationOtp({ toggle, phoneNumb }) {
  let history = useHistory()
  let dispatch = useDispatch();
  let auth = useAuth();
  let user = useSelector(state => state.login)
  const [cookies, setCookies] = useCookies();
  const { register, handleSubmit, errors, watch } = useForm()
  const [Otp, setOtp] = useState('')
  const OtpHandler = data => {
    let finalPayload = {
      ...data,
      phoneNumber: phoneNumb,
      timezone: getCountryTimeZone(),
    }
    dispatch(verifyUserLogin(finalPayload, moveToNext))
    // toggle('success')
  }
  const moveToNext = (data) => {
    toast.success("Verification complete", { position: toast.POSITION.TOP_RIGHT })
    // history.push(PATH.SUBSCRIPTION)
    // setCookies("sage_user_patient", data.data)
    setCookies("sage_user_patient", data.data )
    setTimeout(() => {
      toggle('Login')
      history.push({
        pathname: PATH.DASHBOARD
      })
    }, 2000);
  }
  function resendOtpHandler() {
    let data={
      phoneNumber: phoneNumb !== null && phoneNumb
    }
    dispatch(userLogin(data, resendToaster))
  }
  const resendToaster = (data) => {
    toast.success("Verification code has been resent", { position: toast.POSITION.TOP_RIGHT })
    // history.push(PATH.SUBSCRIPTION)
  }
  return (
    <section>
      <div className='row'>
        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
          <div className='Login_heading_div'>
            <h1>Sign in</h1>
          </div>
        </div>
      </div>
      <div className='row mt-3'>
        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
          <div className='Login_sub_heading_div'>
            <span>Enter Your Verification Code</span>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(OtpHandler)}>
        <div className='row mt-3'>
          <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            <div className='Login_verifyOtp_div'>
              <Card className='custom_card_VerficationScreen'>
                <Card.Body>
                  <Card.Img variant='top' src={IMAGES.YELLOW_PHONE} />
                  <div className='row pl-3 pr-3'>
                    <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 text-justify'>
                      <span className='primary_text'>
                        Code has been sent to Your{' '}
                      </span>
                      <span>
                        <b className='text-start'>Mobile number</b>
                      </span>
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 opt_input_div'>
                      <div className='row'>
                        <div className='form-group col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center'>
                          <ReactCodeInput
                            name='code'
                            value={Otp}
                            onChange={e => setOtp(e)}
                            fields={4}
                            type='text'
                          />

                          <input
                            type='text'
                            name='code'
                            ref={register({ required: true })}
                            value={Otp}
                            style={{
                              display: 'none'
                            }}
                          />
                          {
                            errors && errors.code &&
                            <FieldError message={"Verification code is required"} />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='form-group col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            {
              user && user.userLoginVerifyOtpLoading === true ?
                <Loader type="TailSpin" className="text-center" color="#005d38" height={40} width={40} /> :
                <Button className='signUp_button' type='submit'>
                  Verify
                </Button>
            }
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            <div className='link_div'>
              <label>
                Didn't receive code ?
                <span className='signIn_link' onClick={resendOtpHandler}> Resend</span>
              </label>
            </div>
            {
              user && user.userLoginVerifyOtpFailure === true &&
              <FieldError message={user.userLoginVerifyOtpError} />
            }
          </div>
        </div>
      </form>
    </section>
  )
}
function SuccessScreen({ toggle }) {
  let history = useHistory();
  setTimeout(() => {
    toggle('Login')
    history.push({
      pathname: PATH.DASHBOARD
    })
  }, 3000);
  return (
    <div className='row mt-3'>
      <div className='col-sm-12 col-md-12 col-lg-4 col-xl-4'>
        <Card className='custom_card_inner_styling_signUp_page'>
          <Card.Body>
            <div className='row mt-5'>
              <div className='col-md-12 p-0'>
                <svg
                  className='checkmark'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 52 52'
                >
                  <circle
                    className='checkmark__circle'
                    cx='26'
                    cy='26'
                    r='25'
                    fill='none'
                  />
                  <path
                    className='checkmark__check'
                    fill='none'
                    d='M14.1 27.2l7.1 7.2 16.7-16.8'
                  />
                </svg>
              </div>
            </div>
            <div className='row  mt-5 mb-5'>
              <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center'>
                <h2 className='success_heading'>Verified Successfully</h2>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}