import NoPage from "./NoPageFound";
import login from "./Login";
import SignUp from "./SignUp/index";
import Subscription from "./Subscription";
import DashBoard from "./DashBoard/index";
import Payment from "./Payment/index";
import Profile from "./Profile/index";
import { FooterBar } from "../assets/genericComponents/Footer";
import ChangePlan from "./ChangePlan";
import LogOut from "./LogOut";
import Verify from "./Verify";



const WEB_PAGES = {
  NOPAGE: NoPage,
  SUBSCRIPTION: Subscription,
  LOGIN: login,
  LOGOUT: LogOut,
  SIGNUP: SignUp,
  VERIFY: Verify,
  DASHBOARD:DashBoard,
  PAYMENT:Payment,
  PROFILE:Profile,
  CHANGEPLAN:ChangePlan,
  FOOTER: FooterBar,
};

export { WEB_PAGES };
