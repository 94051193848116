import { NEW_PATIENT, request, success, failure } from "./utilities";
import { signUpUserApi, VerifyOtpApi } from '../apis/signUp'
import { APP_SETTINGS } from "../../config";

/**
 * 
 * @param {UserData} userdata params generated in signup form and sent to the action described below 
 * @returns call reducer and return the api response as well
 */
export const signUpPatient = (userData, moveToNext) => {
    return (dispatch) => {
        dispatch(request(NEW_PATIENT.ADD_NEW_PATIENT_REQUEST));
        signUpUserApi(userData).then(
            response => {

                if (response.data.succeeded === true) {
                    dispatch(success(NEW_PATIENT.ADD_NEW_PATIENT_SUCCESS, response.data.data))
                    if (moveToNext) {
                        moveToNext(response.data)
                    }
                }
                else {
                    dispatch(failure(NEW_PATIENT.ADD_NEW_PATIENT_FAILURE, response.data.message))
                }
            },
            error => {
                dispatch(failure(NEW_PATIENT.ADD_NEW_PATIENT_FAILURE,
                    (error && error.response && error.response.data && error.response.data.message ?
                        error.response.data.message : error.message)))
            }
        )
    }
}
export const otpVerification = (codes,auth,moveToNext) => {
    return (dispatch) => {
        dispatch(request(NEW_PATIENT.VERIFY_NEW_PATIENT_OTP_REQUEST));
        VerifyOtpApi(codes,auth).then(
            response => {

                if (response.data.succeeded === true) {
                    dispatch(success(NEW_PATIENT.VERIFY_NEW_PATIENT_OTP_SUCCESS, response.data.data))
                    if(moveToNext){
                        moveToNext(response.data)
                    }
                }
                else {
                    dispatch(failure(NEW_PATIENT.VERIFY_NEW_PATIENT_OTP_FAILURE, response.data.message))
                }
            },
            error => {
                dispatch(failure(NEW_PATIENT.VERIFY_NEW_PATIENT_OTP_FAILURE,
                    (error && error.response && error.response.data && error.response.data.message ?
                        error.response.data.message : error.message)))
            }
        )
    }
}
