import React  from "react";
import { ERROR } from '../../config';
export function ErrorMessage(props) {
    return (
        <div className="error-message-generic">
            <span>
                {(props.message ? props.message : ERROR.SYSTEM_ERROR)}
            </span>
        </div>
    )

}

export function FieldError(props) {
    return (
        <div className="error-message-field-generic">
            <span>
                {(props.message ? props.message : ERROR.SYSTEM_ERROR)}
            </span>
        </div>
    )
}