import { combineReducers } from "redux";
import { dashboardRecordsReducer } from "./dashboardrecord";
import { patientDetailsReducer } from "./getUserinfo";
import { loginReducer } from "./loginReducer";
import { addNewPlaymentByIdReducer } from "./paymentReducer";
import { getPlansByIdReducer } from "./plansReducer";
import { newPatientReducer } from "./signUpReducer";
import { userSubscriptionsReducer } from "./subscrption";
import { updateUserReducer } from "./updateUserReducer";
import { userCardDetailsReducer } from "./userCardDetails";

export const rootReducer = combineReducers({
    newPatient: newPatientReducer,
    getPlans:getPlansByIdReducer,
    login:loginReducer,
    payment:addNewPlaymentByIdReducer,
    dashBoardrecords:dashboardRecordsReducer,
    userSubsciption:userSubscriptionsReducer,
    updateUser:updateUserReducer,
    patient:patientDetailsReducer,
    userCard:userCardDetailsReducer,
})