import { getUserRecordById, putUserRecordById } from "../apis/updateUser";
import { USER_DETAILS, request, success, failure } from "./utilities";

export const getUserInfo = (auth, id, moveToNext) => {
    return (dispatch) => {
        dispatch(request(USER_DETAILS.GET_USER_DETAILS_REQUEST));
        getUserRecordById(auth, id).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(USER_DETAILS.GET_USER_DETAILS_SUCCESS, response.data.data))
                    if(moveToNext){
                        moveToNext(response.data)
                    }
                }
                else {
                    dispatch(failure(USER_DETAILS.GET_USER_DETAILS_FAILURE, response.data.message))
                }
            },
            error => {
                dispatch(failure(USER_DETAILS.GET_USER_DETAILS_FAILURE,
                    (error && error.response && error.response.data && error.response.data.message ?
                        error.response.data.message : error.message)
                ))

            }
        )
    }
}
//updating user record function
export const putUserInfo = (auth, id,data, moveToNext) => {
    return (dispatch) => {
        dispatch(request(USER_DETAILS.PUT_USER_DETAILS_REQUEST));
        putUserRecordById(auth, id,data).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(USER_DETAILS.PUT_USER_DETAILS_SUCCESS, response.data.data))
                    if(moveToNext){
                        moveToNext(response.data)
                    }
                }
                else {
                    dispatch(failure(USER_DETAILS.PUT_USER_DETAILS_FAILURE, response.data.message))
                }
            },
            error => {
                dispatch(failure(USER_DETAILS.PUT_USER_DETAILS_FAILURE,
                    (error && error.response && error.response.data && error.response.data.message ?
                        error.response.data.message : error.message)
                ))

            }
        )
    }
}