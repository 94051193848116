import React, { useEffect, useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator';
import { getDashBoardRecord } from '../../redux/actions/dashboardrecord'
import { useAuth } from '../../Navigation/Auth/ProvideAuth'
import { useDispatch, useSelector } from 'react-redux'
import Loader from 'react-loader-spinner';

export default function Table() {
  let dispatch = useDispatch();
  let auth = useAuth()
  let record = useSelector(state => state.dashBoardrecords)
  const [Data, setData] = useState([])
  useEffect(() => {
    dispatch(getDashBoardRecord(auth.sage_user_patient.token))
  }, [dispatch])
  useEffect(() => {
    setData(record && record.getRecordSuccess === true && record.getRecord ? record.getRecord : [])
  }, [dispatch, record])

  const columns = [
    {
      dataField: 'createdDate',
      text: 'Product Date',
      formatter: (cell, row) => {
        return (
          <span>{row.createdDate.split("T")[0]}</span>
        )
      }
    },
    {
      dataField: 'expiryDate',
      text: 'Product Expiry Date',
      formatter: (cell, row) => {
        return (
          <span>{row.expiryDate.split("T")[0]}</span>
        )
      }
    },

    {
      dataField: 'planId',
      text: 'Product Name',
      formatter: (cell, row) => {
        return (
          <div style={{ width: '100%' }}>
            {row.planId < 4 ?
              (
                <>
                  <span className='sage__gold_tag_span'></span>
                  {row.subscriptionPlan}({row.subscriptionPlanType})
                </>
              ) : (
                <>
                  <span className='sage__platinum_tag_span'></span>
                  {row.subscriptionPlan}({row.subscriptionPlanType})
                </>
              )}
          </div>
        )
      }
    },
    {
      dataField: 'price',
      text: 'Product Price'
    },

  ];

  // const options = {
  //   hideSizePerPage: true,
  //   sizePerPage: 6,
  //   withFirstAndLast: false,
  // }
  return (
    <>
      {
        record?.getRecordLoading === true ?
        <Loader type="TailSpin" className="text-center" color="#005d38" height={50} width={50} /> :
          <BootstrapTable
            // classes="table table-responsive"
            wrapperClasses='table table-responsive-md'
            keyField='id'
            bordered={false}
            data={record && record.getRecordSuccess === true && record.getRecord ? [record.getRecord] : []}
            columns={columns}
            pagination={paginationFactory({ hideSizePerPage: true })}
          />
      }
    </>
  )
}
