import React, { useState, useEffect } from 'react'

import {
  Nav,
  NavDropdown,
  Navbar,
  Button,
  Modal,
  Card,
  Form
} from 'react-bootstrap'
import { AiFillSetting, AiOutlineMail } from 'react-icons/ai'
import { FaBars } from 'react-icons/fa'
import { ErrorMessage, SignOut } from '.'
import { useAuth } from '../../Navigation/Auth/ProvideAuth'
import PhoneInput from 'react-phone-number-input'
import { convertBase64 } from '../genericAction'
import Loader from 'react-loader-spinner'

export function Header () {
  const [header, setheader] = useState(false)
  const [width, setwidth] = useState(false)
  useEffect(() => {
    document.title = 'Login || Sage'
  }, [])
  const auth = useAuth()
  document.body.style.backgroundColor = '#f5f5f5'
  const HideHeader = () => {
    if (window.scrollY >= 30) {
      setheader(true)
    } else {
      setheader(false)
    }
  }
  window.addEventListener('scroll', HideHeader)
  return (
    <div className={header ? 'nav_hide' : 'header'}>
      {/* Logo */}
      <div className='header-left'>
        <a href='/admin' className='logo'>
          {/* <img src={IMAGES.SLAKELOGO} alt="Logo" /> */}
        </a>
        <a href='/admin' className='logo logo-small'></a>
      </div>
      {/* /Logo */}
      <span
        id='toggle_btn'
        className={width ? 'toggle_btn_widthset' : 'toggle_btn'}
        onClick={() => setwidth(!width)}
      >
        <i className='fe fe-text-align-left' />
      </span>

      {/* Mobile Menu Toggle */}
      <a href='#0' className='mobile_btn' id='mobile_btn'>
        <FaBars />
      </a>
      {/* /Mobile Menu Toggle */}

      {/*  <ul className={header ? 'nav_hide' : 'nav user-menu'}>
        {/* <li className="nav-item header_message_box">

          <a href="#0" id="toggle_btn" >
            <i className="fe fe-text-align-left" />
          </a>
        </li> */}
      {/* <li className="nav-item header_message_box">
          <Link to="/Messages" className="header_setting_icons">
            <AiOutlineMail className="icon_sidebar" />
          </Link>


        </li>


        <li className="nav-item header_message_box">
          <Link to="/settings" className="header_setting_icons">
            <AiFillSetting className="icon_sidebar " />
          </Link>
        </li>

        <li className="nav-item dropdown noti-dropdown">
          <Dropdown className="notify">
            <Dropdown.Toggle
              className="dropdown-toggle nav-link"
              id="dropdown-basic"
            >
              <i className="fe fe-bell"></i>{" "}
              <span className="badge badge-pill"></span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="notification-list">
            <Dropdown.Item href="#/action-1" className="no-padding">
                <span>Mkas</span>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </li>
        <li className="nav-item dropdown has-arrow">
          <Dropdown className="user-dropdown">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <span className="user-img">
                {auth && auth.sage_user.photo ? <img className="rounded" src={auth.sage_user.photo} alt="Logo" /> :
                  <img className="rounded" src={IMAGES.PROFILEDOC} alt="Logo" />
                }
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1" className="no-padding">
                <div className="user-header">
                  <div className="avatar avatar-sm">

                  </div>
                  <div className="user-text">
                    {auth && auth.sage_user && auth.sage_user.name ? <h6>{auth.sage_user.name}</h6> : <h6>Ryan Taylor</h6>}
                    {/* <p className="text-muted mb-0">Administrator</p> */}
      {/*     </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item ><Link to="/settings">Setting</Link></Dropdown.Item>
              <Dropdown.Item ><SignOut /> </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </li>
      </ul>*/}
    </div>
  )
}

// function Profile() {
//   const [show, setShow] = useState(false);
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);
//   const [OtpScreenEmail, setOtpScreenEmail] = useState(false);
//   const [EmailOtpCode, setEmailOtpCode] = useState('');
//   const [PhoneOtpCode, setPhoneOtpCode] = useState('');
//   const [OtpScreenMobile, setOtpScreenMobile] = useState(false);
//   const [NewEmailScreen, setNewEmailScreen] = useState(false);
//   const [NewMobileScreen, setNewMobileScreen] = useState(false);
//   const [phone, setPhone] = useState();
//   const [profilePicture, setProfilePicture] = useState(null);
//   const [Name, setName] = useState('');
//   const { register, handleSubmit, errors, watch } = useForm();
//   const [cookies, setCookie] = useCookies();

//   let dispatch = useDispatch();
//   let photo = useSelector(state => state.user.photo);

//   let auth = useAuth();
//   let user = useSelector(state => state.user)
//   const onChangeProfilePicture = (e) => {
//     let file = e.target.files[0];
//     if (file) {
//       convertBase64(file).then((getValue) => {
//         setProfilePicture([getValue]);
//       });
//     }
//   };
//   useEffect(() => {
//     user && user.UpdatePhotoSuccess && setProfilePicture(auth.sage_user.photo)
//   }, [user.UpdatePhotoSuccess])
//   const EmailOtpHandler = (data) => {
//     setEmailOtpCode(data)
//   }
//   const PhoneOtpHandler = (data) => {
//     setPhoneOtpCode(data)
//   }
//   const UpdatePhoneNumHandler = (data) => {
//     // e.preventDefault()
//     // debugger;
//     // alert(e.data)
//     dispatch(UpdatePhone(data, auth))
//   }

//   useEffect(() => {
//     if (user && user.Email_SendOtpSuccess === true) {
//       EmailVerificationCodeModal()
//     }
//   }, [user])

//   useEffect(() => {
//     if (user && user.UpdateEmailFailure) {
//       setOtpScreenEmail(false);
//       setNewEmailScreen(false)
//     }
//   }, [])
//   const EmailVerificationCodeModal = () => {
//     setOtpScreenEmail(true)
//   }

//   const UploadPicture = (e) => {
//     let file = e.target.files[0];
//     if (file) {
//       convertBase64(file).then((getValue) => {
//         azureBlob([getValue], auth).then(response => {
//           setProfilePicture(response.data[0])
//           if (response.data) {
//             dispatch(UpdatePhoto(response.data[0], auth, setCookiesforUser, UpdatedImgMoveToNext))
//           }
//         }).catch(e => console.log("azure error:", e))
//       })
//     }
//   }
//   const UpdatedImgMoveToNext = (data) => {
//     SuccessCase(data.message)
//   }
//   const SuccessCase = (message) => {
//     toast.success(message,
//       {
//         position: toast.POSITION.TOP_RIGHT
//       })
//   }
//   function setCookiesforUser(data) {
//     data = { ...data, photo: profilePicture }

//     let newData = {
//       dob: auth.sage_user.dob,
//       email: auth.sage_user.email,
//       gender: auth.sage_user.gender,
//       genderId: auth.sage_user.genderId,
//       isPhoneNumberVerified: auth.sage_user.isPhoneNumberVerified,
//       isProfileCompleted: auth.sage_user.isProfileCompleted,
//       name: auth.sage_user.name,
//       phoneNumber: auth.sage_user.phoneNumber,
//       photo: photo,
//       roleId: auth.sage_user.roleId,
//       timezone: auth.sage_user.timezone,
//       token: auth.sage_user.token,
//       userId: auth.sage_user.userId
//     }
//     setCookie('sage_user', newData);
//     // setCanEdit(!canEdit)
//   }

//   const UpdateNameHandler = (data) => {

//     let finalPayload = {
//       ...data,
//       "genderId": auth.sage_user.genderId,
//       "dob": auth.sage_user.dob,
//       "userId": auth.sage_user.userId
//     }
//     dispatch(UpdateUserName(finalPayload, auth, setCookiesforUserName))
//   }

//   function setCookiesforUserName(data) {
//     let newData = {
//       dob: auth.sage_user.dob,
//       email: auth.sage_user.email,
//       gender: auth.sage_user.gender,
//       genderId: auth.sage_user.genderId,
//       isPhoneNumberVerified: auth.sage_user.isPhoneNumberVerified,
//       isProfileCompleted: auth.sage_user.isProfileCompleted,
//       name: data.name,
//       phoneNumber: auth.sage_user.phoneNumber,
//       photo: auth.sage_user.photo,
//       roleId: auth.sage_user.roleId,
//       timezone: auth.sage_user.timezone,
//       token: auth.sage_user.token,
//       userId: auth.sage_user.userId
//     }
//     setCookie('sage_user', newData);
//   }

//   const UpdateEmailHandler = (data) => {

//     dispatch(UpdateEmaill(data, auth, setCookiesforEmail))
//     // setOtpScreenEmail(false); setNewEmailScreen(false)
//   }
//   function setCookiesforEmail(data) {
//     let newData = {
//       dob: auth.sage_user.dob,
//       email: data.email,
//       gender: auth.sage_user.gender,
//       genderId: auth.sage_user.genderId,
//       isPhoneNumberVerified: auth.sage_user.isPhoneNumberVerified,
//       isProfileCompleted: auth.sage_user.isProfileCompleted,
//       name: auth.sage_user.name,
//       phoneNumber: auth.sage_user.phoneNumber,
//       photo: auth.sage_user.photo,
//       roleId: auth.sage_user.roleId,
//       timezone: auth.sage_user.timezone,
//       token: auth.sage_user.token,
//       userId: auth.sage_user.userId
//     }
//     setCookie('sage_user', newData);
//   }

//   const VerifyEmailHandler = () => {
//     dispatch(VerifySendEmailOtp(auth))

//   }
//   const EmailVerficationCodeHandler = () => {
//     dispatch(VerificationEmailOtp(EmailOtpCode, auth))
//     // setNewEmailScreen(true)
//   }

//   useEffect(() => {
//     user && user.Update_VerifySendEmailOtpFailure === true && setNewEmailScreen(true)
//   }, [user])

//   const VerifyPhoneNumberHandler = () => {
//     dispatch(VerifySendPhoneOtp(auth))
//     // setOtpScreenMobile(true)
//   }
//   const VerifyPhoneOtpHandler = () => {
//     dispatch(VerifyOtpPhone(PhoneOtpCode, auth))
//   }
//   useEffect(() => {
//     user && user.Phone_SendOtpSuccess && setOtpScreenMobile(true);
//   }, [user])

//   useEffect(() => {
//     user && user.Update_VerifySendPhoneOtpFailure && setNewMobileScreen(true)
//   }, [user])
//   useEffect(() => {
//     // user&&user.UpdatePhoneNoSuccess&&set
//     user && user.UpdateEmailSuccess && setNewEmailScreen(true)
//   }, [user.UpdateEmailSuccess, user.UpdatePhoneNoSuccess])

//   return (
//     <>
//       <Dropdown.Item onClick={handleShow}>Profile</Dropdown.Item>
//       <Modal
//         size="md"
//         show={show}
//         onHide={handleClose}
//         backdrop="static"
//         keyboard={false}
//         className="Modal_customStyle_profile"
//       >
//         <Modal.Header closeButton className="admin_profile_modal_header">
//           <Modal.Title>Profile</Modal.Title>
//         </Modal.Header>
//         <div className="Mobile-change-modal-body">
//           {
//             OtpScreenMobile === false ? (
//               <div className="Email-change-modal-body">
//                 {
//                   OtpScreenEmail === false ?
//                     (
//                       <Modal.Body className="admin_profile_modal_body">
//                         <div classname="row">
//                           <div className="col-md-12 p-0">
//                             <Card className="admin_profile_card">
//                               <Card.Body>
//                                 <span className="edit_profile_image_span">
//                                   <div className="img-sec mb-5">

//                                     {user && user.UpdatePhotoFailure && user.UpdatePhotoError &&
//                                       <ErrorMessage message={user.UpdatePhotoError} />
//                                     }

//                                     <div className="picture-box" htmlFor="upload-file" >

//                                       {auth && auth.sage_user.photo && <img className="edit_profile_image" src={auth.sage_user.photo} alt="" />
//                                       }
//                                       <img alt="" className="edit_profile_image" src={profilePicture} />{" "}
//                                     </div>
//                                     <div className="add-pictures">
//                                       <label htmlFor="upload-file">
//                                         <FaCamera size={35} className="upload-image-icon" />
//                                       </label>
//                                     </div>
//                                     <Form.File

//                                       bsCustomPrefix="form-file-input"
//                                       name="path"
//                                       id="upload-file"
//                                       data-browse="UPLOAD"
//                                       accept="image/*"
//                                       onChange={(e) => { UploadPicture(e) }}
//                                     />
//                                   </div>
//                                 </span>
//                               </Card.Body>
//                             </Card>
//                           </div>
//                         </div>
//                         <div classname="row">
//                           <div className="col-md-12 p-0">
//                             <Card className="admin_profile_card">
//                               <Card.Body>
//                                 <div class="row">
//                                   <div className="col-md-12">
//                                     <form className="" onSubmit={handleSubmit(UpdateNameHandler)}>
//                                       <div className="form-row">
//                                         <div className="form-group col-md-10">
//                                           <label htmlFor="name" className="primary_color">Name:</label>
//                                           <input type="text" className="form-control" name="name"
//                                             onChange={(e) => setName(e.target.value)}
//                                             ref={register({ required: true })}
//                                             id="name" placeholder={auth && auth.sage_user.name}
//                                           />
//                                         </div>
//                                         <div className="form-group col-md-2 edit-button"><Button type="submit" className="Edit_btn">Edit</Button></div>
//                                       </div>
//                                     </form>
//                                   </div>
//                                 </div>

//                                 <div class="row">
//                                   <div className="col-md-12">
//                                     <form className="">
//                                       <div className="form-row">
//                                         <div className="form-group col-md-10">
//                                           <label htmlFor="email" className="primary_color">Email:</label>
//                                           <input type="email" className="form-control" id="email" placeholder={auth.sage_user.email} />
//                                         </div>

//                                         {user && user.Email_SendOtpLoading ? <Loader type="TailSpin" color="#005d38" height={30} width={30} /> :
//                                           <div className="form-group col-md-2 edit-button">
//                                             <Button className="Edit_btn" onClick={() => VerifyEmailHandler()}>Verify</Button>
//                                           </div>
//                                         }

//                                       </div>
//                                     </form>
//                                   </div>
//                                 </div>
//                                 <div class="row">
//                                   <div className="col-md-12">
//                                     <form className="">
//                                       <div className="form-row">
//                                         <div className="form-group col-md-10">
//                                           <label htmlFor="phn" className="primary_color">Phone Number:</label>
//                                           <input type="text" className="form-control" id="phn" placeholder={auth.sage_user.phoneNumber} />
//                                         </div>
//                                         {user && user.Phone_SendOtpLoading ? <Loader type="TailSpin" color="#005d38" height={30} width={30} /> :
//                                           <div className="form-group col-md-2 edit-button">
//                                             <Button className="Edit_btn" onClick={() => VerifyPhoneNumberHandler()}>Verify</Button>
//                                           </div>
//                                         }
//                                       </div>
//                                     </form>
//                                   </div>
//                                 </div>
//                               </Card.Body>
//                             </Card>
//                           </div>
//                         </div>
//                       </Modal.Body>
//                     ) : (
//                       <div>
//                         {
//                           NewEmailScreen === false ? (
//                             <Modal.Body>
//                               <Card className="admin_profile_card">
//                                 <Card.Body>
//                                   <div class="row">
//                                     <div class="col-md-10">
//                                       <h4 className="primary_color">Enter verifictaion code here which we've send to you on email.</h4>
//                                     </div>
//                                   </div>
//                                   <div class="row mt-4">
//                                     <div class="col-md-12 text-center">

//                                       <label className="">Verification Code:</label>

//                                       <ReactCodeInput name="otp" fields={4} type={Text}
//                                         onChange={EmailOtpHandler}
//                                         ref={register({ required: true })}

//                                         onComplete={() => EmailVerficationCodeHandler()} />

//                                       <input type="text"
//                                         name="otp"
//                                         ref={register()}
//                                         value={EmailOtpCode}
//                                         style={{
//                                           display: "none",
//                                         }}
//                                       />

//                                     </div>
//                                   </div>
//                                   <div className="row mt-3 form-agreement-text">
//                                     <div className="col">
//                                       <label className="verification-form-footer">
//                                         {" "}
//                                         Didn't recieve code ?{" "}
//                                         <Link to={"#"} className="primary_color"><b>Resend</b></Link>
//                                       </label>
//                                     </div>
//                                   </div>
//                                 </Card.Body>
//                               </Card>
//                             </Modal.Body>
//                           ) : (
//                             <Modal.Body>
//                               <Card className="admin_profile_card">
//                                 <Card.Body>
//                                   <div class="row">
//                                     <div class="col-md-12 p-0">
//                                       <h4 className="primary_color">Update your Email</h4>
//                                     </div>
//                                   </div>

//                                   <div class="row mt-4">
//                                     <div className="col-md-12">
//                                       {user && user.UpdateEmailFailure === true
//                                         && user.UpdateEmailError &&
//                                         <ErrorMessage message={user.UpdateEmailError} />
//                                       }
//                                       {user && user.UpdateEmailSuccess === true &&
//                                         <ErrorMessage message={"Email Updated !!"} />
//                                       }

//                                       <form className="form" onSubmit={handleSubmit(UpdateEmailHandler)}>
//                                         <div className="form-row ">
//                                           <div className="form-group col-md-10 email-verification">
//                                             <label htmlFor="email" className="primary_color">Email:</label>
//                                             <input name="email" type="email"
//                                               // onChange={(e)=>setNewEmail(e.target.value)}
//                                               className="form-control" id="email"
//                                               ref={register({ required: true })}
//                                               placeholder="Enter new email" />
//                                           </div>
//                                           <div className="form-group col-md-2 edit-button"><Button className="Edit_btn" type="submit"
//                                           // onClick={() => {UpdateEmailHandler(NewEmail)}}
//                                           >Save</Button></div>
//                                         </div>
//                                       </form>
//                                     </div>
//                                   </div>

//                                 </Card.Body>
//                               </Card>
//                             </Modal.Body>
//                           )}
//                       </div>
//                     )}
//               </div>
//             ) : (
//               <div className="mobile-number-update">
//                 {
//                   NewMobileScreen === false ? (
//                     <Modal.Body>
//                       <Card className="admin_profile_card">
//                         <Card.Body>
//                           <div class="row">
//                             <div class="col-md-10">
//                               <h4 className="primary_color">Enter verifictaion code here which we've send to you on Mobile Number.</h4>
//                             </div>
//                           </div>
//                           <div class="row mt-4">
//                             <div class="col-md-12 text-center">

//                               <label className="">Verification Code:</label>
//                               {/* <ReactCodeInput fields={4} type={Text}
//                               onComplete={() => setNewMobileScreen(true)} /> */}

//                               <ReactCodeInput name="otp" fields={4} type={Text}
//                                 onChange={PhoneOtpHandler}

//                                 ref={register({ required: true })}

//                                 onComplete={() => VerifyPhoneOtpHandler()} />

//                               <input type="text"
//                                 name="otp"
//                                 ref={register()}
//                                 value={PhoneOtpCode}
//                                 style={{
//                                   display: "none",
//                                 }}
//                               />

//                             </div>
//                           </div>
//                           <div className="row mt-3 form-agreement-text">
//                             <div className="col">
//                               <label className="verification-form-footer">
//                                 {" "}
//                                 Didn't recieve code ?{" "}
//                                 <Link to={"#"} className="primary_color"><b>Resend</b></Link>
//                               </label>
//                             </div>
//                           </div>
//                         </Card.Body>
//                       </Card>
//                     </Modal.Body>
//                   ) : (
//                     <Modal.Body>
//                       <Card className="admin_profile_card">
//                         <Card.Body>
//                           {user && user.UpdatePhoneNoFailure === true && user.UpdatePhoneNoError &&
//                             <ErrorMessage message={user.UpdatePhoneNoError} />
//                           }
//                           {user && user.UpdatePhoneNoSuccess === true &&
//                             <ErrorMessage message={"Phone No Updated!!!"} />
//                           }
//                           <div class="row">
//                             <div class="col-md-12">
//                               <h4 className="primary_color">Update your Phone Number</h4>
//                             </div>
//                           </div>
//                           <div class="row mt-4">
//                             <div className="col-md-12 p-0">
//                               <form className="form-" onSubmit={handleSubmit(UpdatePhoneNumHandler)}>
//                                 <div className="form-row">
//                                   <div className="form-group col-md-10 ">
//                                     <label htmlFor="phone" className="primary_color">Phone Number:</label>
//                                     <PhoneInput
//                                       defaultCountry="US"
//                                       name="phoneNo"
//                                       className="form-control"
//                                       placeholder="Enter phone number"
//                                       countryCodeEditable={false}
//                                       ref={register({ required: true })}
//                                       value={phone}
//                                       onChange={setPhone}
//                                     // required={true}
//                                     // autoFormat={true}

//                                     />

//                                   </div>
//                                   {user && user.UpdatePhoneNoLoading ?
//                                     <Loader type="TailSpin" color="#005d38" height={30} width={30} /> :
//                                     <div className="form-group col-md-2 edit-button"><Button className="Edit_btn" type="submit">Save</Button></div>
//                                   }
//                                 </div>
//                               </form>
//                             </div>
//                           </div>
//                         </Card.Body>
//                       </Card>
//                     </Modal.Body>
//                   )}
//               </div>
//             )
//           }
//         </div>
//         <Modal.Footer className="">
//           <Button variant="secondary" onClick={handleClose}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// }
// function NotificationsList() {
//   let auth = useAuth();
//   let dispatch = useDispatch();
//   let userNotifications = useSelector(state => state.notifications)
//   useEffect(() => {
//     dispatch(getNotification(auth))
//   }, [dispatch])
//   return (
//     <div>
//       {
//         userNotifications && userNotifications.getNotificationsLoading &&
//         <Dropdown.Item
//           className="notification-message"
//         >
//           <Loader type="ThreeDots" color="#005d38" height={30} width={30} />
//         </Dropdown.Item>
//       }
//       {
//         userNotifications && userNotifications.getNotifications !== null && userNotifications.getNotifications.map((item, key) => {

//           return (
//             <Dropdown.Item
//               className="notification-message"
//             >
//               <div className="media">
//                 <span className="avatar avatar-sm">
//                   <img src={item && item.fromPhoto !== null  ? item.fromPhoto : IMAGES.AVATAR} alt="" />
//                 </span>
//                 <div className="media-body">
//                   <p className="noti-details">
//                     <span className="noti-title">{item.fromName}</span>{" "}
//                     <span className="noti-title">{item.message}</span>
//                   </p>
//                   <p className="noti-time">
//                     <span className="notification-time">{item.createdDateTime.slice(11)}</span>
//                   </p>
//                 </div>
//               </div>
//             </Dropdown.Item>

//           )
//         })

//       }
//       {
//         userNotifications && userNotifications.getNotifications === null &&
//         <Dropdown.Item
//           className="notification-message"
//         >
//           <span className="noti-title">No notifications</span>
//         </Dropdown.Item>
//       }
//       {
//         userNotifications && userNotifications.getNotifications !== null && userNotifications.getNotifications.length > 1 &&
//       <Dropdown.Item
//         className="notification-message text-center"
//       >
//         <Link to="/Notifications">View All</Link>
//       </Dropdown.Item>
//       }
//     </div>
//   )

// }
