import { DASHBOARD } from "../actions/utilities";

const initialState = {
    getRecordLoading: false,
    getRecordSuccess: false,
    getRecordFailure: false,
    getRecordError: null,
    getRecord: null,
}

export const dashboardRecordsReducer = (state = initialState, action) => {
    switch (action.type) {
        case DASHBOARD.GET_PATIENT_RECORD_REQUEST:
            return {
                ...state,
                getRecordLoading: true,
                getRecordSuccess: false,
                getRecordFailure: false,
                getRecordError: null,
            }
        case DASHBOARD.GET_PATIENT_RECORD_SUCCESS:
            return {
                ...state,
                getRecordLoading: false,
                getRecordSuccess: true,
                getRecordFailure: false,
                getRecordError: null,
                getRecord: action.payload,
            }
        case DASHBOARD.GET_PATIENT_RECORD_FAILURE:
            return {
                ...state,
                getRecordLoading: false,
                getRecordSuccess: false,
                getRecordFailure: true,
                getRecordError: action.payload,
            }
        default: return state
    }

}