import { LOGIN, success, request, failure } from "./utilities"
import { loginApi, verifyLoginOtpApi } from "../apis/login"

export const userLogin = (data, moveToNext) => {
    return (dispatch) => {
        dispatch(request(LOGIN.POST_LOGIN_REQUEST));
        loginApi(data).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(LOGIN.POST_LOGIN_SUCCESS, response.data.data))
                    if (moveToNext) {
                        moveToNext(response.data)
                    }
                }
                else {
                    dispatch(failure(LOGIN.POST_LOGIN_FAILURE, response.data.message))

                }
            },
            error => {
                dispatch(failure(LOGIN.POST_LOGIN_FAILURE,
                    (error && error.response && error.response.data && error.response.data.message ?
                        error.response.data.message : error.message)
                ))

            }
        )
    }
}
export const verifyUserLogin = (data,moveToNext) => {
    return (dispatch) => {
        dispatch(request(LOGIN.POST_LOGIN_VERIFY_REQUEST));
        verifyLoginOtpApi(data).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(LOGIN.POST_LOGIN_VERIFY_SUCCESS, response.data.data))
                    if(moveToNext){
                        moveToNext(response.data)
                    }
                }
                else {
                    dispatch(failure(LOGIN.POST_LOGIN_VERIFY_FAILURE, response.data.message))

                }
            },
            error => {
                dispatch(failure(LOGIN.POST_LOGIN_VERIFY_FAILURE,
                    (error && error.response && error.response.data && error.response.data.message ?
                        error.response.data.message : error.message)
                ))

            }
        )
    }
}