import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie';
import { ParentComponentWithSideBar } from '../../assets'
export default function LogOut() {
  const [cookies, remove] = useCookies(['sage_user_patient']);

  useEffect(() => {
    remove('sage_user_patient', { path: "/" })
  }, [])
  return (
    <ParentComponentWithSideBar>
      <div className='row'>
        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
          <header>
            <h1 className='dashboard_heading'>You are loged-out</h1>
          </header>
        </div>
      </div>
    </ParentComponentWithSideBar>
  )
}
