import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { WEB_PAGES } from '../components'
import { PATH } from '../config'
import PublicRoute from './Auth/PublicRoute'
import { useLocation } from 'react-router-dom'
import PrivateRoute from './Auth/PrivateRoute'
import { FooterBar } from '../assets'
import { Header } from '../assets'
import Sidebar from '../assets/genericComponents/SideBar'
import { useAuth } from './Auth/ProvideAuth'
import { useDispatch, useSelector } from 'react-redux'

export const RouterConfig = () => {
  let auth = useAuth()
  let dispatch = useDispatch();
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  // useEffect(() => {
  //   window.$('body').append('<div class="sidebar-overlay"></div>');
  //   window.$(document).on('click', '#mobile_btn', function () {
  //     window.$wrapper.toggleClass('slide-nav');
  //     window.$('.sidebar-overlay').toggleClass('opened');
  //     window.$('html').addClass('menu-opened');
  //     return false;
  //   });
  // });
  return (
    <div>
      <Switch>
        {/* List all public routes here */}

        {/* <PublicRoute path={PATH.PHARMACYDASHBOARD}>
          <WEB_PAGES.PHARMACYDASHBOARD />
        </PublicRoute> */}

        {/* All Private Routes::: */}
        {/* <PublicRoute exact path={PATH.LANDINGPAGE}>
          <WEB_PAGES.LANDINGPAGE/>
        </PrivateRoute> */}
        {/* <Route exact path={PATH.LOGIN} component={WEB_PAGES.LOGIN} /> */}
        <PublicRoute exact path={PATH.SUBSCRIPTION}>
          <WEB_PAGES.SUBSCRIPTION />
        </PublicRoute>
        <PublicRoute exact path={PATH.LOGIN}>
          <WEB_PAGES.LOGIN />
        </PublicRoute>
        <PublicRoute exact path={PATH.SIGNUP}>
          <WEB_PAGES.SIGNUP />
        </PublicRoute>
        <PublicRoute exact path={PATH.VERIFY}>
          <WEB_PAGES.VERIFY />
        </PublicRoute>
        {
          localStorage && localStorage.token &&
            <PublicRoute exact path={PATH.PAYMENT}>
              <WEB_PAGES.PAYMENT />
            </PublicRoute>
        }
          <PrivateRoute exact path={PATH.DASHBOARD}>
            <WEB_PAGES.DASHBOARD />
          </PrivateRoute>
          <PrivateRoute exact path={PATH.PROFILE}>
            <WEB_PAGES.PROFILE />
          </PrivateRoute>
          <PrivateRoute exact path={PATH.CHANGEPLAN}>
            <WEB_PAGES.CHANGEPLAN />
          </PrivateRoute>
          <PrivateRoute exact path={PATH.LOGOUT}>
            <WEB_PAGES.LOGOUT />
          </PrivateRoute>
        <PublicRoute path={PATH.NOPAGE} component={WEB_PAGES.NOPAGE} />
      </Switch>
    </div>
  )
}
