import React, { useEffect, useState } from 'react'
import { Button, Card, Modal } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { PATH } from '../../config'
import { IMAGES } from '../../assets/images/index'
import { HiCheckCircle } from 'react-icons/hi'
import { RiCloseCircleFill } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import { getPlansById, getAllPlans, otpVerification } from '../../redux/actions/plans'





const style = {
  color: '#015D38'
}
export default function Subscription() {
  let dispatch = useDispatch();
  let plansList = useSelector(state => state.getPlans)

  useEffect(() => {
    dispatch(getAllPlans());
    localStorage.removeItem("plandata");
  }, [dispatch])

  useEffect(() => {
    localStorage.removeItem("plandata");
  }, [])
  return (
    <div className='content_body'>
      <div className='row'>
        <div className='doc_image'>
          {/* <span className='sage_mini_logo'>
            <img className='mini_logo' src={IMAGES.SLAKELOGO} alt='' />
          </span> */}
          {/* <img src={IMAGES.MALEDOC} className='rightside_image' alt='' /> */}
        </div>
      </div>
      <div className='row'>
        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-7'>
          <div className='Subscription_card_box'>
            <Card className='custom_card_styling_Subscription_page'>
              <Card.Body>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='subsciption_heading_div'>
                      <h1>Sage Membership Plans</h1>
                    </div>
                    <div className='subsciption_heading_div'>
                      <h2>See which plan is your right fit.</h2>
                    </div>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='col-md-6'>
                    <Card className='custom_card_inner_styling_subscrition_page'>
                      <Card.Body className='p-0'>
                        <div className='row'>
                          <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                            <div className='Gold_Image_box'>
                              <img
                                className='goldBg'
                                src={IMAGES.GOLDBG}
                                alt=''
                              />
                              <img
                                className='goldTag'
                                src={IMAGES.GOLDTAG}
                                alt=''
                              />
                            </div>
                            <div className='pricing_div'>
                              <span className='Starting_from_span'>
                                Starting from
                              </span>
                              <span className='Price_span'>$
                                {plansList && plansList.getPlans !== null && plansList.getPlansSuccess === true ? plansList.getPlans[0].price : '00'}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                            <div className='offer_list_div'>
                              <ul className='gold_points'>
                                <li className='gold_point'>
                                  <HiCheckCircle className='gold_check_icon' />
                                  Home-based Advanced Primary Care
                                </li>
                                <li className='gold_point'>
                                  <HiCheckCircle className='gold_check_icon' />
                                  Wellness & Nutrition
                                </li>
                                <li className='gold_point'>
                                  <HiCheckCircle className='gold_check_icon' />
                                  Emotional Wellbeing
                                </li>
                                <li className='gold_point'>
                                  <HiCheckCircle className='gold_check_icon' />
                                  Comprehensive Lab @ Home
                                </li>
                                <li className='gold_point'>
                                  <HiCheckCircle className='gold_check_icon' />
                                  Vision, Dental & Hearing Exam
                                </li>
                                <li className='gold_point'>
                                  <HiCheckCircle className='gold_check_icon' />
                                  24/7 Access
                                </li>
                                <li className='gold_point'>
                                  <HiCheckCircle className='gold_check_icon' />
                                  24/7 Telehealth Consults
                                </li>
                                <li>
                                  <RiCloseCircleFill className='gold_close_icon' />
                                  Personal Tablet with Sage Health App
                                </li>
                                <li>
                                  <RiCloseCircleFill className='gold_close_icon' />
                                  Remote Patient Monitoring Equipment for CHF,
                                  <br />
                                  <RiCloseCircleFill
                                    className='gold_close_icon'
                                    style={{ visibility: 'hidden' }}
                                  />
                                  COPD, Diabetes and other chronic conditions
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                            <div className='plan_button_div'>
                              <PricingModal />
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className='col-md-6'>
                    <Card className='custom_card_inner_styling_subscrition_page'>
                      <Card.Body className='p-0'>
                        <div className='row'>
                          <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                            <div className='silver_Image_box'>
                              <img
                                className='silverBg'
                                src={IMAGES.SILVERBG}
                                alt=''
                              />
                              <img
                                className='silverTag'
                                src={IMAGES.SILVERTAG}
                                alt=''
                              />
                            </div>
                            <div className='pricing_div'>
                              <span className='Starting_from_span'>
                                Starting from
                              </span>
                              <span className='Price_span'>$
                                {plansList && plansList.getPlans !== null && plansList.getPlansSuccess === true ? plansList.getPlans[3].price : '00'}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                            <div className='offer_list_div'>
                              <ul className='gold_points'>
                                <li className='gold_point'>
                                  <HiCheckCircle className='gold_check_icon' />
                                  Home-base Advanced Primary Care
                                </li>
                                <li className='gold_point'>
                                  <HiCheckCircle className='gold_check_icon' />
                                  Wellness & Nutrition
                                </li>
                                <li className='gold_point'>
                                  <HiCheckCircle className='gold_check_icon' />
                                  Emotional Wellbeing
                                </li>
                                <li className='gold_point'>
                                  <HiCheckCircle className='gold_check_icon' />
                                  Comprehensive Lab @ Home
                                </li>
                                <li className='gold_point'>
                                  <HiCheckCircle className='gold_check_icon' />
                                  Vision, Dental & Hearing Exam
                                </li>
                                <li className='gold_point'>
                                  <HiCheckCircle className='gold_check_icon' />
                                  24/7 Access
                                </li>
                                <li className='gold_point'>
                                  <HiCheckCircle className='gold_check_icon' />
                                  24/7 Access
                                </li>
                                <li>
                                  <HiCheckCircle className='gold_check_icon' />
                                  Personal Tablet with Sage Health App
                                </li>
                                <li>
                                  <HiCheckCircle className='gold_check_icon' />
                                  Remote Patient Monitoring Equipment for CHF,
                                  <br />
                                  <HiCheckCircle
                                    className='gold_close_icon'
                                    style={{ visibility: 'hidden' }}
                                  />
                                  COPD, Diabetes and other chronic conditions
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                            <div className='plan_button_div'>
                              <PlatiniumPricing />
                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
      <footer>
        <div className='row'>
          <div className='login_footer col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-0'>
            Designed and developed by <b style={style}>XEVEN SOLUTIONS</b>
          </div>
          <div className='col-md-3'></div>
        </div>
      </footer>
    </div>
  )
}

function PricingModal() {
  let dispatch = useDispatch();
  let history = useHistory()
  const [planData, setPlanData] = useState('asd')
  let plansList = useSelector(state => state.getPlans)

  const [lgShow, setLgShow] = useState(false)

  const redirectHandler_monthly = (data) => {

    history.push({
      pathname: PATH.SIGNUP,
      params: { planData: data }
    })

  }
  const redirectHandler_quarterly = (data) => {
    history.push({
      pathname: PATH.SIGNUP,
      params: { planData: data }
    })

  }
  const redirectHandler_annually = (data) => {

    history.push({
      pathname: PATH.SIGNUP,
      params: { planData: data }
    })
  }
  useEffect(() => {
    dispatch(getAllPlans())
  }, [dispatch])
  useEffect(() => {
    document.title = "Patient Subscription || Sage";
  }, []);
  return (
    <>
      <Button
        className='chooseplan_button_gold'
        onClick={() => setLgShow(true)}
      >
        Choose Plan
      </Button>
      <Modal
        size='xl'
        centered
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby='gold_pricing_modal'
        className='gold_price_Modal'
      >
        <Modal.Header closeButton className='gold_pricing_popup'>
          <Modal.Title id='gold_pricing_modal'>Select pricing plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-sm-4 col-md-12 col-lg-4 col-xl-4'>
              {
                plansList && plansList.getPlans !== null &&
                plansList.getPlansSuccess === true &&
                plansList.getPlans.map((item, index) => {
                  if (item.planId === 1)
                    return (
                      <Card
                        className='price_popp_card_1 mb-0'
                        key={index}
                      >
                        <Card.Body onClick={() => redirectHandler_monthly(item)}>
                          <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                              <div className='pricing_popup_card_header_div'>
                                <span>Monthly Membership</span>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                              <div className='pricing_popup_card_price_div'>
                                <span>$ {item.price}</span>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                              <div className='pricing_popup_card_doted_div'></div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                              <div className='pricing_popup_card_text_div'>
                                <span>
                                  The first payment will be billed to your method of payment for 3 months of service.
                                  {/* <span className='red_astaric'>*</span> */}
                                  <b> On the 4th month</b>, you'll be billed monthly until the anniversary date of your VIP membership.

                                </span>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                        <Card.Footer className='pl-1 pr-0 text-center astaric_span'><span className='astaric_span'>*******************************************</span></Card.Footer>
                      </Card>
                    )
                })

              }

            </div>
            <div className='col-sm-4 col-md-12 col-lg-4 col-xl-4'>
              {
                plansList && plansList.getPlans !== null &&
                plansList.getPlansSuccess === true &&
                plansList.getPlans.map((item, index) => {
                  if (item.planId === 2)
                    return (
                      <Card
                        className='price_popp_card_2 mb-0'
                        onClick={() => redirectHandler_quarterly(item)}
                        key={index}
                      >
                        <Card.Body>
                          <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                              <div className='pricing_popup_card_header_div_1'>
                                <span>Quarterly Membership</span>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                              <div className='pricing_popup_card_price_div_1'>
                                <span>$ {item.price}</span>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                              <span className='discount_span'>(10% discount)</span>
                              <div className='pricing_popup_card_doted_div'></div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                              <div className='pricing_popup_card_text_div_1'>
                                <span>
                                  Your first quarterly payment is due the day you sign up. Each subsequent quarterly payment will occur every <b>90 days</b>.
                                </span>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                        <Card.Footer className='pl-1 pr-0 text-center astaric_span'><span className='astaric_span'>*******************************************</span></Card.Footer>
                      </Card>

                    )
                })
              }

            </div>
            <div className='col-sm-4 col-md-12 col-lg-4 col-xl-4'>
              {
                plansList && plansList.getPlans !== null &&
                plansList.getPlansSuccess === true &&
                plansList.getPlans.map((item, index) => {
                  if (item.planId === 3)
                    return (
                      <Card
                        key={index}
                        className='price_popp_card_3 mb-0'
                        onClick={() => redirectHandler_annually(item)}
                      >
                        <Card.Body>
                          <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                              <div className='pricing_popup_card_header_div_2'>
                                <span>Annual Membership</span>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                              <div className='pricing_popup_card_price_div_2'>
                                <span>$ {item.price}</span>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                              <span className='discount_span_1'>(15% discount)</span>
                              <div className='pricing_popup_card_doted_div'></div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                              <div className='pricing_popup_card_text_div_2'>
                                <span>
                                  Your first annual payment<b>(15% discount)</b> is due the day you sign up.
                                  It will renew automatically at the one year anniversary of your membership.
                                </span>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                        <Card.Footer className='pl-1 pr-0 text-center astaric_span'><span className='astaric_span'>*******************************************</span></Card.Footer>
                      </Card>

                    )
                })

              }
            </div>
          </div>
            {/* <div className='row'>
              <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 d-sm-none d-md-none dilg-none d-xl-block'>
                <span>********************************************************************************************************************************************************</span>
              </div>
              <div className='col-sm-12 col-md-12 col-lg-12 d-sm-none d-md-none d-lg-block d-xl-none'>
                <span>*********************************************************************************************************</span>
              </div>
              <div className='col-sm-12 col-md-12 d-sm-block d-md-none d-lg-none d-xl-none'>
                <span>****************************************************************</span>
              </div>
            </div> */}
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center'>
              <div className='pricing_notice_text'>
                <span className='red_astaric'>*</span>
                <span className='pricing_warning_text'>
                  If you cancel anytime within the first 3 months of your membership in the monthly plan, we will prorate your refund based on services rendered.
                </span>
                <span className='red_astaric'>*</span>
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>
    </>
  )
}
function PlatiniumPricing() {
  let history = useHistory()
  let dispatch = useDispatch();
  let plansList = useSelector(state => state.getPlans)
  const [lgShow, setLgShow] = useState(false)

  const redirectHandler_monthly = (data) => {
    history.push({
      pathname: PATH.SIGNUP,
      params: { planData: data }
    })
  }
  const redirectHandler_quarterly = (data) => {
    history.push({
      pathname: PATH.SIGNUP,
      params: { planData: data }
    })
  }
  const redirectHandler_annually = (data) => {
    history.push({
      pathname: PATH.SIGNUP,
      params: { planData: data }
    })
  }
  let platinumId = "1001"
  useEffect(() => {
    dispatch(getAllPlans())
  }, [dispatch])
  return (
    <>
      <Button
        className='chooseplan_button_silver'
        onClick={() => setLgShow(true)}
      >
        Choose Plan
      </Button>
      <Modal
        size='xl'
        centered
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby='gold_pricing_modal'
        className='gold_price_Modal'
      >
        <Modal.Header closeButton className='gold_pricing_popup'>
          <Modal.Title id='gold_pricing_modal'>Select pricing plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-sm-4 col-md-4 col-lg-4 col-xl-4'>
              {
                plansList && plansList.getPlans !== null &&
                plansList.getPlansSuccess === true &&
                plansList.getPlans.map((item, index) => {
                  if (item.planId === 4)
                    return (
                      <Card
                        key={index}
                        className='price_popp_card_1 mb-0'
                        onClick={() => redirectHandler_monthly(item)}
                      >
                        <Card.Body>
                          <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                              <div className='pricing_popup_card_header_div'>
                                <span>Monthly Membership</span>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                              <div className='pricing_popup_card_price_div'>
                                <span>$ {item.price}</span>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                              <div className='pricing_popup_card_doted_div'></div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                              <div className='pricing_popup_card_text_div'>
                                <span>
                                  The first payment will be billed to your method of payment for 3 months of service.
                                  {/* <span className='red_astaric'>*</span> */}
                                  <b> On the 4th month</b>, you'll be billed monthly until the anniversary date of your VIP membership.
                                </span>
                                {/* <span>
                                  The first payment will be deducted for 3 months and each
                                  subsequent payment will start from <b>month 4</b>,
                                  will be monthly deduction.
                                </span> */}
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                        <Card.Footer className='pl-1 pr-0 text-center astaric_span'><span className='astaric_span'>*******************************************</span></Card.Footer>
                      </Card>

                    )
                })

              }
            </div>
            <div className='col-sm-4 col-md-4 col-lg-4 col-xl-4'>
              {
                plansList && plansList.getPlans !== null &&
                plansList.getPlansSuccess === true &&
                plansList.getPlans.map((item, index) => {
                  if (item.planId === 5)
                    return (
                      <Card
                        key={index}
                        className='price_popp_card_2 mb-0'
                        onClick={() => redirectHandler_quarterly(item)}
                      >
                        <Card.Body>
                          <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                              <div className='pricing_popup_card_header_div_1'>
                                <span>Quarterly Membership</span>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                              <div className='pricing_popup_card_price_div_1'>
                                <span>$ {item.price}</span>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                              <span className='discount_span'>(10% discount)</span>
                              <div className='pricing_popup_card_doted_div'></div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                              <div className='pricing_popup_card_text_div_1'>
                                <span>
                                  Your first quarterly payment is due the day you sign up. Each subsequent quarterly payment will occur every <b>90 days</b>.
                                </span>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                        <Card.Footer className='pl-1 pr-0 text-center astaric_span'><span className='astaric_span'>*******************************************</span></Card.Footer>
                      </Card>

                    )
                })

              }
            </div>
            <div className='col-sm-4 col-md-4 col-lg-4 col-xl-4'>
              {
                plansList && plansList.getPlans !== null &&
                plansList.getPlansSuccess === true &&
                plansList.getPlans.map((item, index) => {
                  if (item.planId === 6)
                    return (
                      <Card
                        key={index}
                        className='price_popp_card_3 mb-0'
                        onClick={() => redirectHandler_annually(item)}
                      >
                        <Card.Body>
                          <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                              <div className='pricing_popup_card_header_div_2'>
                                <span>Annual Membership</span>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                              <div className='pricing_popup_card_price_div_2'>
                                <span>$ {item.price}</span>
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                              <span className='discount_span_1'>(15% discount)</span>
                              <div className='pricing_popup_card_doted_div'></div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                              <div className='pricing_popup_card_text_div_2'>
                                <span>
                                  Your first annual payment<b>(15% discount)</b> is due the day you sign up.
                                  It will renew automatically at the one year anniversary of your membership.
                                </span>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                        <Card.Footer className='pl-1 pr-0 text-center astaric_span'><span className='astaric_span'>*******************************************</span></Card.Footer>
                      </Card>

                    )
                })
              }
            </div>
          </div>
          {/* <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 d-sm-none d-xl-block'>
              <span>********************************************************************************************************************************************************</span>
            </div>
            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 d-sm-none d-md-none d-lg-block d-xl-none'>
              <span>*********************************************************************************************************</span>
            </div>
            <div className='col-sm-12 col-md-12 d-lg-none d-xl-none'>
              <span>****************************************************************</span>
            </div>
          </div> */}
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center'>
              <div className='pricing_notice_text'>
                <span className='red_astaric'>*</span>
                <span className='pricing_warning_text'>
                  If you cancel anytime within the first 3 months of your membership in the monthly plan, we will prorate your refund based on services rendered.
                </span>
                <span className='red_astaric'>*</span>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
