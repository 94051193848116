import axios from "axios";
import { APP_SETTINGS } from "../../config";

export const userCardDetailsApi = (auth) => {
    return axios.get(APP_SETTINGS.API_PATH.CARDDETAILS.getCardDetails, {
        headers:
        {
            Authorization: 'Bearer ' + auth
        }
    })
}