import { LOGIN } from "../actions/utilities";

const InitialState = {
    userLoginLoading: false,
    userLoginSuccess: false,
    userLoginFailure: false,
    userLoginError: null,
    userLogin: null,

    userLoginVerifyOtpLoading: false,
    userLoginVerifyOtpSuccess: false,
    userLoginVerifyOtpFailure: false,
    userLoginVerifyOtpError: null,
    userLoginVerifyOtp: null,
}

export const loginReducer = (state = InitialState, action) => {
    switch (action.type) {
        case LOGIN.POST_LOGIN_REQUEST:
            return {
                ...state,
                userLoginLoading: true,
                userLoginSuccess: false,
                userLoginFailure: false,
                userLoginError: null,

            }
            case LOGIN.POST_LOGIN_SUCCESS:
               return {
                ...state,
                userLoginLoading: false,
                userLoginSuccess: true,
                userLoginFailure: false,
                userLoginError: null,
                userLogin: action.payload,  
                }
            case LOGIN.POST_LOGIN_FAILURE:
               return {
                ...state,
                userLoginLoading: false,
                userLoginSuccess: false,
                userLoginFailure: true,
                userLoginError: action.payload,  
                }
        case LOGIN.POST_LOGIN_VERIFY_REQUEST:
            return {
                ...state,
                userLoginVerifyOtpLoading: true,
                userLoginVerifyOtpSuccess: false,
                userLoginVerifyOtpFailure: false,
                userLoginVerifyOtpError: null,

            }
            case LOGIN.POST_LOGIN_VERIFY_SUCCESS:
               return {
                ...state,
                userLoginVerifyOtpLoading: false,
                userLoginVerifyOtpSuccess: true,
                userLoginVerifyOtpFailure: false,
                userLoginVerifyOtpError: null,
                userLoginVerifyOtp: action.payload 
                }
            case LOGIN.POST_LOGIN_VERIFY_FAILURE:
               return {
                ...state,
                userLoginVerifyOtpLoading: false,
                userLoginVerifyOtpSuccess: false,
                userLoginVerifyOtpFailure: true,
                userLoginVerifyOtpError: action.payload,
                }
        default: return state
    }
}