import { newEmailApi, newPhoneApi, sendEmailOtpApi, sendPhoneOtpApi, verifyEmailOtpApi, verifyPhoneOtpApi } from "../apis/updateUser";
import { request, success, failure, UPDATE_USER } from "./utilities";

export const sendNewEmailOtp = (auth, moveToNext) => {
    return (dispatch) => {
        dispatch(request(UPDATE_USER.SEND_USER_EMAIL_OTP_REQUEST));
        sendEmailOtpApi(auth).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(UPDATE_USER.SEND_USER_EMAIL_OTP_SUCCESS, response.data.data))
                    if (moveToNext) {
                        moveToNext(response.data)
                    }
                } else {
                    dispatch(failure(UPDATE_USER.SEND_USER_EMAIL_OTP_FAILURE, response.data.message))
                }
            },
            error => {
                dispatch(failure(UPDATE_USER.SEND_USER_EMAIL_OTP_FAILURE,
                    (error && error.response && error.response.data && error.response.data.message ?
                        error.response.data.message : error.message)))
            }
        )
    }
}
//verify otp sent by above dispatch function...
export const verifyNewEmailOtp = (auth, code, moveToNext) => {
    return (dispatch) => {
        dispatch(request(UPDATE_USER.VERIFY_USER_EMAIL_OTP_REQUEST));
        verifyEmailOtpApi(auth, code).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(UPDATE_USER.VERIFY_USER_EMAIL_OTP_SUCCESS, response.data.data))
                    if (moveToNext) {
                        moveToNext(response.data)
                    }
                } else {
                    dispatch(failure(UPDATE_USER.VERIFY_USER_EMAIL_OTP_FAILURE, response.data.message))
                }
            },
            error => {
                dispatch(failure(UPDATE_USER.VERIFY_USER_EMAIL_OTP_FAILURE,
                    (error && error.response && error.response.data && error.response.data.message ?
                        error.response.data.message : error.message)))
            }
        )
    }
}
//after verification below action function will update new email
export const newEmailAddress = (auth, data, moveToNext) => {
    return (dispatch) => {
        dispatch(request(UPDATE_USER.UPDATE_USER_EMAIL_OTP_REQUEST));
        newEmailApi(auth, data).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(UPDATE_USER.UPDATE_USER_EMAIL_OTP_SUCCESS, response.data.data))
                    if (moveToNext) {
                        moveToNext(response.data)
                    }
                } else {
                    dispatch(failure(UPDATE_USER.UPDATE_USER_EMAIL_OTP_FAILURE, response.data.message))
                }
            },
            error => {
                dispatch(failure(UPDATE_USER.UPDATE_USER_EMAIL_OTP_FAILURE,
                    (error && error.response && error.response.data && error.response.data.message ?
                        error.response.data.message : error.message)))
            }
        )
    }
}

//phone otp dispatch function
export const sendNewPhoneOtp = (auth, moveToNext) => {
    return (dispatch) => {
        dispatch(request(UPDATE_USER.SEND_USER_PHONE_OTP_REQUEST));
        sendPhoneOtpApi(auth).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(UPDATE_USER.SEND_USER_PHONE_OTP_SUCCESS, response.data.data))
                    if (moveToNext) {
                        moveToNext(response.data)
                    }
                } else {
                    dispatch(failure(UPDATE_USER.SEND_USER_PHONE_OTP_FAILURE, response.data.message))
                }
            },
            error => {
                dispatch(failure(UPDATE_USER.SEND_USER_PHONE_OTP_FAILURE,
                    (error && error.response && error.response.data && error.response.data.message ?
                        error.response.data.message : error.message)))
            }
        )
    }
}
//phone otp verification dispatch function
export const verifyNewPhoneOtp = (auth,code, moveToNext) => {
    return (dispatch) => {
        dispatch(request(UPDATE_USER.VERIFY_USER_PHONE_OTP_REQUEST));
        verifyPhoneOtpApi(auth,code).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(UPDATE_USER.VERIFY_USER_PHONE_OTP_SUCCESS, response.data.data))
                    if (moveToNext) {
                        moveToNext(response.data)
                    }
                } else {
                    dispatch(failure(UPDATE_USER.VERIFY_USER_PHONE_OTP_FAILURE, response.data.message))
                }
            },
            error => {
                dispatch(failure(UPDATE_USER.VERIFY_USER_PHONE_OTP_FAILURE,
                    (error && error.response && error.response.data && error.response.data.message ?
                        error.response.data.message : error.message)))
            }
        )
    }
}

//new phone number disptach function
export const newPhoneNumber = (auth, data, moveToNext) => {
    return (dispatch) => {
        dispatch(request(UPDATE_USER.UPDATE_USER_PHONE_OTP_REQUEST));
        newPhoneApi(auth, data).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(UPDATE_USER.UPDATE_USER_PHONE_OTP_SUCCESS, response.data.data))
                    if (moveToNext) {
                        moveToNext(response.data)
                    }
                } else {
                    dispatch(failure(UPDATE_USER.UPDATE_USER_PHONE_OTP_FAILURE, response.data.message))
                }
            },
            error => {
                dispatch(failure(UPDATE_USER.UPDATE_USER_PHONE_OTP_FAILURE,
                    (error && error.response && error.response.data && error.response.data.message ?
                        error.response.data.message : error.message)))
            }
        )
    }
}