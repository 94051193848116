import { APP_SETTINGS } from "../../config";
import axios from "axios";

export const loginApi = (data) => {
    return axios.post(APP_SETTINGS.API_PATH.LOGIN.login, data, {
        headers: {
            Authorization: 'Bearer '
        }
    })
}

//Login otp verify api function
export const verifyLoginOtpApi = (data) => {
    return axios.post(APP_SETTINGS.API_PATH.LOGIN.verifyLogin, data, {
        headers: {
            // Authorization: 'Bearer ' + auth
        }
    })
}