import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { HiCheckCircle } from 'react-icons/hi'
import { RiCloseCircleFill } from 'react-icons/ri'
import Loader from 'react-loader-spinner'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FieldError, IMAGES, ParentComponentWithSideBar } from '../../assets'
import { useAuth } from '../../Navigation/Auth/ProvideAuth'
import { getDashBoardRecord } from '../../redux/actions/dashboardrecord'
import { getAllPlans } from '../../redux/actions/plans'
import getSubscription, { delSubscription } from '../../redux/actions/subscription'
import { cardDetails } from '../../redux/actions/userCardDetails'
import PaymentPlan from './paymentPlan'

/**
 * 
 * @returns Below described function will render information related to user's current plan
 */
export default function ChangePlan() {
    let dispatch = useDispatch();
    let auth = useAuth()
    let userRecord = useSelector(state => state.userSubsciption)
    let userCardDetails = useSelector(state => state.userCard)
    const [PlanChanger, setPlanChanger] = useState('BasicInfo')
    const [ScreenToggle, setScreenToggle] = useState("Main")
    const [PlanInfo, setPlanInfo] = useState([])
    const planChangehandler = () => {
        setPlanChanger('NewPlan')
    }
    let userData = userRecord && userRecord.getSubscription !== null && userRecord.getSubscriptionSuccess === true ? userRecord.getSubscription : '';
    /**
  * cancel subscription function
  */
    const cancelsubcription = () => {
        dispatch(delSubscription(auth.sage_user_patient.token, moveToNext))
    }
    /**
 * cancel subscription function lert wether request was successfull or fail
 */
    const moveToNext = () => {
        toast.success("Plan has been cancelled successfully", { position: toast.POSITION.TOP_RIGHT });
        dispatch(getSubscription(auth.sage_user_patient.token))
    }

    useEffect(() => {
        dispatch(getSubscription(auth.sage_user_patient.token))
        dispatch(cardDetails(auth.sage_user_patient.token))
    }, [dispatch])
    useEffect(() => {
        dispatch(getDashBoardRecord(auth.sage_user_patient.token))
    }, [dispatch])
    return (
        <ParentComponentWithSideBar>
            {
                ScreenToggle === 'Main' &&
                <section>
                    <div className='row'>
                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                            <header>
                                <h1 className='dashboard_heading'>Subscription Plan</h1>
                                <hr />
                            </header>
                        </div>
                    </div>
                    {
                        PlanChanger === 'BasicInfo' && (
                            <div className='row'>
                                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                    <Card className='my_plan_card'>
                                        <Card.Header className='my_plan_card_header'><span>My Subscription Plan</span></Card.Header>
                                        <Card.Body className='p-0'>
                                            <div className='row mt-4'>
                                                <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex justify-content-center'>
                                                    <div className='my_subscibed_plan_div'>
                                                        {
                                                            userData && userData.isCancelledSubscription === true ?
                                                                <div className='my_subscibed_gold_plan'>
                                                                    <label className='sage_gold_plan_price text-danger'>You don't have any plan</label>
                                                                </div>
                                                                : (<>
                                                                    <div className='my_subscibed_gold_plan'>
                                                                        {
                                                                            userData && userData.planId < 4 &&
                                                                            <span className='sage_gold_plan_badge'></span>
                                                                        }
                                                                        {
                                                                            userData && userData.planId > 3 &&
                                                                            <span className='sage_platinum_plan_badge'></span>
                                                                        }

                                                                        <label className='sage_gold_plan_title'>Sage {userData ? userData.subscriptionPlan : ''}</label>
                                                                    </div>
                                                                    <div className='my_subscibed_gold_plan_Price'>
                                                                        <label className='sage_gold_plan_price'>$ {userData ? userData.price : 0} / {userData ? userData.subscriptionPlanType : ''}</label>
                                                                    </div>
                                                                </>)

                                                        }
                                                        {
                                                            userData && userData.isCancelledSubscription === false &&
                                                            <div className='changePlan_button_div'>
                                                                {
                                                                    userRecord && userRecord.delSubscriptionLoading === true ?
                                                                        <Loader
                                                                            type="TailSpin"
                                                                            // className="text-center"
                                                                            color="#005d38"
                                                                            height={40}
                                                                            width={40}
                                                                        /> :
                                                                        <Button className='cancel_plan_btn' onClick={cancelsubcription}>cancel Plan</Button>
                                                                }
                                                                {
                                                                    userRecord && userRecord.delSubscriptionFailure === true &&
                                                                    <FieldError message={"Error"} />
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex justify-content-center'>
                                                    {
                                                        userCardDetails && userCardDetails.getUserCardDetails !== null ?
                                                            <div className='my_subscibed_plan_div'>
                                                                <div className='my_subscibed_gold_plan_credit_card'>
                                                                    {
                                                                        userCardDetails && userCardDetails.getUserCardDetails !== null && userCardDetails.getUserCardDetails.brand === "Visa" &&
                                                                        <img src={IMAGES.VISA} className='sage_creditCard_image' alt='Credit_card' />
                                                                    }
                                                                    {
                                                                        userCardDetails && userCardDetails.getUserCardDetails !== null && userCardDetails.getUserCardDetails.brand === "American Express" &&
                                                                        <img src={IMAGES.AMERICANEXPRESS} className='sage_creditCard_image' alt='Credit_card' />
                                                                    }
                                                                    {
                                                                        userCardDetails && userCardDetails.getUserCardDetails !== null && userCardDetails.getUserCardDetails.brand === "MasterCard" &&
                                                                        <img src={IMAGES.MASTER} className='sage_creditCard_image' alt='Credit_card' />
                                                                    }
                                                                    <label className='sage_credit_card_number'>{userCardDetails && userCardDetails.getUserCardDetails !== null && userCardDetails.getUserCardDetails.brand} Card ending ****{userCardDetails && userCardDetails.getUserCardDetails !== null && userCardDetails.getUserCardDetails.last4}</label>
                                                                </div>
                                                                <div className='my_subscibed_credit_Card_text'>
                                                                    <label className='sage_plan_ending_warningText'><span style={{ color: 'rgb(228, 7, 7)' }}>*</span> Your next payment will be deduct after {userData && userData.expiryDate.split("T")[0]}</label>
                                                                </div>
                                                                {/* <div className='changePlan_button_div'>
                                                            <Button className='change_plan_btn'>Change Payment Method</Button>
                                                        </div> */}
                                                            </div>
                                                            : <div className='my_subscibed_plan_div'>
                                                                <div className='my_subscibed_gold_plan_credit_card'>
                                                                    <label className='sage_gold_plan_title text-danger'>No data availbale yet</label>
                                                                </div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                            {
                                                userData && userData.planId < 4 &&
                                                <div className='row'>
                                                    <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                                        <div className='my_choosed_plan_benifits_div'>
                                                            <span>Plan Benefits</span>
                                                        </div>
                                                        <div className='my_choosed_plan_benifits_points_div'>
                                                            <ul className='gold_points'>
                                                                <li className='gold_point'>
                                                                    <HiCheckCircle className='gold_check_icon' />
                                                                    Home-base Advanced Primary Care
                                                                </li>
                                                                <li className='gold_point'>
                                                                    <HiCheckCircle className='gold_check_icon' />
                                                                    Wellness & Nutrition
                                                                </li>
                                                                <li className='gold_point'>
                                                                    <HiCheckCircle className='gold_check_icon' />
                                                                    Emotional Wellbeing
                                                                </li>
                                                                <li className='gold_point'>
                                                                    <HiCheckCircle className='gold_check_icon' />
                                                                    Comprehensive Lab @ Home
                                                                </li>
                                                                <li className='gold_point'>
                                                                    <HiCheckCircle className='gold_check_icon' />
                                                                    Vision, Dental & Hearing Exam
                                                                </li>
                                                            </ul>
                                                            <ul className='gold_points'>
                                                                <li className='gold_point'>
                                                                    <HiCheckCircle className='gold_check_icon' />
                                                                    24/7 Access
                                                                </li>
                                                                <li className='gold_point'>
                                                                    <HiCheckCircle className='gold_check_icon' />
                                                                    24/7 Telehealth Consults
                                                                </li>
                                                                <li>
                                                                    <RiCloseCircleFill className='gold_close_icon' />
                                                                    Personal Tablet with Sage Health App
                                                                </li>
                                                                <li>
                                                                    <RiCloseCircleFill className='gold_close_icon' />
                                                                    Remote Patient Monitoring Equipment for CHF,
                                                                    <br />
                                                                    <RiCloseCircleFill
                                                                        className='gold_close_icon'
                                                                        style={{ visibility: 'hidden' }}
                                                                    />
                                                                    COPD, Diabetes and other chronic conditions
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>}
                                            {
                                                userData && userData.planId > 3 &&
                                                <div className='row'>
                                                    <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                                        <div className='my_choosed_plan_benifits_div'>
                                                            <span>Plan Benefits</span>
                                                        </div>
                                                        <div className='my_choosed_plan_benifits_points_div'>
                                                            <ul className='gold_points'>
                                                                <li className='gold_point'>
                                                                    <HiCheckCircle className='gold_check_icon' />
                                                                    Home-base Advanced Primary Care
                                                                </li>
                                                                <li className='gold_point'>
                                                                    <HiCheckCircle className='gold_check_icon' />
                                                                    Wellness & Nutrition
                                                                </li>
                                                                <li className='gold_point'>
                                                                    <HiCheckCircle className='gold_check_icon' />
                                                                    Emotional Wellbeing
                                                                </li>
                                                                <li className='gold_point'>
                                                                    <HiCheckCircle className='gold_check_icon' />
                                                                    Comprehensive Lab @ Home
                                                                </li>
                                                                <li className='gold_point'>
                                                                    <HiCheckCircle className='gold_check_icon' />
                                                                    Vision, Dental & Hearing Exam
                                                                </li>
                                                            </ul>
                                                            <ul className='gold_points'>
                                                                <li className='gold_point'>
                                                                    <HiCheckCircle className='gold_check_icon' />
                                                                    24/7 Access
                                                                </li>
                                                                <li className='gold_point'>
                                                                    <HiCheckCircle className='gold_check_icon' />
                                                                    24/7 Telehealth Consults
                                                                </li>
                                                                <li>
                                                                    <HiCheckCircle className='gold_check_icon' />
                                                                    Personal Tablet with Sage Health App
                                                                </li>
                                                                <li>
                                                                    <HiCheckCircle className='gold_check_icon' />
                                                                    Remote Patient Monitoring Equipment for CHF,
                                                                    <br />
                                                                    <HiCheckCircle
                                                                        className='gold_check_icon'
                                                                        style={{ visibility: 'hidden' }}
                                                                    />
                                                                    COPD, Diabetes and other chronic conditions
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div className='row'>
                                                <div className='form-group col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex flex-row justify-content-end'>
                                                    {userData && userData.isCancelledSubscription === true &&
                                                        <Button className='update_info_button'
                                                            onClick={planChangehandler}
                                                        >
                                                            Choose New Plan
                                                        </Button>
                                                    }
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        )
                    }
                    {
                        PlanChanger === 'NewPlan' && (
                            <NewPlan toggle={setPlanChanger} paymentToggle={setScreenToggle} planData={setPlanInfo} />
                        )}
                </section>
            }
            {
                ScreenToggle === 'paymentScreen' &&
                <PaymentPlan paymentToggle={{ setScreenToggle, setPlanChanger }} planData={PlanInfo} />
            }
        </ParentComponentWithSideBar>
    )
}

/**
 * @props will setstate back to the basicInfo screen
 * @returns new plan functon component to pickup new plan
 */
function NewPlan(props) {
    let dispatch = useDispatch();
    let plansList = useSelector(state => state.getPlans)
    const [Plans, setPlans] = useState("NoPlan")
    const [PlansPrices, setPlansPrices] = useState()

    const planChangehandler = () => {
        props.toggle('BasicInfo')
    }
    const activeCard = {
        border: '2px solid #005d38'
    }


    /**
     *this function is used for payment subscriptions appears at right side  
     * @param {*} item items will provide information related to the subscription plan lik planId,price and planName etc
     */
    const paymentHandler = (item) => {
        props.paymentToggle('paymentScreen')
        // console.log("item", item)
        props.planData(item)
    }
    useEffect(() => {
        dispatch(getAllPlans())
    }, [dispatch])
    useEffect(() => {
        setPlansPrices(plansList && plansList.getPlans !== null && plansList.getPlansSuccess === true ? plansList.getPlans : [])
    }, [dispatch, plansList])

    return (
        <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-8 col-xl-8'>
                <Card className='myPlan_main_card'>
                    <Card.Body className='pb-lg-0 pt-lg-3 p-sm-5'>
                        <div className='row d-flex flex-row justify-content-center'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                <div className='myPlan_subsciption_heading_div'>
                                    <h2>See which plan is your right fit.</h2>
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-md-6'>
                                    <Card className='custom_card_inner_styling_subscrition_page' style={Plans === 'GoldPlan' ? activeCard : null}>
                                        <Card.Body className='p-0'>
                                            <div className='row'>
                                                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                                    <div className='Gold_Image_box'>
                                                        <img
                                                            className='goldBg'
                                                            src={IMAGES.GOLDBG}
                                                            alt=''
                                                        />
                                                        <img
                                                            className='goldTag'
                                                            src={IMAGES.GOLDTAG}
                                                            alt=''
                                                        />
                                                    </div>
                                                    <div className='pricing_div'>
                                                        <span className='Starting_from_span'>
                                                            Starting from
                                                        </span>
                                                        <span className='Price_span'>$ {plansList && plansList.getPlans !== null && plansList.getPlansSuccess === true ? plansList.getPlans[0].price : 0}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                                    <div className='offer_list_div'>
                                                        <ul className='gold_points'>
                                                            <li className='gold_point'>
                                                                <HiCheckCircle className='gold_check_icon' />
                                                                Home-base Advanced Primary Care
                                                            </li>
                                                            <li className='gold_point'>
                                                                <HiCheckCircle className='gold_check_icon' />
                                                                Wellness & Nutrition
                                                            </li>
                                                            <li className='gold_point'>
                                                                <HiCheckCircle className='gold_check_icon' />
                                                                Emotional Wellbeing
                                                            </li>
                                                            <li className='gold_point'>
                                                                <HiCheckCircle className='gold_check_icon' />
                                                                Comprehensive Lab @ Home
                                                            </li>
                                                            <li className='gold_point'>
                                                                <HiCheckCircle className='gold_check_icon' />
                                                                Vision, Dental & Hearing Exam
                                                            </li>
                                                            <li className='gold_point'>
                                                                <HiCheckCircle className='gold_check_icon' />
                                                                24/7 Access
                                                            </li>
                                                            <li className='gold_point'>
                                                                <HiCheckCircle className='gold_check_icon' />
                                                                24/7 Telehealth Consults
                                                            </li>
                                                            <li>
                                                                <RiCloseCircleFill className='gold_close_icon' />
                                                                Personal Tablet with Sage Health App
                                                            </li>
                                                            <li>
                                                                <RiCloseCircleFill className='gold_close_icon' />
                                                                Remote Patient Monitoring Equipment for CHF,
                                                                <br />
                                                                <RiCloseCircleFill
                                                                    className='gold_close_icon'
                                                                    style={{ visibility: 'hidden' }}
                                                                />
                                                                COPD, Diabetes and other chronic conditions
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                                    <div className='plan_button_div'>
                                                        <Button
                                                            className='chooseplan_button_gold'
                                                            onClick={() => setPlans("GoldPlan")}
                                                        >
                                                            Choose Plan
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-md-6'>
                                    <Card className='custom_card_inner_styling_subscrition_page' style={Plans === 'PlatinumPlan' ? activeCard : null}>
                                        <Card.Body className='p-0'>
                                            <div className='row'>
                                                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                                    <div className='silver_Image_box'>
                                                        <img
                                                            className='silverBg'
                                                            src={IMAGES.SILVERBG}
                                                            alt=''
                                                        />
                                                        <img
                                                            className='silverTag'
                                                            src={IMAGES.SILVERTAG}
                                                            alt=''
                                                        />
                                                    </div>
                                                    <div className='pricing_div'>
                                                        <span className='Starting_from_span'>
                                                            Starting from
                                                        </span>
                                                        <span className='Price_span'>$ {plansList && plansList.getPlans !== null && plansList.getPlansSuccess === true ? plansList.getPlans[3].price : 0}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                                    <div className='offer_list_div'>
                                                        <ul className='gold_points'>
                                                            <li className='gold_point'>
                                                                <HiCheckCircle className='gold_check_icon' />
                                                                Home-base Advanced Primary Care
                                                            </li>
                                                            <li className='gold_point'>
                                                                <HiCheckCircle className='gold_check_icon' />
                                                                Wellness & Nutrition
                                                            </li>
                                                            <li className='gold_point'>
                                                                <HiCheckCircle className='gold_check_icon' />
                                                                Emotional Wellbeing
                                                            </li>
                                                            <li className='gold_point'>
                                                                <HiCheckCircle className='gold_check_icon' />
                                                                Comprehensive Lab @ Home
                                                            </li>
                                                            <li className='gold_point'>
                                                                <HiCheckCircle className='gold_check_icon' />
                                                                Vision, Dental & Hearing Exam
                                                            </li>
                                                            <li className='gold_point'>
                                                                <HiCheckCircle className='gold_check_icon' />
                                                                24/7 Access
                                                            </li>
                                                            <li className='gold_point'>
                                                                <HiCheckCircle className='gold_check_icon' />
                                                                24/7 Access
                                                            </li>
                                                            <li>
                                                                <HiCheckCircle className='gold_check_icon' />
                                                                Personal Tablet with Sage Health App
                                                            </li>
                                                            <li>
                                                                <HiCheckCircle className='gold_check_icon' />
                                                                Remote Patient Monitoring Equipment for CHF,
                                                                <br />
                                                                <HiCheckCircle
                                                                    className='gold_close_icon'
                                                                    style={{ visibility: 'hidden' }}
                                                                />
                                                                COPD, Diabetes and other chronic conditions
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                                    <div className='plan_button_div'>
                                                        <Button
                                                            className='chooseplan_button_silver'
                                                            onClick={() => setPlans("PlatinumPlan")}
                                                        >
                                                            Choose Plan
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <div className='col-sm-12 col-md-12 col-lg-4 col-xl-4'>
                <div className='row'>
                    <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                        {
                            Plans === 'NoPlan' &&
                            (
                                <Card className='myPlan_sidebar_card'>
                                    <Card.Body>
                                        <div className='row mt-2 mb-2'>
                                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center'>
                                                <h2 style={{ color: '#515151' }}>Finalize the plans</h2>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                                <div className='myPlan_sidebar_Image_div'>
                                                    <img src={IMAGES.CHANGEPLANIMAGE} alt='sidebar_pic' />
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            )
                        }
                        {
                            Plans === 'GoldPlan' &&
                            (
                                <Card className='myPlan_sidebar_card'>
                                    <Card.Body >
                                        {
                                            plansList && plansList.getPlans !== null &&
                                            plansList.getPlansSuccess === true &&
                                            plansList.getPlans.map((item, index) => {
                                                if (item.planId === 2 || item.planId === 3 || item.planId === 4 || item.planId === 5 || item.planId === 6) return null
                                                return (
                                                    <div className='row mb-2' onClick={() => paymentHandler(item)}>
                                                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                                            <div className='rightSide_price_gold_div'>
                                                                <div className='pricing_side_card_inner_div'>
                                                                    <div className='pricing_side_card_heading_div'>
                                                                        <span>Monthly Subscription</span>
                                                                    </div>
                                                                </div>
                                                                <div className='pricing_side_card_inner_div'>
                                                                    <div className='pricing_side_card_price_div'>
                                                                        <span>${item ? item.price : 0}</span>
                                                                    </div>
                                                                </div>
                                                                <div className='pricing_side_card_inner_div pb-0 pt-0'>
                                                                    <div className='pricing_popup_card_doted_div'></div>
                                                                </div>
                                                                <div className='pricing_side_card_inner_div pt-0'>
                                                                    <div className='pricing_side_card_text_div'>
                                                                        <span>
                                                                            The first payment will be deducted for <b>3 months</b> and each
                                                                            subsequent payment will start from <b>month 4</b>,
                                                                            will be monthly deduction.
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            plansList && plansList.getPlans !== null &&
                                            plansList.getPlansSuccess === true &&
                                            plansList.getPlans.map((item, index) => {
                                                if (item.planId === 1 || item.planId === 3 || item.planId === 4 || item.planId === 5 || item.planId === 6) return null
                                                return (
                                                    <div className='row mb-2' onClick={() => paymentHandler(item)}>
                                                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                                            <div className='rightSide_quater_price_gold_div'>
                                                                <div className='pricing_side_card_inner_div'>
                                                                    <div className='quater_pricing_side_card_heading_div'>
                                                                        <span>Quarterly Subscription</span>
                                                                    </div>
                                                                </div>
                                                                <div className='pricing_side_card_inner_div'>
                                                                    <div className='quater_pricing_side_card_price_div'>
                                                                        <span>${item ? item.price : 0}</span>
                                                                    </div>
                                                                </div>
                                                                <div className='pricing_side_card_inner_div pb-0 pt-0'>
                                                                    <span className='side_pricing_discount_span'>(10% discount)</span>
                                                                    <div className='pricing_popup_card_doted_div'></div>
                                                                </div>
                                                                <div className='pricing_side_card_inner_div pt-0'>
                                                                    <div className='quater_pricing_side_card_text_div'>
                                                                        <span>
                                                                            Your payment will automatically deduct <b>after</b> 3
                                                                            months from your subscribed card.
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        {
                                            plansList && plansList.getPlans !== null &&
                                            plansList.getPlansSuccess === true &&
                                            plansList.getPlans.map((item, index) => {
                                                if (item.planId === 1 || item.planId === 2 || item.planId === 4 || item.planId === 5 || item.planId === 6) return null
                                                return (
                                                    <div className='row' onClick={() => paymentHandler(item)}>
                                                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                                            <div className='rightSide_Annual_price_gold_div'>
                                                                <div className='pricing_side_card_inner_div'>
                                                                    <div className='Annual_pricing_side_card_heading_div'>
                                                                        <span>Annual Subscription</span>
                                                                    </div>
                                                                </div>
                                                                <div className='pricing_side_card_inner_div'>
                                                                    <div className='Annual_pricing_side_card_price_div'>
                                                                        <span>${item ? item.price : 0}</span>
                                                                    </div>
                                                                </div>
                                                                <div className='pricing_side_card_inner_div pb-0 pt-0'>
                                                                    <span className='side_pricing_discount_span'>(15% discount)</span>
                                                                    <div className='pricing_popup_card_doted_div'></div>
                                                                </div>
                                                                <div className='pricing_side_card_inner_div pt-0'>
                                                                    <div className='Annual_pricing_side_card_text_div'>
                                                                        <span>
                                                                            Your payment will automatically deducted <b>after 12
                                                                                months </b> from your subscribed card.
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Card.Body>
                                </Card>
                            )
                        }
                        {
                            Plans === 'PlatinumPlan' &&
                            (
                                <Card className='myPlan_sidebar_card'>
                                    <Card.Body>
                                        <div className='row mt-2 mb-2'>
                                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center'>

                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                                <Card className='myPlan_sidebar_card'>
                                                    <Card.Body >
                                                        {
                                                            plansList && plansList.getPlans !== null &&
                                                            plansList.getPlansSuccess === true &&
                                                            plansList.getPlans.map((item, index) => {
                                                                if (item.planId === 1 || item.planId === 2 || item.planId === 3 || item.planId === 5 || item.planId === 6) return null
                                                                return (
                                                                    <div className='row mb-2' onClick={() => paymentHandler(item)}>
                                                                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                                                            <div className='rightSide_price_gold_div'>
                                                                                <div className='pricing_side_card_inner_div'>
                                                                                    <div className='pricing_side_card_heading_div'>
                                                                                        <span>Monthly Subscription</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='pricing_side_card_inner_div'>
                                                                                    <div className='pricing_side_card_price_div'>
                                                                                        <span>${item ? item.price : 0}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='pricing_side_card_inner_div pb-0 pt-0'>
                                                                                    <div className='pricing_popup_card_doted_div'></div>
                                                                                </div>
                                                                                <div className='pricing_side_card_inner_div pt-0'>
                                                                                    <div className='pricing_side_card_text_div'>
                                                                                        <span>
                                                                                            The first payment will be deducted for <b>3 months</b> and each
                                                                                            subsequent payment will start from <b>month 4</b>,
                                                                                            will be monthly deduction.
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            plansList && plansList.getPlans !== null &&
                                                            plansList.getPlansSuccess === true &&
                                                            plansList.getPlans.map((item, index) => {
                                                                if (item.planId === 1 || item.planId === 2 || item.planId === 3 || item.planId === 4 || item.planId === 6) return null
                                                                return (

                                                                    <div className='row mb-2' onClick={() => paymentHandler(item)}>
                                                                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                                                            <div className='rightSide_quater_price_gold_div'>
                                                                                <div className='pricing_side_card_inner_div'>
                                                                                    <div className='quater_pricing_side_card_heading_div'>
                                                                                        <span>Quarterly Subscription</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='pricing_side_card_inner_div'>
                                                                                    <div className='quater_pricing_side_card_price_div'>
                                                                                        <span>${item ? item.price : 0}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='pricing_side_card_inner_div pb-0 pt-0'>
                                                                                    <span className='side_pricing_discount_span'>(10% discount)</span>
                                                                                    <div className='pricing_popup_card_doted_div'></div>
                                                                                </div>
                                                                                <div className='pricing_side_card_inner_div pt-0'>
                                                                                    <div className='quater_pricing_side_card_text_div'>
                                                                                        <span>
                                                                                            Your payment will automatically deducted <b>after</b> 3
                                                                                            months from your subscribed card.
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            plansList && plansList.getPlans !== null &&
                                                            plansList.getPlansSuccess === true &&
                                                            plansList.getPlans.map((item, index) => {
                                                                if (item.planId === 1 || item.planId === 2 || item.planId === 3 || item.planId === 4 || item.planId === 5) return null
                                                                return (

                                                                    <div className='row' onClick={() => paymentHandler(item)}>
                                                                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                                                            <div className='rightSide_Annual_price_gold_div'>
                                                                                <div className='pricing_side_card_inner_div'>
                                                                                    <div className='Annual_pricing_side_card_heading_div'>
                                                                                        <span>Annual Subscription</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='pricing_side_card_inner_div'>
                                                                                    <div className='Annual_pricing_side_card_price_div'>
                                                                                        <span>${item ? item.price : 0}</span>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='pricing_side_card_inner_div pb-0 pt-0'>
                                                                                    <span className='side_pricing_discount_span'>(15% discount)</span>
                                                                                    <div className='pricing_popup_card_doted_div'></div>
                                                                                </div>
                                                                                <div className='pricing_side_card_inner_div pt-0'>
                                                                                    <div className='Annual_pricing_side_card_text_div'>
                                                                                        <span>
                                                                                            Your payment will automatically deduct <b>after 12
                                                                                                months </b> from your subscribed card.
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            )
                        }
                    </div>
                </div>
                <div className='row'>
                    <div className='form-group col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex flex-row justify-content-end'>
                        <Button className='signUp_button'
                            onClick={planChangehandler}
                        >
                            Continue with old plan
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
