import { CARDDETAILS } from "../actions/utilities";

const initialState = {
    getUserCardDetailsLoading: false,
    getUserCardDetailsSuccess: false,
    getUserCardDetailsFailure: false,
    getUserCardDetailsError: null,
    getUserCardDetails: null,
}

export const userCardDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case CARDDETAILS.GET_CARDS_DETAILS_REQUEST:
            return {
                ...state,
                getUserCardDetailsLoading: true,
                getUserCardDetailsSuccess: false,
                getUserCardDetailsFailure: false,
                getUserCardDetailsError: null,
            }
        case CARDDETAILS.GET_CARDS_DETAILS_SUCCESS:
            return {
                ...state,
                getUserCardDetailsLoading: false,
                getUserCardDetailsSuccess: true,
                getUserCardDetailsFailure: false,
                getUserCardDetailsError: null,
                getUserCardDetails: action.payload,
            }
        case CARDDETAILS.GET_CARDS_DETAILS_FAILURE:
            return {
                ...state,
                getUserCardDetailsLoading: false,
                getUserCardDetailsSuccess: false,
                getUserCardDetailsFailure: true,
                getUserCardDetailsError: action.payload,
            }
        default: return state
    }
}