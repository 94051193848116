import { delSubscriptionApi, subscriptionApi } from "../apis/subscription"
import { SUBSCRIPTION,request,success,failure } from "./utilities"

export default function getSubscription(auth,moveToNext) {
  return(dispatch)=>{
    dispatch(request(SUBSCRIPTION.GET_SUBSCRIPTION_REQUEST))
    subscriptionApi(auth).then(
        response=>{
            if(response.data.succeeded === true){
                dispatch(success(SUBSCRIPTION.GET_SUBSCRIPTION_SUCCESS,response.data.data))
                if(moveToNext){
                    moveToNext(response.data)
                }
            }else{
                dispatch(success(SUBSCRIPTION.GET_SUBSCRIPTION_FAILURE,response.data.message))
            }
        },
        error => {
            dispatch(failure(SUBSCRIPTION.GET_SUBSCRIPTION_FAILURE,
                (error && error.response && error.response.data && error.response.data.message ?
                    error.response.data.message : error.message)))
        }
    
    )
  }
}
export  function delSubscription(auth,moveToNext) {
  return(dispatch)=>{
    dispatch(request(SUBSCRIPTION.DEL_SUBSCRIPTION_REQUEST))
    delSubscriptionApi(auth).then(
        response=>{
            if(response.data.succeeded === true){
                dispatch(success(SUBSCRIPTION.DEL_SUBSCRIPTION_SUCCESS,response.data.data))
                if(moveToNext){
                    moveToNext(response.data)
                }
            }else{
                dispatch(success(SUBSCRIPTION.DEL_SUBSCRIPTION_FAILURE,response.data.message))
            }
        },
        error => {
            dispatch(failure(SUBSCRIPTION.DEL_SUBSCRIPTION_FAILURE,
                (error && error.response && error.response.data && error.response.data.message ?
                    error.response.data.message : error.message)))
        }
    
    )
  }
}
