import { GET_PLANS, request, success, failure } from "./utilities";
import { getPlansByIdApi,getAllPlansApi } from '../apis/plans'
import { APP_SETTINGS } from "../../config";

export const getAllPlans = () => {
    return (dispatch) => {
        dispatch(request(GET_PLANS.GET_PLANS_REQUEST));
        getAllPlansApi().then(
            response => {

                if (response.data.succeeded === true) {
                    dispatch(success(GET_PLANS.GET_PLANS_SUCCESS, response.data.data))
                }
                else {
                    dispatch(failure(GET_PLANS.GET_PLANS_FAILURE, response.data.message))
                }
            },
            error => {
                dispatch(failure(GET_PLANS.GET_PLANS_FAILURE,
                    (error && error.response && error.response.data && error.response.data.message ?
                        error.response.data.message : error.message)))
            }
        )
    }
}
export const getPlansById = (id) => {
    return (dispatch) => {
        dispatch(request(GET_PLANS.GET_PLANS_BY_ID_REQUEST));
        getPlansByIdApi(id).then(
            response => {

                if (response.data.succeeded === true) {
                    dispatch(success(GET_PLANS.GET_PLANS_BY_ID_SUCCESS, response.data.data))
                }
                else {
                    dispatch(failure(GET_PLANS.GET_PLANS_BY_ID_FAILURE, response.data.message))
                }
            },
            error => {
                dispatch(failure(GET_PLANS.GET_PLANS_BY_ID_FAILURE,
                    (error && error.response && error.response.data && error.response.data.message ?
                        error.response.data.message : error.message)))
            }
        )
    }
}
