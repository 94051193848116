import { PAYMENT, request, success, failure } from "./utilities";
import { newPaymentApi, putNewPaymentApi } from "../apis/payment";

export const newPayment = (auth, data, moveToNext) => {
    return (dispatch) => {
        dispatch(request(PAYMENT.ADD_NEW_PAYMENT_REQUEST));
        newPaymentApi(auth,data).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(PAYMENT.ADD_NEW_PAYMENT_SUCCESS, response.data.data))
                    if(moveToNext){
                        moveToNext(response.data)
                    }
                }
                else {
                    dispatch(failure(PAYMENT.ADD_NEW_PAYMENT_FAILURE, response.data.message))

                }
            },
            error => {
                dispatch(failure(PAYMENT.ADD_NEW_PAYMENT_FAILURE,
                    (error && error.response && error.response.data && error.response.data.message ?
                        error.response.data.message : error.message)
                ))

            }
        )
    }
}
export const putNewPayment = (auth, data, moveToNext) => {
    return (dispatch) => {
        dispatch(request(PAYMENT.PUT_NEW_PAYMENT_REQUEST));
        putNewPaymentApi(auth,data).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(PAYMENT.PUT_NEW_PAYMENT_SUCCESS, response.data.data))
                    if(moveToNext){
                        moveToNext(response.data)
                    }
                }
                else {
                    dispatch(failure(PAYMENT.PUT_NEW_PAYMENT_FAILURE, response.data.message))

                }
            },
            error => {
                dispatch(failure(PAYMENT.PUT_NEW_PAYMENT_FAILURE,
                    (error && error.response && error.response.data && error.response.data.message ?
                        error.response.data.message : error.message)
                ))

            }
        )
    }
}