import { PAYMENT } from "../actions/utilities";

const initialState = {
    addNewPlaymentLoading: false,
    addNewPlaymentSuccess: false,
    addNewPlaymentFailure: false,
    addNewPlaymentError: null,
    addNewPlayment: [],

    putNewPlaymentLoading: false,
    putNewPlaymentSuccess: false,
    putNewPlaymentFailure: false,
    putNewPlaymentError: null,
    putNewPlayment: [],
}

export const addNewPlaymentByIdReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAYMENT.ADD_NEW_PAYMENT_REQUEST:
            return {
                ...state,
               addNewPlaymentLoading: true,
               addNewPlaymentSuccess: false,
               addNewPlaymentFailure: false,
               addNewPlaymentError: null,
            }
        case PAYMENT.ADD_NEW_PAYMENT_SUCCESS:
            return {
                ...state,
               addNewPlaymentLoading: false,
               addNewPlaymentSuccess: true,
               addNewPlaymentFailure: false,
               addNewPlaymentError: null,
               addNewPlayment: action.payload
            }
        case PAYMENT.ADD_NEW_PAYMENT_FAILURE:
            return {
                ...state,
               addNewPlaymentLoading: false,
               addNewPlaymentSuccess: false,
               addNewPlaymentFailure: true,
               addNewPlaymentError: action.payload,
            }
        case PAYMENT.PUT_NEW_PAYMENT_REQUEST:
            return {
                ...state,
               putNewPlaymentLoading: true,
               putNewPlaymentSuccess: false,
               putNewPlaymentFailure: false,
               putNewPlaymentError: null,
            }
        case PAYMENT.PUT_NEW_PAYMENT_SUCCESS:
            return {
                ...state,
               putNewPlaymentLoading: false,
               putNewPlaymentSuccess: true,
               putNewPlaymentFailure: false,
               putNewPlaymentError: null,
               putNewPlayment: action.payload
            }
        case PAYMENT.PUT_NEW_PAYMENT_FAILURE:
            return {
                ...state,
               putNewPlaymentLoading: false,
               putNewPlaymentSuccess: false,
               putNewPlaymentFailure: true,
               putNewPlaymentError: action.payload,
            }
        default: return state
    }
}