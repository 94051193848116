import { UPDATE_USER } from "../actions/utilities";

const initialState = {
    sendEmailOtpLoading: false,
    sendEmailOtpSuccess: false,
    sendEmailOtpFailure: false,
    sendEmailOtpError: null,
    sendEmailOtp: null,

    verifyEmailOtpLoading: false,
    verifyEmailOtpSuccess: false,
    verifyEmailOtpFailure: false,
    verifyEmailOtpError: null,
    verifyEmailOtp: null,

    updateEmailOtpLoading: false,
    updateEmailOtpSuccess: false,
    updateEmailOtpFailure: false,
    updateEmailOtpError: null,
    updateEmailOtp: null,

    sendPhoneLoading: false,
    sendPhoneSuccess: false,
    sendPhoneFailure: false,
    sendPhoneError: null,
    sendPhone: null,

    verifyPhoneLoading: false,
    verifyPhoneSuccess: false,
    verifyPhoneFailure: false,
    verifyPhoneError: null,
    verifyPhone: null,

    updatePhoneLoading: false,
    updatePhoneSuccess: false,
    updatePhoneFailure: false,
    updatePhoneError: null,
    updatePhone: null,
}

export const updateUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_USER.SEND_USER_EMAIL_OTP_REQUEST:
            return {
                ...state,
                sendEmailOtpLoading: true,
                sendEmailOtpSuccess: false,
                sendEmailOtpFailure: false,
                sendEmailOtpError: null,
            }
        case UPDATE_USER.SEND_USER_EMAIL_OTP_SUCCESS:
            return {
                ...state,
                sendEmailOtpLoading: false,
                sendEmailOtpSuccess: true,
                sendEmailOtpFailure: false,
                sendEmailOtpError: null,
                sendEmailOtp: action.payload
            }
        case UPDATE_USER.SEND_USER_EMAIL_OTP_FAILURE:
            return {
                ...state,
                sendEmailOtpLoading: false,
                sendEmailOtpSuccess: false,
                sendEmailOtpFailure: true,
                sendEmailOtpError: action.payload,
            }
        case UPDATE_USER.VERIFY_USER_EMAIL_OTP_REQUEST:
            return {
                ...state,
                verifyEmailOtpLoading: true,
                verifyEmailOtpSuccess: false,
                verifyEmailOtpFailure: false,
                verifyEmailOtpError: null,
            }
        case UPDATE_USER.VERIFY_USER_EMAIL_OTP_SUCCESS:
            return {
                ...state,
                verifyEmailOtpLoading: false,
                verifyEmailOtpSuccess: true,
                verifyEmailOtpFailure: false,
                verifyEmailOtpError: null,
                verifyEmailOtp: action.payload
            }
        case UPDATE_USER.VERIFY_USER_EMAIL_OTP_FAILURE:
            return {
                ...state,
                verifyEmailOtpLoading: false,
                verifyEmailOtpSuccess: false,
                verifyEmailOtpFailure: true,
                verifyEmailOtpError: action.payload,
            }
        case UPDATE_USER.UPDATE_USER_EMAIL_OTP_REQUEST:
            return {
                ...state,
                updateEmailOtpLoading: true,
                updateEmailOtpSuccess: false,
                updateEmailOtpFailure: false,
                updateEmailOtpError: null,
            }
        case UPDATE_USER.UPDATE_USER_EMAIL_OTP_SUCCESS:
            return {
                ...state,
                updateEmailOtpLoading: false,
                updateEmailOtpSuccess: true,
                updateEmailOtpFailure: false,
                updateEmailOtpError: null,
                updateEmailOtp: action.payload
            }
        case UPDATE_USER.UPDATE_USER_EMAIL_OTP_FAILURE:
            return {
                ...state,
                updateEmailOtpLoading: false,
                updateEmailOtpSuccess: false,
                updateEmailOtpFailure: true,
                updateEmailOtpError: action.payload,
            }
        case UPDATE_USER.SEND_USER_PHONE_OTP_REQUEST:
            return {
                ...state,
                sendPhoneLoading: true,
                sendPhoneSuccess: false,
                sendPhoneFailure: false,
                sendPhoneError: null
            }
        case UPDATE_USER.SEND_USER_PHONE_OTP_SUCCESS:
            return {
                ...state,
                sendPhoneLoading: false,
                sendPhoneSuccess: true,
                sendPhoneFailure: false,
                sendPhoneError: null,
                sendPhone: action.payload
            }
        case UPDATE_USER.SEND_USER_PHONE_OTP_FAILURE:
            return {
                ...state,
                sendPhoneLoading: false,
                sendPhoneSuccess: false,
                sendPhoneFailure: true,
                sendPhoneError: action.payload,
            }
        case UPDATE_USER.VERIFY_USER_PHONE_OTP_REQUEST:
            return {
                ...state,
                verifyPhoneLoading: true,
                verifyPhoneSuccess: false,
                verifyPhoneFailure: false,
                verifyPhoneError: null
            }
        case UPDATE_USER.VERIFY_USER_PHONE_OTP_SUCCESS:
            return {
                ...state,
                verifyPhoneLoading: false,
                verifyPhoneSuccess: true,
                verifyPhoneFailure: false,
                verifyPhoneError: null,
                verifyPhone: action.payload
            }
        case UPDATE_USER.VERIFY_USER_PHONE_OTP_FAILURE:
            return {
                ...state,
                verifyPhoneLoading: false,
                verifyPhoneSuccess: false,
                verifyPhoneFailure: true,
                verifyPhoneError: action.payload,
            }
        case UPDATE_USER.UPDATE_USER_PHONE_OTP_REQUEST:
            return {
                ...state,
                updatePhoneLoading: true,
                updatePhoneSuccess: false,
                updatePhoneFailure: false,
                updatePhoneError: null
            }
        case UPDATE_USER.UPDATE_USER_PHONE_OTP_SUCCESS:
            return {
                ...state,
                updatePhoneLoading: false,
                updatePhoneSuccess: true,
                updatePhoneFailure: false,
                updatePhoneError: null,
                updatePhone: action.payload
            }
        case UPDATE_USER.UPDATE_USER_PHONE_OTP_FAILURE:
            return {
                ...state,
                updatePhoneLoading: false,
                updatePhoneSuccess: false,
                updatePhoneFailure: true,
                updatePhoneError: action.payload,
            }
        default: return state
    }
}