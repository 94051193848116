import React, { useState } from 'react'
import { Button, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactCodeInput from 'react-verification-code-input';
import { FieldError, IMAGES } from '../../assets'
import { PATH } from '../../config';
import { useAuth } from '../../Navigation/Auth/ProvideAuth';
import { otpVerification } from '../../redux/actions/patientSignup';

export default function Verify() {
    let dispatch = useDispatch();
    let auth = useAuth();
    let location = useLocation()
    let signUpuser = useSelector((state) => state.newPatient);
    const { register, handleSubmit, errors, watch } = useForm()
    const [toggle, setToggle] = useState('main')
    const [EmailOtp, setEmailOtp] = useState('')
    const [PhoneOtp, setPhoneOtp] = useState('')
    const handleOtp = (data) => {
        dispatch(otpVerification(data, localStorage.token, success))
    }
    function success() {
        toast.success("Code verification successful", { position: toast.POSITION.TOP_RIGHT })
        setToggle('success')
    }
    return (
        <div className='content_body'>
            {/* <div className='row'>
                <div className='doc_image'>
                    <span className='sage_mini_logo'>
                        <img className='mini_logo' src={IMAGES.SLAKELOGO} alt='' />
                    </span>
                    <img src={IMAGES.MALEDOC} className='rightside_image' alt='' />
                </div>
            </div> */}
            {
                toggle === "main" &&
                <section>
                    <div className='row mt-3'>
                        <div className='col-sm-12 col-md-12 col-lg-5 col-xl-5'>
                            <Card className='custom_card_inner_styling_verifyOtp_signUp_page'>
                                <Card.Body>
                                    <div className='row'>
                                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                            <div className='Login_heading_div'>
                                                <h1>Sign up</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                            <div className='Email_otp_sub_heading_div'>
                                                <span>Enter Your Verification Code</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                            <div className='Email_otp_sub_text_div'>
                                                <span>
                                                    The First half code is send to your email and the second
                                                    half code is send to your mobile number
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <form onSubmit={handleSubmit(handleOtp)}>
                                        <div className='row mt-3'>
                                            <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                                                <Card className='Email_otp_card'>
                                                    <Card.Body>
                                                        <Card.Img variant='top' src={IMAGES.EMAIL_ENV} />
                                                        <div className='row pl-3 pr-3'>
                                                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 text-justify'>
                                                                <span className='primary_text'>
                                                                    Enter{' '}
                                                                    <b style={{ color: '#000' }}>first half code</b>{' '}
                                                                    from your
                                                                </span>
                                                                <span>
                                                                    {' '}
                                                                    <b className=''>Email</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-3'>
                                                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 opt_input_div'>
                                                                <div className='row'>
                                                                    <div className='form-group col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center'>
                                                                        <ReactCodeInput
                                                                            name={'emailOTP'}
                                                                            value={EmailOtp}
                                                                            onChange={e => setEmailOtp(e)}
                                                                            fields={4}
                                                                            type='text'
                                                                        />

                                                                        <input
                                                                            type='text'
                                                                            name={'emailOTP'}
                                                                            ref={register({ required: true })}
                                                                            value={EmailOtp}
                                                                            onChange={e => setEmailOtp(e)}
                                                                            style={{
                                                                                display: 'none'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                                                <Card className='Email_otp_card'>
                                                    <Card.Body>
                                                        <Card.Img variant='top' src={IMAGES.YELLOW_PHONE} />
                                                        <div className='row pl-3 pr-3'>
                                                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 text-justify'>
                                                                <span className='primary_text'>
                                                                    Enter{' '}
                                                                    <b style={{ color: '#000' }}>
                                                                        second half code
                                                                    </b>{' '}
                                                                    from your
                                                                </span>
                                                                <span>
                                                                    {' '}
                                                                    <b className=''>phone number</b>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className='row mt-3'>
                                                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 opt_input_div'>
                                                                <div className='row'>
                                                                    <div className='form-group col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center'>
                                                                        <ReactCodeInput
                                                                            value={PhoneOtp}
                                                                            onChange={e => setPhoneOtp(e)}
                                                                            fields={4}
                                                                            type='text'
                                                                            name={'phoneOTP'}

                                                                        />

                                                                        <input
                                                                            type='text'
                                                                            name={'phoneOTP'}
                                                                            onChange={e => setPhoneOtp(e)}
                                                                            ref={register({ required: true })}
                                                                            value={PhoneOtp}
                                                                            style={{
                                                                                display: 'none'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='form-group col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                                {
                                                    signUpuser && signUpuser.verifyOtpLoading === true ?
                                                        <Loader type="TailSpin" className="text-right" color="#005d38" height={40} width={40} /> :
                                                        <Button className='signUp_button' type='submit'>
                                                            Verify
                                                        </Button>
                                                }
                                                {
                                                    signUpuser && signUpuser.verifyOtpFailure === true &&
                                                    <FieldError message={signUpuser.verifyOtpError} />

                                                }
                                            </div>

                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                                <div className='link_div'>
                                                    <label>
                                                        Didn't receive code ?
                                                        <span className='signIn_link'> Resend</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                    <footer>
                        <div className='row'>
                            <div className='login_footer col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-0'>
                                Designed and developed by <b style={{ color: '#015D38' }}>XEVEN SOLUTIONS</b>
                            </div>
                        </div>
                    </footer>
                </section>}
            {toggle === "success" &&
                < SuccessScreen />
            }
        </div>
    )
}

function SuccessScreen({ toggle }) {
    let history = useHistory();
    let location = useLocation()
    const [planData, setPlanData] = useState(null)

    // useEffect(() => {
    //   setPlanData({ Data: location && location.params !== null && location.params.Data })
    // }, [])
    setTimeout(() => {
        history.push({
            pathname: PATH.PAYMENT,
            //   params: { Data:location && location.params !== null && location.params
            // }
        })
    }, 2000);
    return (
        <div className='row mt-3'>
            <div className='col-sm-12 col-md-12 col-lg-4 col-xl-4'>
                <Card className='custom_card_inner_styling_signUp_page'>
                    <Card.Body>
                        <div className='row mt-5'>
                            <div className='col-md-12 p-0'>
                                <svg
                                    className='checkmark'
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 52 52'
                                >
                                    <circle
                                        className='checkmark__circle'
                                        cx='26'
                                        cy='26'
                                        r='25'
                                        fill='none'
                                    />
                                    <path
                                        className='checkmark__check'
                                        fill='none'
                                        d='M14.1 27.2l7.1 7.2 16.7-16.8'
                                    />
                                </svg>
                            </div>
                        </div>
                        <div className='row  mt-5 mb-5'>
                            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center'>
                                <h2 className='success_heading'>Verified Successfully</h2>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </div>
    )
}