import { PAYMENT } from "../actions/utilities";
import axios from "axios";
import { APP_SETTINGS } from "../../config";

export const newPaymentApi = (auth, data) => {
    return axios.post(APP_SETTINGS.API_PATH.PAYMENT.addNewPayment, data, {
        headers: {
            Authorization: 'Bearer ' + auth
        }
    })
}
export const putNewPaymentApi = (auth, data) => {
    return axios.put(APP_SETTINGS.API_PATH.PAYMENT.putNewPayment, data, {
        headers: {
            Authorization: 'Bearer ' + auth
        }
    })
}