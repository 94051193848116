import { userCardDetailsApi } from "../apis/cardDetails";
import { CARDDETAILS, success, failure, request } from "./utilities";

export const cardDetails = (auth) => {
    return (dispatch) => {
        dispatch(request(CARDDETAILS.GET_CARDS_DETAILS_REQUEST));
        userCardDetailsApi(auth).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(CARDDETAILS.GET_CARDS_DETAILS_SUCCESS, response.data.data))
                }
                else {
                    dispatch(failure(CARDDETAILS.GET_CARDS_DETAILS_FAILURE, response.data.message))
                }
            },
            error => {
                dispatch(failure(CARDDETAILS.GET_CARDS_DETAILS_FAILURE,
                    (error && error.response && error.response.data && error.response.data.message ?
                        error.response.data.message : error.message)))
            }
        )
    }
}
