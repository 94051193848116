import React, { useState, useEffect } from 'react'
import { Button, Card, Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { FaBuilding, FaCalendar, FaCity, FaRegEdit, FaMapPin, FaRegBuilding, FaUserFriends } from 'react-icons/fa'
import { MdSupervisedUserCircle } from 'react-icons/md'
import Loader from 'react-loader-spinner'
import PhoneInput from 'react-phone-number-input'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import ReactCodeInput from 'react-verification-code-input'
import { FieldError, IMAGES, ParentComponentWithSideBar } from '../../assets'
import { GENDER_LOOKUP, SALUTATION_LOOKUP, STATES_LOOKUP } from '../../config'
import { useAuth } from '../../Navigation/Auth/ProvideAuth'
import { getUserInfo, putUserInfo } from '../../redux/actions/getUserInfo'
import { newEmailAddress, newPhoneNumber, sendNewEmailOtp, sendNewPhoneOtp, verifyNewEmailOtp, verifyNewPhoneOtp } from '../../redux/actions/updateUser'


//Patient profile function to handle personal info

export default function Profile() {
    const { register, handleSubmit, errors, watch } = useForm()
    let dispatch = useDispatch();
    let auth = useAuth();
    let userUpdate = useSelector(state => state.updateUser)
    let patientData = useSelector(state => state.patient)
    const [UserData, setUserData] = useState({});

    var dateOfBirth = UserData && UserData.dob ? UserData.dob.split('T')[0] : UserData.dob;

    useEffect(() => {
        dispatch(getUserInfo(auth && auth.sage_user_patient.token, auth && auth.sage_user_patient.userId))
    }, [dispatch])
    useEffect(() => {
        if (patientData && patientData.userDetailsSuccess === true) {
            setUserData(patientData && patientData.userDetailsSuccess === true ? patientData.userDetails : {})
        }
    }, [dispatch, patientData])
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData({
            ...UserData,
            [name]: value,
        });
    };

    const updateInfoHandler = (data) => {
        let finalpayload = {
            ...data,
            email: '',
            phoneNumber: '',
        }
        dispatch(putUserInfo(auth && auth.sage_user_patient.token, auth && auth.sage_user_patient.patientId, finalpayload, moveToNext))
    }
    function moveToNext(data) {
        toast.success(data.message, { position: toast.POSITION.TOP_RIGHT });
        dispatch(getUserInfo(auth && auth.sage_user_patient.token, auth && auth.sage_user_patient.userId))
    }
    console.log("patientData", UserData)
    return (
        <ParentComponentWithSideBar>
            <section>
                <div className='row'>
                    <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                        <header>
                            <h1 className='dashboard_heading'>My Profile</h1>
                            <hr />
                        </header>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                        <div className='personal_info_div'>
                            <span>Personal Info</span>
                        </div>
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                        <div className='personal_info_from'>
                            <form onSubmit={handleSubmit(updateInfoHandler)}>
                                <div className='row'>
                                    <div className='form-group col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                                        <label className='form_labels'>Salutation:</label>
                                        <select
                                            className="form-control"
                                            name="salutationId"
                                            ref={register({ required: true })}
                                            value={UserData?.salutationId} onChange={e => handleInputChange(e)}
                                        >
                                            <option value="" selected hidden disabled>
                                                --Choose a Salutation--
                                            </option>
                                            {SALUTATION_LOOKUP &&
                                                SALUTATION_LOOKUP.map((item, index) => {
                                                    return (
                                                        <option
                                                            key={index}
                                                            id={item.lookupId}
                                                            value={item.lookupId}
                                                            defaultValue
                                                        >
                                                            {item.value}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                        <FaUserFriends className='myProfile_form_icon' />
                                    </div>
                                    <div className='form-group col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                        <label htmlFor='' className='form_labels'>Frist Name:</label>
                                        <input type='text' className='form-control' placeholder='First Name' name="firstName" value={UserData?.firstName} onChange={e => handleInputChange(e)} ref={register({ required: true })} />
                                        <FaRegEdit className='myProfile_form_icon' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='form-group col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                        <label htmlFor='' className='form_labels'>Middle Name:</label>
                                        <input type='text' className='form-control' placeholder='Middle Name' name="middleName" value={UserData?.middleName} onChange={e => handleInputChange(e)} ref={register({ required: true })} />
                                        <FaRegEdit className='myProfile_form_icon' />
                                    </div>
                                    <div className='form-group col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                        <label htmlFor='' className='form_labels'>Last Name:</label>
                                        <input type='text' className='form-control' placeholder='Last Name' name="lastName" value={UserData?.lastName} onChange={e => handleInputChange(e)} ref={register({ required: true })} />
                                        <FaRegEdit className='myProfile_form_icon' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='form-group col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                        <label className='form_labels'>Gender:</label>
                                        <select className='form-control' name="genderId" value={UserData?.gender} onChange={e => handleInputChange(e)} ref={register({ required: true })} >
                                            {
                                                GENDER_LOOKUP && GENDER_LOOKUP.map((item, index) => {
                                                    return (
                                                        <option key={index} value={item.lookupId}>{item.value}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                        <MdSupervisedUserCircle className='myProfile_form_icon' />
                                    </div>
                                    <div className='form-group col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                        <label htmlFor='' className='form_labels'>Date of Birth:</label>
                                        <input type='date' className='form-control' name="dob" placeholder='Dob' value={dateOfBirth} onChange={e => handleInputChange(e)} ref={register({ required: true })} />
                                        <FaCalendar className='myProfile_form_icon' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                        <div className='personal_info_div'>
                                            <span>Contact Info</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='form-group col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                        <label htmlFor='' className='form_labels'>Email:</label>
                                        <div className='profile_form_div'>
                                            <input type='email' className='form-control'
                                                name="email" placeholder='abc@sage.com' value={UserData?.email} onChange={e => handleInputChange(e)}
                                                ref={register({ required: true })}
                                                readOnly
                                            />
                                            <EmailOTP />
                                        </div>
                                        <FaRegEdit className='myProfile_form_icon' />
                                        {
                                            userUpdate && userUpdate.sendEmailOtpFailure === true &&
                                            <FieldError message={userUpdate.sendEmailOtpError} />
                                        }
                                    </div>
                                    <div className='form-group col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                        <label htmlFor='' className='form_labels'>Phone Number:</label>
                                        <PhoneInput
                                            defaultCountry='US'
                                            name='phoneNumber'
                                            className='form-control'
                                            placeholder='Phone number'
                                            value={UserData?.phoneNumber}
                                            onChange={e => handleInputChange(e)}
                                            disabled
                                        />
                                        <input name="phoneNumber" type={'text'} value={UserData?.phoneNumber} style={{ display: 'none' }}
                                            ref={register({ required: true })}
                                        />
                                        <PhoneOTP />
                                        {
                                            userUpdate && userUpdate.sendPhoneFaliure === true &&
                                            <FieldError message={userUpdate.sendPhoneError} />
                                        }
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                        <div className='personal_info_div'>
                                            <span>Address Info</span>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mt-4'>
                                    <div className='form-group col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                        <label htmlFor='' className='form_labels'>Street Address:</label>
                                        <input type='text' className='form-control' placeholder='Street 4...' name="street" value={UserData?.street} onChange={e => handleInputChange(e)} ref={register({ required: true })} />
                                        <FaMapPin className='myProfile_form_icon' />
                                    </div>
                                    <div className='form-group col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                        <label htmlFor='' className='form_labels'>City:</label>
                                        <input type='text' className='form-control' placeholder='Newyork' name="city" value={UserData?.city} onChange={e => handleInputChange(e)} ref={register({ required: true })} />
                                        <FaCity className='myProfile_form_icon' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='form-group col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                        <label htmlFor='' className='form_labels'>County:</label>
                                        <input type='text' className='form-control' placeholder='Alsaka' name="county" value={UserData?.county} onChange={e => handleInputChange(e)} ref={register({ required: true })} />
                                        <FaBuilding className='myProfile_form_icon' />
                                    </div>
                                    <div className='form-group col-sm-6 col-md-6 col-lg-6 col-xl-6'>
                                        <label htmlFor='' className='form_labels'>State:</label>
                                        {/* <input type='text' className='form-control' placeholder='Texas' value={State} onChange={e => setState(e.target.value)} /> */}
                                        <select
                                            className="form-control consultation_type"
                                            ref={register({ required: true })}
                                            name={"state"}
                                            value={UserData?.state} onChange={e => handleInputChange(e)}
                                            id="type"
                                        >
                                            <option>--Choose State--</option>
                                            {STATES_LOOKUP &&
                                                STATES_LOOKUP.map((item, index) => {
                                                    return (
                                                        <option
                                                            key={index}
                                                            value={item.value}
                                                        >
                                                            {item.label}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                        <FaRegBuilding className='myProfile_form_icon' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='form-group col-sm-12 col-md-12 col-lg-6 col-xl-6'>
                                        <label htmlFor='' className='form_labels'>Zip Code:</label>
                                        <input type='number' className='form-control'
                                            placeholder='1235'
                                            value={UserData?.zipCode}
                                            onChange={e => handleInputChange(e)}
                                            ref={register({ required: true })}
                                            maxLength='6'
                                            name="zipCode"
                                        />
                                        <FaRegEdit className='myProfile_form_icon' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='form-group col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex flex-row justify-content-end'>
                                        {
                                            patientData && patientData.updateUserRecordLoading === true ?
                                                <Loader
                                                    type="TailSpin"
                                                    className="text-right"
                                                    color="#005d38"
                                                    height={40}
                                                    width={40}
                                                /> :

                                                <Button className='update_info_button'
                                                    type='submit'
                                                >
                                                    Update
                                                </Button>
                                        }
                                    </div>
                                </div>
                            </form>
                            {
                                patientData && patientData.updateUserRecordFailure === true &&
                                <FieldError message={patientData.updateUserRecordError} />
                            }
                        </div>
                    </div>
                </div>
            </section>
        </ParentComponentWithSideBar>
    )
}
// Email Otp verification popup/modal
function EmailOTP() {
    let auth = useAuth();
    let dispatch = useDispatch();
    let userUpdate = useSelector(state => state.updateUser)
    const { register, handleSubmit, errors, watch } = useForm()
    const [show, setShow] = useState(false);
    const [EmailOtp, setEmailOtp] = useState('')
    const [toggleForm, setToggleForm] = useState("OTPFORM")

    const emailOTP = () => {
        dispatch(sendNewEmailOtp(auth.sage_user_patient.token, moveToNext))
    }
    const moveToNext = (data) => {
        toast.success(data.message, { position: toast.POSITION.TOP_RIGHT });
        handleShow()
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const verifyEmail = () => {
        let otpCod = EmailOtp
        dispatch(verifyNewEmailOtp(auth.sage_user_patient.token, otpCod, otpSuccess))
    }

    const otpSuccess = (data) => {
        toast.success(data.message, { position: toast.POSITION.TOP_RIGHT });
        setToggleForm("NEWFORM")
    }
    const newEmailhandler = (data) => {
        dispatch(newEmailAddress(auth.sage_user_patient.token, data, emailSuccess))
    }
    const emailSuccess = (data) => {
        toast.success(data.message, { position: toast.POSITION.TOP_RIGHT });
        handleClose()
    }
    return (
        <>
            {
                userUpdate && userUpdate.sendEmailOtpLoading === true ?
                    <Loader
                        type="TailSpin"
                        className="text-right mt-2"
                        color="#005d38"
                        height={40}
                        width={40}
                    /> :
                    <Button variant="primary" className="verify_button" onClick={emailOTP}> Send Code</Button>
            }

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                centered
                className='gold_price_Modal'
                aria-labelledby="Verify_otp"
            >
                <Modal.Header closeButton className='verify_Otp_popup'>
                    <Modal.Title id='Verify_otp'>Verification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row mt-2 mb-3'>
                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center'>
                            {
                                toggleForm === 'OTPFORM' &&
                                <h4 className='heading'>Enter Your Verification Code</h4>
                            }
                            {
                                toggleForm === 'OTPFORM' &&
                                <h4 className='heading'>Enter Your New email Address</h4>
                            }
                        </div>
                    </div>
                    {
                        toggleForm === 'OTPFORM' &&
                        <form onSubmit={handleSubmit(verifyEmail)}>
                            <div className='row'>
                                <div className='col-sm-12 col-md-12 col-lg-2 col-xl-2'></div>
                                <div className='col-sm-12 col-md-12 col-lg-8 col-xl-8'>
                                    <Card className='Email_otp_card'>
                                        <Card.Body>
                                            <Card.Img variant='top' src={IMAGES.EMAIL_ENV} />
                                            <div className='row pl-3 pr-3'>
                                                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 text-justify'>
                                                    <span className='primary_text'>
                                                        Code has been sent to your
                                                    </span>
                                                    <span>
                                                        {' '}
                                                        <b className=''>Email Address</b>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='row mt-3'>
                                                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 opt_input_div'>
                                                    <div className='row'>
                                                        <div className='form-group col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center'>
                                                            <ReactCodeInput
                                                                value={EmailOtp}
                                                                onChange={e => setEmailOtp(e)}
                                                                fields={4}
                                                                type='text'
                                                                name={'code '}

                                                            />

                                                            <input
                                                                type='text'
                                                                name={'code '}
                                                                onChange={e => setEmailOtp(e)}
                                                                ref={register({ required: true })}
                                                                value={EmailOtp}
                                                                style={{
                                                                    display: 'none'
                                                                }}
                                                            />
                                                            {
                                                                errors && errors.code &&
                                                                <FieldError message={"Verification Code is required"} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-3'>
                                                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 opt_input_div'>
                                                    <div className='row'>
                                                        <div className='form-group col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center'>
                                                            {
                                                                userUpdate && userUpdate.verifyEmailOtpLoading === true ?
                                                                    <Loader
                                                                        type="TailSpin"
                                                                        className="text-right"
                                                                        color="#005d38"
                                                                        height={40}
                                                                        width={40}
                                                                    /> :
                                                                    <Button className='signUp_button' type='submit'>
                                                                        Verify Code
                                                                    </Button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                userUpdate && userUpdate.verifyEmailOtpFailure === true &&
                                                <FieldError message={userUpdate.verifyEmailOtpError} />
                                            }
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-2 col-xl-2'></div>
                            </div>
                        </form>
                    }
                    {
                        toggleForm === 'NEWFORM' &&
                        <form onSubmit={handleSubmit(newEmailhandler)}>
                            <div className='row'>
                                <div className='form-group col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                    <label htmlFor='' className='form_labels'>Email:</label>
                                    <input type='email' className='form-control' placeholder='abc@sage.com' name='key'
                                        ref={register({ required: true })}
                                    />
                                    <FaRegEdit className='myProfile_form_icon' />
                                    {
                                        errors && errors.key &&
                                        <FieldError message={"Email Address is required"} />
                                    }
                                </div>
                            </div>
                            <div className='row'>
                                <div className='form-group col-sm-4 col-md-12 col-lg-12 col-xl-12'>
                                    {
                                        userUpdate && userUpdate.updateEmailOtpLoading === true ?
                                            <Loader
                                                type="TailSpin"
                                                className="text-right"
                                                color="#005d38"
                                                height={40}
                                                width={40}
                                            /> :
                                            <Button className='signUp_button' type='submit'>
                                                Update
                                            </Button>
                                    }
                                </div>
                            </div>
                            {
                                userUpdate && userUpdate.updateEmailOtpFailure === true &&
                                <FieldError message={userUpdate.updateEmailOtpError} />
                            }
                        </form>
                    }
                </Modal.Body>

            </Modal>
        </>
    );
}
// Phone Otp verification popup/modal
function PhoneOTP() {
    let auth = useAuth();
    let dispatch = useDispatch();
    let userUpdate = useSelector(state => state.updateUser)
    const { register, handleSubmit, errors, watch } = useForm()
    const [show, setShow] = useState(false);
    const [PhoneOtp, setPhoneOtp] = useState('')
    const [Phone, setPhone] = useState('')
    const [toggleForm, setToggleForm] = useState("OTPFORM")
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const phoneOtpSend = () => {
        dispatch(sendNewPhoneOtp(auth.sage_user_patient.token, onOtpSuccess))
    }
    const onOtpSuccess = (data) => {
        toast.success(data.message, { position: toast.POSITION.TOP_RIGHT });
        handleShow()
    }
    const phoneotpVerify = (data) => {
        dispatch(verifyNewPhoneOtp(auth.sage_user_patient.token, PhoneOtp, onSuccess))
    }
    const onSuccess = (data) => {
        toast.success(data.message, { position: toast.POSITION.TOP_RIGHT });
        setToggleForm("NEWFORM")
    }
    const phoneUpdate = (data) => {
        dispatch(newPhoneNumber(auth.sage_user_patient.token, data, onUpdateSuccess))
    }
    const onUpdateSuccess = (data) => {
        toast.success(data.message, { position: toast.POSITION.TOP_RIGHT });
        handleClose()
    }
    return (
        <>
            {
                userUpdate && userUpdate.sendPhoneLoading === true ?
                    <Loader
                        type="TailSpin"
                        className="text-right mt-2"
                        color="#005d38"
                        height={40}
                        width={40}
                    /> :
                    <Button variant="primary" className="verify_button" onClick={phoneOtpSend}>Send Code</Button>
            }
            <Modal
                show={show}
                onHide={handleClose}
                centered
                backdrop="static"
                className='gold_price_Modal'
                aria-labelledby="Verify_otp"
            >
                <Modal.Header closeButton className='verify_Otp_popup'>
                    <Modal.Title id='Verify_otp'>Verification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row mt-2 mb-3'>
                        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center'>
                            {toggleForm === 'OTPFORM' &&
                                <h4 className='heading'>Enter Your Verification Code</h4>
                            }
                            {toggleForm === 'NEWFORM' &&
                                <h4 className='heading'>Enter Your New Phone Number</h4>
                            }
                        </div>
                    </div>
                    {
                        toggleForm === 'OTPFORM' &&
                        <form onSubmit={handleSubmit(phoneotpVerify)}>
                            <div className='row'>
                                <div className='col-sm-12 col-md-12 col-lg-2 col-xl-2'></div>
                                <div className='col-sm-12 col-md-12 col-lg-8 col-xl-8'>
                                    <Card className='Email_otp_card'>
                                        <Card.Body>
                                            <Card.Img variant='top' src={IMAGES.YELLOW_PHONE} />
                                            <div className='row pl-3 pr-3'>
                                                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 text-justify'>
                                                    <span className='primary_text'>
                                                        Code has been sent to your
                                                    </span>
                                                    <span>
                                                        {' '}
                                                        <b className=''>phone number</b>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='row mt-3'>
                                                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 opt_input_div'>
                                                    <div className='row'>
                                                        <div className='form-group col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center'>
                                                            <ReactCodeInput
                                                                value={PhoneOtp}
                                                                onChange={e => setPhoneOtp(e)}
                                                                fields={4}
                                                                type='text'
                                                                name={'PhoneOtp'}

                                                            />

                                                            <input
                                                                type='text'
                                                                name={'PhoneOtp'}
                                                                onChange={e => setPhoneOtp(e)}
                                                                ref={register({ required: true })}
                                                                value={PhoneOtp}
                                                                style={{
                                                                    display: 'none'
                                                                }}
                                                            />
                                                            {
                                                                errors && errors.PhoneOtp &&
                                                                <FieldError message={"Verification Code is required"} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mt-3'>
                                                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 opt_input_div'>
                                                    <div className='row'>
                                                        <div className='form-group col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center'>
                                                            {
                                                                userUpdate && userUpdate.verifyPhoneLoading === true ?
                                                                    <Loader
                                                                        type="TailSpin"
                                                                        className="text-right"
                                                                        color="#005d38"
                                                                        height={40}
                                                                        width={40}
                                                                    /> :
                                                                    <Button className='signUp_button' type='submit'>
                                                                        Verify Code
                                                                    </Button>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                userUpdate && userUpdate.verifyPhoneFailure === true &&
                                                <FieldError message={userUpdate.verifyPhoneError} />
                                            }
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-2 col-xl-2'>
                                </div>
                            </div>
                        </form>
                    }
                    {
                        toggleForm === 'NEWFORM' &&
                        <form onSubmit={handleSubmit(phoneUpdate)}>
                            <div className='row'>
                                <div className='form-group col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                                    <label htmlFor='' className='form_labels'>Phone Number:</label>
                                    <PhoneInput
                                        defaultCountry='US'
                                        name='key'
                                        className='form-control'
                                        placeholder='Phone number'
                                        country={'us'}
                                        value={Phone}
                                        // className="myProfile_form_PhoneInput"
                                        onChange={(value) => setPhone(value)}
                                    />
                                    <input name="key" type={'text'} value={Phone} ref={register({ required: true })} style={{ display: 'none' }} />
                                    {
                                        errors && errors.key === true &&
                                        <FieldError message={"Phone Number is required"} />
                                    }
                                </div>
                            </div>
                            <div className='row'>
                                <div className='form-group col-sm-4 col-md-12 col-lg-12 col-xl-12'>
                                    {
                                        userUpdate && userUpdate.updatePhoneLoading === true ?
                                            <Loader
                                                type="TailSpin"
                                                className="text-right"
                                                color="#005d38"
                                                height={40}
                                                width={40}
                                            /> :
                                            <Button className='signUp_button' type='submit'>
                                                Update
                                            </Button>
                                    }
                                </div>
                            </div>
                            {
                                userUpdate && userUpdate.updatePhoneFailure === true &&
                                <FieldError message={userUpdate.updatePhoneError} />
                            }
                        </form>
                    }

                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Verify
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </>
    );
}