import React, { useEffect, useState } from 'react'
import { PATH } from '../../config'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { IMAGES } from '../../assets/images'
import DatePicker from 'react-datepicker'
import { FaUserAlt } from 'react-icons/fa'
import { CgMenuGridO } from 'react-icons/cg'
import { BsCalendar2WeekFill } from 'react-icons/bs'
import { Button } from 'react-bootstrap'
import { newPayment } from '../../redux/actions/payment'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from '../../Navigation/Auth/ProvideAuth'
import { useForm } from 'react-hook-form'
import { FieldError } from '../../assets'
import { toast } from 'react-toastify'
import Loader from 'react-loader-spinner'
import { useCookies } from 'react-cookie'

export default function Payment() {
  let location = useLocation()
  let history = useHistory()
  let cardDetails = useSelector(state => state.payment)
  const { register, handleSubmit, errors, watch } = useForm()
  const [startDate, setStartDate] = useState()
  const [yearDate, setYearDate] = useState()
  let dispatch = useDispatch()
  let auth = useAuth();
  let PaymentData = JSON.parse(localStorage.plandata);
  const handlePayment = (data) => {
    let month = startDate
    var time = month.getHours() + ':' + month.getMinutes() + ':' + month.getSeconds()
    let newD = data.expMonth.toString().replace('00:00:00', time)
    var monthDate = new Date(newD).getMonth();
    let newDt = data.expYear.toString().replace('00:00:00', time)
    var yearDate_ = new Date(newDt).getFullYear();
    // history.push({
    //   pathname: PATH.DASHBOARD
    // })
    let finalPayload = {
      ...data,
      planId: PaymentData.planId,
      expMonth: ++monthDate,
      expYear: yearDate_,
    }
    dispatch(newPayment(localStorage.token, finalPayload, moveToNext))
  }
  const moveToNext = (data) => {
    toast.success("Payment Completed Now  You can Login.", { position: toast.POSITION.TOP_RIGHT })
    redirectHandler()
  }
  const redirectHandler = () => {
    setTimeout(() => {
      localStorage.clear()
      history.push({
        pathname: PATH.LOGIN,
      })
    }, 1000)
  }
  useEffect(() => {
    document.title = "Patient Payment || Sage";
  }, []);
  return (
    <div className=''>
      <header className='Payment_Header'>
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            <div className='payment_Header_div'>
              <div className='header_logo'>
                <img
                  className='header_logo_img'
                  src={IMAGES.SLAKELOGO}
                  alt=''
                />
              </div>
              <div className='header_heading'>
                <span className='header_heading_text'>Payment</span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className='row mt-lg-5'>
        <div className='col-sm-12 col-md-12 col-lg-8 col-xl-8'>
          {/* 
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
              <div className='payment_method_div'>
                <div className='method_heading'>
                  <span className='methods_heading_text'>Payment methods</span>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
              <div className='payment_card_div'>
                <div className='cards_images_div'>
                  <img
                    src={IMAGES.VISA}
                    alt=''
                    className='credit_cards_image_visa'
                  />
                  <img
                    src={IMAGES.MASTER}
                    alt=''
                    className='credit_cards_image_master'
                  />
                  <img
                    src={IMAGES.PAYPAL}
                    alt=''
                    className='credit_cards_image_paypal'
                  />
                </div>
              </div>
            </div>
          </div> */}
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
              <div className='payment_details_div'>
                <div className='method_heading'>
                  <span className='methods_heading_text'>Payment details</span>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
              <form onSubmit={handleSubmit(handlePayment)}>
                <div className='row mt-2'>
                  <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                    <div className='payment_form_div'>
                      <div className='label_div'>
                        <label htmlFor='name' className='form_labels'>
                          Card Holder Name
                        </label>
                        <label htmlFor='name' className='form_info_label'>
                          Enter your name mentioned on the card
                        </label>
                      </div>
                      <div className='input_div'>
                        <input
                          type='text'
                          id='name'
                          className='form-control'
                          name='name'
                          placeholder='e.g Michael Joseph'
                          ref={register({ required: true })}
                        />
                        <FaUserAlt className='credit_card_icons' />
                        {
                          errors && errors.name &&
                          <FieldError message={"Name is required"} />
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                    <div className='payment_form_div'>
                      <div className='label_div'>
                        <label htmlFor='card' className='form_labels'>
                          Card Number
                        </label>
                        <label htmlFor='card' className='form_info_label'>
                          Enter 16 - digits card number
                        </label>
                      </div>
                      <div className='input_div'>
                        <input
                          type='number'
                          id='card'
                          className='form-control'
                          name='number'
                          ref={register({ required: true })}
                          placeholder='e.g 5xxx xxxx xxxx xxxx'
                        />
                        <CgMenuGridO className='credit_card_icons' />
                        {
                          errors && errors.number &&
                          <FieldError message={"Card number is required"} />
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                    <div className='payment_form_div'>
                      <div className='label_div'>
                        <label htmlFor='date' className='form_labels'>
                          Expiry Date
                        </label>
                        <label htmlFor='date' className='form_info_label'>
                          Enter the expiry date of the card
                        </label>
                      </div>
                      <div className='input_div date_div'>
                        <DatePicker
                          id="date"
                          name='expMonth'
                          selected={startDate}
                          onChange={date => setStartDate(new Date(date))}
                          dateFormat='MM'
                          showMonthYearPicker
                          showFullMonthYearPicker
                          isClearable
                          className='form-control month_picker'
                          placeholderText='select a month'
                        />
                        <input name="expMonth" type={'text'} value={startDate}
                          ref={register({ required: true })} style={{ display: 'none' }} />

                        <DatePicker
                          name="expYear"
                          selected={yearDate}
                          onChange={date => setYearDate(new Date(date))}
                          showYearPicker
                          isClearable
                          dateFormat='yyyy'
                          className='form-control year_picker'
                          placeholderText='Select a year'
                        />
                        <input name="expYear" type={'text'} value={yearDate}
                          ref={register({ required: true })} style={{ display: 'none' }} />

                      </div>
                      <div className='d-flex flex-direction-row justify-content-between w-50'>

                        {
                          errors && errors.expMonth &&
                          <FieldError message={"Expiry month date is required"} />
                        }
                        {
                          errors && errors.expYear &&
                          <FieldError message={"Expiry year date is required"} />
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                    <div className='payment_form_div'>
                      <div className='label_div'>
                        <label htmlFor='cvv' className='form_labels'>
                          CVV Number
                        </label>
                        <label htmlFor='cvv' className='form_info_label'>
                          Enter 3 or 4 digit CVV number on the card
                        </label>
                      </div>
                      <div className='input_div'>
                        <input
                          type='number'
                          id='cvv'
                          name="cvc"
                          className='form-control'
                          placeholder='e.g 444'
                          ref={register({ required: true, maxLength: 4 })}
                          maxLength="5"
                        />
                        <BsCalendar2WeekFill className='credit_card_icons' />
                        {
                          errors && errors.cvc &&
                          <FieldError message={"Cvv number is required"} />
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mt-2'>
                  <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                    <div className='payment_Button_form_div'>
                      {
                        cardDetails && cardDetails.addNewPlaymentLoading === true ?
                          <Loader type="TailSpin" className="text-right" color="#005d38" height={40} width={40} /> :
                          <Button className='PayNow_button' type='submit'>
                            Pay Now
                          </Button>
                      }
                    </div>
                  </div>
                  <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2'>
                    <div className='payment_Button_form_div'>
                      {
                        cardDetails && cardDetails.addNewPlaymentFailure === true &&
                        <FieldError message={cardDetails.addNewPlaymentError} />
                      }
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='col-sm-12 col-md-12 col-lg-4 col-xl-4 d-sm-flex d-md-flex flex-sm-row flex-md-row justify-content-sm-center mt-sm-4 mt-md-4 mt-4 mt-lg-0'>
          <aside className='right_side_box'>
            <div className='row mt-3 mb-3'>
              <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center'>
                <span className='your_plan_text'>Your Plan</span>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center'>
                <div className='side_bar_vline'></div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center'>
                <img className='your_plan_image' src={IMAGES.PLANIMAGE} alt='plan_image' />
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                {PaymentData && PaymentData.planId > 3 &&
                  <div className='choosed_plan_div'>
                    <span className='platinum'>Sage {PaymentData.value}</span>
                  </div>
                }
                {PaymentData && PaymentData.planId < 4 &&
                  <div className='subs_text_div'>
                    <label>Subscrition Plan</label>
                    <div className='choosed_plan_div'>
                      <span>Sage {PaymentData.value}</span>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                <div className='Price_text_div'>
                  <label>Pricing Plan</label>
                  <div className='choosed_plan__price_div'>
                    <span>$ {PaymentData.price} / {PaymentData.subscriptionPlanType}</span>
                  </div>
                </div>
              </div>
            </div>
          </aside>
              </div>
      </div>
      <footer>
        <div className='row'>
          <div className='login_footer col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-0'>
            Designed and developed by <b style={{ color: '#015D38' }}>XEVEN SOLUTIONS</b>
          </div>
        </div>
      </footer>
    </div>
  )
}
