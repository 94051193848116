// A wrapper for <Route> that redirects to the login

import React from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { Header } from "../../assets";
import Sidebar from "../../assets/genericComponents/SideBar";
import { PATH } from "../../config";
import { useAuth } from "./ProvideAuth";

// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      onUpdate={() => window.scrollTo(0, 0)}
      render={({ location }) =>
        (auth.sage_user_patient &&
          auth.sage_user_patient.token &&
          auth.sage_user_patient.roleId) ? (
          <div className="main-wrapper">
            <Header />
            <Sidebar />
            {children}

          </div>

        ) : (
          <Redirect
            to={{
              pathname: PATH.LOGIN,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
export default PrivateRoute;
