
import { SUBSCRIPTION } from "../actions/utilities";
import axios from "axios";
import { APP_SETTINGS } from "../../config";

export const subscriptionApi = (auth) => {
    return axios.get(APP_SETTINGS.API_PATH.SUBSCRIPTION.getSubscription, {
        headers: {
            Authorization: 'Bearer ' + auth
        }
    })
}
export const delSubscriptionApi = (auth) => {
    return axios.delete(APP_SETTINGS.API_PATH.SUBSCRIPTION.deleteSubscription, {
        headers: {
            Authorization: 'Bearer ' + auth
        }
    })
}