import React, { useState, useEffect } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  PATH,
  SALUTATION_LOOKUP,
  GENDER_LOOKUP,
  STATES_LOOKUP,
} from "../../config";
import {  useHistory, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { IMAGES } from "../../assets/images/index";
import { FiEdit, FiUsers } from "react-icons/fi";
import { BiBuildings } from "react-icons/bi";
import { RiBuilding2Line, RiRoadMapLine } from "react-icons/ri";
import { FaBirthdayCake, FaCity, FaRegEnvelope } from "react-icons/fa";
import { MdSupervisedUserCircle } from "react-icons/md";
import { useForm } from "react-hook-form";
import ReactCodeInput from "react-verification-code-input";
import { FieldError } from "../../assets";
import {  toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  signUpPatient,
  otpVerification,
} from "../../redux/actions/patientSignup";
import { getCountryTimeZone } from "../../assets";
import { useCookies } from "react-cookie";
import { useAuth } from "../../Navigation/Auth/ProvideAuth";
import Loader from "react-loader-spinner";

export default function SignUp() {
  let location = useLocation();
  const [cookies, setCookies] = useCookies();
  const { register, handleSubmit, errors, watch } = useForm();
  const [phone, setPhone] = useState("");
  const [toggleScreen, setToggleScreen] = useState("signUp");
  const [startDate, setStartDate] = useState("");
  let history = useHistory();
  let dispatch = useDispatch();
  let signUpuser = useSelector((state) => state.newPatient);
  let user = signUpuser && signUpuser.addNewPatient !== null && signUpuser.addNewPatientSuccess === true && signUpuser.addNewPatient
  const { token } = signUpuser && signUpuser.addNewPatient !== null && signUpuser.addNewPatientSuccess === true && signUpuser.addNewPatient
  const SignUpHandler = (data) => {
    var dobDate = new Date();
    var time =
      dobDate.getHours() +
      ":" +
      dobDate.getMinutes() +
      ":" +
      dobDate.getSeconds();
    let newD = data.dob.toString().replace("00:00:00", time);
    var dd = new Date(newD).toISOString();

    let finalPayload = {
      ...data,
      dob: dd,
      photo: "String",
      timezone: getCountryTimeZone(),
      address:
        data.street +
        " " +
        data.city +
        " " +
        data.county +
        "," +
        data.state +
        "," +
        data.zipCode,
    };
    dispatch(signUpPatient(finalPayload, moveToNext));
  };
  function moveToNext(data) {
    success(data.message);
    let userData=data.data
    localStorage.setItem("token", userData.token)
  }

  function success(message) {
    toast.success(message, { position: toast.POSITION.TOP_RIGHT });
    history.push({
      pathname: PATH.VERIFY,
      params: { data: user }
    })
  }
  function dateHandler(date) {
    setStartDate(date);
  }
  const loginHandler = () => {
    history.push({
      pathname: PATH.LOGIN,
    });
  };
  const planIdhandler = () => {
    if (
      (location && location.params === undefined) ||
      (location && location.params === null)
    ) {
      history.push(PATH.SUBSCRIPTION);
    }
    localStorage.setItem("plandata",JSON.stringify(location && location.params&&location.params.planData))
  };

  useEffect(() => {
    planIdhandler();
  }, [location]);
  useEffect(() => {
    document.title = "Patient SignUp || Sage";
  }, []);
  return (
    <div className="content_body">
      <div className="row">
        <div className="doc_image">
          {/* <span className="sage_mini_logo">
            <img className="mini_logo" src={IMAGES.SLAKELOGO} alt="" />
          </span> */}
          {/* <img src={IMAGES.MALEDOC} className="rightside_image" alt="" /> */}
        </div>
      </div>
      {toggleScreen === "signUp" && (
        <div className="row mt-3">
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-4">
            <Card className="custom_card_inner_styling_signUp_page">
              <Card.Body>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="Login_heading_div">
                      <h1>Sign up</h1>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <form onSubmit={handleSubmit(SignUpHandler)}>
                      <div className="row">
                        <div className="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <select
                            className="form-control"
                            name="salutationId"
                            ref={register({ required: true })}
                          >
                            <option value="" selected hidden disabled>
                              --Choose a Salutation--
                            </option>
                            {SALUTATION_LOOKUP &&
                              SALUTATION_LOOKUP.map((item, index) => {
                                return (
                                  <option
                                    key={index}
                                    id={item.lookupId}
                                    value={item.lookupId}
                                    defaultValue
                                  >
                                    {item.value}
                                  </option>
                                );
                              })}
                          </select>
                          {errors && errors.salutationId && (
                            <FieldError message={"Salutation is required"} />
                          )}
                          <FiUsers className="inputField_icons" />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-sm-4 col-md-4 col-lg-4 col-xl-4">
                          <input
                            type="text"
                            className="form-control"
                            name="firstName"
                            placeholder="First name"
                            ref={register({ required: true })}
                          />
                          <FiEdit className="inputField_icons" />
                          {errors && errors.firstName && (
                            <FieldError message={"FIrst Name is required"} />
                          )}
                        </div>
                        <div className="form-group col-sm-4 col-md-4 col-lg-4 col-xl-4">
                          <input
                            type="text"
                            className="form-control"
                            name="middleName"
                            placeholder="Middle name"
                            ref={register({ required: true })}
                          />
                          <FiEdit className="inputField_icons" />
                          {errors && errors.middleName && (
                            <FieldError message={"Middle Name is required"} />
                          )}
                        </div>
                        <div className="form-group col-sm-4 col-md-4 col-lg-4 col-xl-4">
                          <input
                            type="text"
                            className="form-control"
                            name="lastName"
                            placeholder="Last name"
                            ref={register({ required: true })}
                          />
                          <FiEdit className="inputField_icons" />
                          {errors && errors.lastName && (
                            <FieldError message={"LastName is required"} />
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <PhoneInput
                            defaultCountry="US"
                            name="phoneNumber"
                            className="form-control"
                            placeholder="Phone
                      number"
                            disablecountrycode={true}
                            value={phone}
                            //   ref={register({ required: true })}
                            onChange={setPhone}
                          />
                          <input
                            name="phoneNumber"
                            type={"text"}
                            value={phone}
                            ref={register({ required: true })}
                            style={{ display: "none" }}
                          />
                          {errors && errors.phoneNumber && (
                            <FieldError message={"Phone number is required"} />
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <select
                            className="form-control"
                            name="genderId"
                            ref={register({ required: true })}
                          >
                            <option value="" selected hidden disabled>
                              --Choose a gender role--
                            </option>
                            {GENDER_LOOKUP &&
                              GENDER_LOOKUP.map((item, index) => {
                                return (
                                  <option
                                    key={index}
                                    id={item.lookupId}
                                    value={item.lookupId}
                                    defaultValue
                                  >
                                    {item.value}
                                  </option>
                                );
                              })}
                          </select>
                          {errors && errors.genderId && (
                            <FieldError message={"Gender role is required"} />
                          )}
                          <MdSupervisedUserCircle className="inputField_icons" />
                        </div>
                        <div className="form-group col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          {/* <input
                        type='date'
                        className='form-control'
                        placeholder='DOB'
                      /> */}
                          <DatePicker
                            name="dob"
                            timeFormat="p"
                            selected={startDate}
                            placeholderText="Date of birth"
                            className="form-control"
                            onChange={(date) => dateHandler(new Date(date))}
                          />
                          <input
                            name="dob"
                            type={"text"}
                            value={startDate}
                            ref={register({ required: true })}
                            style={{ display: "none" }}
                          />
                          <FaBirthdayCake className="inputField_icons" />
                          {errors && errors.dob && (
                            <FieldError message={"Date of birth is required"} />
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder="Email Address"
                            ref={register({ required: true })}
                          />
                          <FaRegEnvelope className="inputField_icons" />
                          {errors && errors.email && (
                            <FieldError message={"Email address is required"} />
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <select
                            className="form-control"
                            name="state"
                            ref={register({ required: true })}
                          >
                            <option value="" selected hidden disabled>
                              --Choose a State--
                            </option>
                            {STATES_LOOKUP &&
                              STATES_LOOKUP.map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                          </select>
                          <BiBuildings className="inputField_icons" />
                          {errors && errors.state && (
                            <FieldError message={"State is required"} />
                          )}
                        </div>
                        <div className="form-group col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <input
                            type="text"
                            className="form-control"
                            name="city"
                            placeholder="City"
                            ref={register({ required: true })}
                          />
                          <FaCity className="inputField_icons" />
                          {errors && errors.city && (
                            <FieldError message={"City name is required"} />
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          <input
                            type="text"
                            className="form-control"
                            name="street"
                            placeholder="Street Address"
                            ref={register({ required: true })}
                          />
                          <RiRoadMapLine className="inputField_icons" />
                          {errors && errors.street && (
                            <FieldError
                              message={"street address is required"}
                            />
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <input
                            type="text"
                            className="form-control"
                            name="county"
                            placeholder="County"
                            ref={register({ required: true })}
                          />
                          <RiBuilding2Line className="inputField_icons" />
                          {errors && errors.county && (
                            <FieldError message={"County is required"} />
                          )}
                        </div>
                        <div className="form-group col-sm-6 col-md-6 col-lg-6 col-xl-6">
                          <input
                            type="text"
                            className="form-control"
                            name="zipCode"
                            placeholder="Zip code"
                            ref={register({ required: true })}
                          />
                          <FiEdit className="inputField_icons" />
                          {errors && errors.zipCode && (
                            <FieldError message={"Zip code is required"} />
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12">
                          {signUpuser &&
                            signUpuser.addNewPatientLoading === true ? (
                            <Loader
                              type="TailSpin"
                              className="text-right"
                              color="#005d38"
                              height={40}
                              width={40}
                            />
                          ) : (
                            <Button
                              className="signUp_button"
                              type="submit"
                            // onClick={SignUpHandler}
                            >
                              Sign up
                            </Button>
                          )}
                          <TermsAndConditionsModal />
                        </div>
                      </div>
                    </form>
                    {signUpuser && signUpuser.addNewPatientFailure === true && (
                      <FieldError message={signUpuser.addNewPatientError} />
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="link_div">
                      <label>
                        Already have account?
                        <span className="signIn_link" onClick={loginHandler}>
                          {" "}
                          Sign In
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
      )}

      <footer>
        <div className="row">
          <div className="login_footer col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-0">
            Designed and developed by{" "}
            <b style={{ color: "#015D38" }}>XEVEN SOLUTIONS</b>
          </div>
        </div>
      </footer>
    </div>
  );
}

function VerifyOtp({ toggle }) {
  console.log(auth)
  let dispatch = useDispatch();
  let auth = useAuth();
  let signUpuser = useSelector((state) => state.newPatient);
  const { register, handleSubmit, errors, watch } = useForm();
  const [EmailOtp, setEmailOtp] = useState("");
  const [PhoneOtp, setPhoneOtp] = useState("");
  const handleOtp = (data) => {
    dispatch(otpVerification(data, auth.sage_user_patient_reg.token, success));
  };
  function success() {
    toast.success("Code verification successful", {
      position: toast.POSITION.TOP_RIGHT,
    });
    toggle("success");
  }

  return (
    <>
      <section>
        <div className="row mt-3">
          <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
            <Card className="custom_card_inner_styling_verifyOtp_signUp_page">
              <Card.Body>
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="Login_heading_div">
                      <h1>Sign in</h1>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="Email_otp_sub_heading_div">
                      <span>Enter Your Verification Code</span>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="Email_otp_sub_text_div">
                      <span>
                        The First half code is send to your email and the second
                        half code is send to your mobile number
                      </span>
                    </div>
                  </div>
                </div>
                <form onSubmit={handleSubmit(handleOtp)}>
                  <div className="row mt-3">
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <Card className="Email_otp_card">
                        <Card.Body>
                          <Card.Img variant="top" src={IMAGES.EMAIL_ENV} />
                          <div className="row pl-3 pr-3">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-justify">
                              <span className="primary_text">
                                Enter{" "}
                                <b style={{ color: "#000" }}>first half code</b>{" "}
                                from your
                              </span>
                              <span>
                                {" "}
                                <b className="">Email</b>
                              </span>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 opt_input_div">
                              <div className="row">
                                <div className="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                                  <ReactCodeInput
                                    name={"emailOTP"}
                                    value={EmailOtp}
                                    onChange={(e) => setEmailOtp(e)}
                                    fields={4}
                                    type="text"
                                  />

                                  <input
                                    type="text"
                                    name={"emailOTP"}
                                    ref={register({ required: true })}
                                    value={EmailOtp}
                                    onChange={(e) => setEmailOtp(e)}
                                    style={{
                                      display: "none",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                      <Card className="Email_otp_card">
                        <Card.Body>
                          <Card.Img variant="top" src={IMAGES.YELLOW_PHONE} />
                          <div className="row pl-3 pr-3">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-justify">
                              <span className="primary_text">
                                Enter{" "}
                                <b style={{ color: "#000" }}>
                                  second half code
                                </b>{" "}
                                from your
                              </span>
                              <span>
                                {" "}
                                <b className="">phone number</b>
                              </span>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 opt_input_div">
                              <div className="row">
                                <div className="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                                  <ReactCodeInput
                                    value={PhoneOtp}
                                    onChange={(e) => setPhoneOtp(e)}
                                    fields={4}
                                    type="text"
                                    name={"phoneOTP"}
                                  />

                                  <input
                                    type="text"
                                    name={"phoneOTP"}
                                    onChange={(e) => setPhoneOtp(e)}
                                    ref={register({ required: true })}
                                    value={PhoneOtp}
                                    style={{
                                      display: "none",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      {signUpuser && signUpuser.verifyOtpLoading === true ? (
                        <Loader
                          type="TailSpin"
                          className="text-right"
                          color="#005d38"
                          height={40}
                          width={40}
                        />
                      ) : (
                        <Button className="signUp_button" type="submit">
                          Verify
                        </Button>
                      )}
                      {signUpuser && signUpuser.verifyOtpFailure === true && (
                        <FieldError message={signUpuser.verifyOtpError} />
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                      <div className="link_div">
                        <label>
                          Didn't receive code ?
                          <span className="signIn_link"> Resend</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </form>
              </Card.Body>
            </Card>
          </div>
        </div>
      </section>
    </>
  );
}
function SuccessScreen({ toggle }) {
  let history = useHistory();
  let location = useLocation();
  const [planData, setPlanData] = useState(null);

  // useEffect(() => {
  //   setPlanData({ Data: location && location.params !== null && location.params.Data })
  // }, [])
  setTimeout(() => {
    toggle("signUp");
    history.push({
      pathname: PATH.PAYMENT,
      //   params: { Data:location && location.params !== null && location.params
      // }
    });
  }, 3000);
  return (
    <div className="row mt-3">
      <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
        <Card className="custom_card_inner_styling_signUp_page">
          <Card.Body>
            <div className="row mt-5">
              <div className="col-md-12 p-0">
                <svg
                  className="checkmark"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 52 52"
                >
                  <circle
                    className="checkmark__circle"
                    cx="26"
                    cy="26"
                    r="25"
                    fill="none"
                  />
                  <path
                    className="checkmark__check"
                    fill="none"
                    d="M14.1 27.2l7.1 7.2 16.7-16.8"
                  />
                </svg>
              </div>
            </div>
            <div className="row  mt-5 mb-5">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center">
                <h2 className="success_heading">Verified Successfully</h2>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

function TermsAndConditionsModal() {
  let history = useHistory();
  const [lgShow, setLgShow] = useState(false);
  let dispatch = useDispatch();
  let signUpuser = useSelector((state) => state.newPatient);

  // const redirectHandler_monthly = () => {
  //   history.push(PATH.SIGNUP)
  // }
  return (
    <>
      <Modal
        size="xl"
        centered
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="terms_Modal"
        className="terms_Modal"
      >
        <Modal.Header closeButton className="terms_condition_Modal">
          <Modal.Title id="gold_pricing_modal">Select pricing plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <h1>Terms and confotion</h1>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
