import axios from "axios";
import { APP_SETTINGS } from "../../config";

//inside dashboard update email new otp generate function
export const sendEmailOtpApi = (auth) => {
    return axios.get(APP_SETTINGS.API_PATH.PATIENTPROFILE.sendEmailOtp, {
        headers: {
            Authorization: "Bearer " + auth,
        }
    })
}
export const verifyEmailOtpApi = (auth, code) => {
    return axios.get(`${APP_SETTINGS.API_PATH.PATIENTPROFILE.verifyEmailOtp}${code}`, {
        headers: {
            Authorization: "Bearer " + auth,
            

        }
    })
}
export const newEmailApi = (auth, data) => {
    return axios.put(APP_SETTINGS.API_PATH.PATIENTPROFILE.updateEmail, data, {
        headers: {
            Authorization: "Bearer " + auth,
        }
    })
}


//inside dashboard update phone new otp generate function
export const sendPhoneOtpApi = (auth) => {
    return axios.get(APP_SETTINGS.API_PATH.PATIENTPROFILE.sendPhoneOtp, {
        headers: {
            Authorization: "Bearer " + auth,
        }
    })
}
export const verifyPhoneOtpApi = (auth, code) => {
    return axios.get(`${APP_SETTINGS.API_PATH.PATIENTPROFILE.verifyPhoneOtp}${code}`, {
        headers: {
            Authorization: "Bearer " + auth,
            

        }
    })
}
export const newPhoneApi = (auth, data) => {
    return axios.put(APP_SETTINGS.API_PATH.PATIENTPROFILE.updatePhone, data, {
        headers: {
            Authorization: "Bearer " + auth,
        }
    })
}

//get user information
export const getUserRecordById = (auth,id) => {
    return axios.get(`${APP_SETTINGS.API_PATH.PATIENTPROFILE.getPatientRecordById}${id}`, {
        headers: {
            Authorization: "Bearer " + auth,
        }
    })
}

//Update user information
export const putUserRecordById = (auth,id,data) => {
    return axios.put(`${APP_SETTINGS.API_PATH.PATIENTPROFILE.puPatientRecordById}${id}`,data, {
        headers: {
            Authorization: "Bearer " + auth,
        }
    })
}