import { APP_SETTINGS } from "../../config";
import axios from "axios";

export const getAllPlansApi = () => {
    return axios.get(APP_SETTINGS.API_PATH.PLANS.getPlansList, {
        headers: {
            // Authorization: 'Bearer ' + auth.yodha_user.token
        }
    })
}

export const getPlansByIdApi = (id) => {
    return axios.get(`${APP_SETTINGS.API_PATH.PLANS.getPlansById}${id}`, {
        headers: {
            // Authorization: 'Bearer ' + auth.yodha_user.token
        }
    })
}