import { USER_DETAILS } from "../actions/utilities"

const initialState = {
    userDetailsLoading: false,
    userDetailsSuccess: false,
    userDetailsFailure: false,
    userDetailsError: null,
    userDetails: [],

    updateUserRecordLoading: false,
    updateUserRecordSuccess: false,
    updateUserRecordFailure: false,
    updateUserRecordError: null,
    updateUserRecord: [],
}

export const patientDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_DETAILS.GET_USER_DETAILS_REQUEST:
            return {
                ...state,
                userDetailsLoading: true,
                userDetailsSuccess: false,
                userDetailsFailure: false,
                userDetailsError: null,
            }
        case USER_DETAILS.GET_USER_DETAILS_SUCCESS:
            return {
                ...state,
                userDetailsLoading: false,
                userDetailsSuccess: true,
                userDetailsFailure: false,
                userDetailsError: null,
                userDetails: action.payload,
            }
        case USER_DETAILS.GET_USER_DETAILS_FAILURE:
            return {
                ...state,
                userDetailsLoading: false,
                userDetailsSuccess: false,
                userDetailsFailure: true,
                userDetailsError: action.payload,
            }
        case USER_DETAILS.PUT_USER_DETAILS_REQUEST:
            return {
                ...state,
                updateUserRecordLoading: true,
                updateUserRecordSuccess: false,
                updateUserRecordFailure: false,
                updateUserRecordError: null,
            }
        case USER_DETAILS.PUT_USER_DETAILS_SUCCESS:
            return {
                ...state,
                updateUserRecordLoading: false,
                updateUserRecordSuccess: true,
                updateUserRecordFailure: false,
                updateUserRecordError: null,
                updateUserRecord: action.payload,
            }
        case USER_DETAILS.PUT_USER_DETAILS_FAILURE:
            return {
                ...state,
                updateUserRecordLoading: false,
                updateUserRecordSuccess: false,
                updateUserRecordFailure: true,
                updateUserRecordError: action.payload,
            }
        default: return state
    }
}