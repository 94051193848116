import React from "react";

const style = {
    color: "#015D38",
}
export function FooterBar() {
    return (
        <footer>
            <div className="row">
                <div className="login_footer col-md-9 mb-0">
                    Designed and developed by{" "}
                    <b style={style}>XEVEN SOLUTIONS</b>
                </div>
                <div className="col-md-3">
                </div>
            </div>
        </footer>
    )
}