import { DASHBOARD, success, request, failure } from "./utilities"
import { getRecordApi } from "../apis/dashboardRecord"

export const getDashBoardRecord = (auth, moveToNext) => {
    return (dispatch) => {
        dispatch(request(DASHBOARD.GET_PATIENT_RECORD_REQUEST));
        getRecordApi(auth).then(
            response => {
                if (response.data.succeeded === true) {
                    dispatch(success(DASHBOARD.GET_PATIENT_RECORD_SUCCESS, response.data.data))
                    if (moveToNext) {
                        moveToNext(response.data)
                    }
                }
                else {
                    dispatch(failure(DASHBOARD.GET_PATIENT_RECORD_FAILURE, response.data.message))

                }
            },
            error => {
                dispatch(failure(DASHBOARD.GET_PATIENT_RECORD_FAILURE,
                    (error && error.response && error.response.data && error.response.data.message ?
                        error.response.data.message : error.message)
                ))

            }
        )
    }
}