import { SUBSCRIPTION } from "../actions/utilities";

const initialState = {
    getSubscriptionLoading: false,
    getSubscriptionSuccess: false,
    getSubscriptionFailure: false,
    getSubscriptionError: null,
    getSubscription: null,

    delSubscriptionLoading: false,
    delSubscriptionSuccess: false,
    delSubscriptionFailure: false,
    delSubscriptionError: null,
    delSubscription: null,
}

export const userSubscriptionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUBSCRIPTION.GET_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                getSubscriptionLoading: true,
                getSubscriptionSuccess: false,
                getSubscriptionFailure: false,
                getSubscriptionError: null,
            }
        case SUBSCRIPTION.GET_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                getSubscriptionLoading: false,
                getSubscriptionSuccess: true,
                getSubscriptionFailure: false,
                getSubscriptionError: null,
                getSubscription: action.payload,
            }
        case SUBSCRIPTION.GET_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                getSubscriptionLoading: false,
                getSubscriptionSuccess: false,
                getSubscriptionFailure: true,
                getSubscriptionError: action.payload,
            }

        case SUBSCRIPTION.DEL_SUBSCRIPTION_REQUEST:
            return {
                ...state,
                delSubscriptionLoading: true,
                delSubscriptionSuccess: false,
                delSubscriptionFailure: false,
                delSubscriptionError: null,
            }
        case SUBSCRIPTION.DEL_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                delSubscriptionLoading: false,
                delSubscriptionSuccess: true,
                delSubscriptionFailure: false,
                delSubscriptionError: null,
                delSubscription: action.payload,
            }
        case SUBSCRIPTION.DEL_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                delSubscriptionLoading: false,
                delSubscriptionSuccess: false,
                delSubscriptionFailure: true,
                delSubscriptionError: action.payload,
            }
        default: return state
    }

}