import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { IMAGES, ParentComponentWithSideBar } from '../../assets'
import Table from './table'
import { useHistory } from "react-router-dom"
import { PATH } from '../../config'
import { useDispatch, useSelector } from 'react-redux'
import { getDashBoardRecord } from '../../redux/actions/dashboardrecord'
import { useAuth } from '../../Navigation/Auth/ProvideAuth'

export default function DashBoard() {
  let dispatch = useDispatch();
  let auth = useAuth()
  let record = useSelector(state => state.dashBoardrecords)
  const [Data, setData] = useState([])

  let history = useHistory();
  function handleChangePlan() {
    history.push(PATH.CHANGEPLAN)
  }
  useEffect(() => {
    dispatch(getDashBoardRecord(auth.sage_user_patient.token))
  }, [dispatch, auth])

  useEffect(() => {
    setData(record && record.getRecordSuccess === true && record.getRecord ? record.getRecord : [])
  }, [dispatch, record])

  useEffect(() => {
    document.title = "Patient Dashboard || Sage";
  }, []);
  return (
    <ParentComponentWithSideBar>
      <section>
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            <header>
              <h1 className='dashboard_heading'>Patient Billing DashBoard</h1>
              <hr />
            </header>
          </div>
        </div>
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
            <h2 className='dashboard_sub_heading'>Billing history</h2>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-sm-12 col-md-12 col-lg-8 col-xl-8'>
            {
              record?.getRecord === null ?
                <div className='no_data_warning_div text-center'>
                  <label className="No_data_warning_text">No data availbale yet</label>

                </div> :
                <div className='choosed_plan_table_div'>
                  <Table />
                </div>
            }
          </div>
          <div className='col-sm-12 col-md-12 col-lg-4 col-xl-4 mt-4 mt-lg-0 d-flex flex-direction-row justify-content-center'>
            <aside className='right_side_box_dashboard'>
              <div className='row mt-3 mb-3'>
                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 '>
                  <div className='my_plan_div'>
                    <span className='choosed_plan_text'>My Plan</span>
                    <Button className='change_plan_btn' onClick={handleChangePlan}>Manage Plan</Button>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center'>
                  <div className='side_bar_vline_choosed_plan'></div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 text-center'>
                  <img
                    className='choosed_plan_image'
                    src={IMAGES.PLANIMAGE}
                    alt='plan_image'
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                  <div className='subs_text_div'>
                    <label>Subscrition Plan</label>
                    {
                      Data && Data.planId < 4 &&
                      <div className='choosed_plan_div'>
                        <span>Sage Gold</span>
                      </div>
                    }
                    {Data && Data.planId > 3 &&
                      <div className='choosed_plan_div'>
                        <span className='platinum'>Sage Platinum</span>
                      </div>}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12'>
                  <div className='Price_text_div'>
                    <label>Pricing Plan</label>
                    <div className='choosed_plan__price_div'>
                      {Data && Data.price ? (<span>$ {Data && Data.price} / {Data.subscriptionPlanType}</span>) : null}
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
        <footer>
          <div className='row'>
            <div className='login_footer col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-0'>
              Designed and developed by <b style={{ color: '#015D38' }}>XEVEN SOLUTIONS</b>
            </div>
          </div>
        </footer>
      </section>
    </ParentComponentWithSideBar>
  )
}
