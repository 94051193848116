import React from "react";
// import { FooterBar } from ".";

export function ParentComponentWithSideBar({ children, ...rest }) {
    return (
        <div className="page-wrapper">
            <div className="container-fluid">
                {children}
            </div>
            {/* <FooterBar /> */}
        </div>

    )
}
