import thunk from "redux-thunk";
import { compose, applyMiddleware, createStore } from "redux";
import { rootReducer } from '../reducer/index'
import logger from "redux-logger";



const devTools = process.env.NODE_ENV !== "production" && window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__()
    : (a) => a;
const middlewareThunnk = [thunk];
const middleWare = compose(applyMiddleware(...middlewareThunnk, logger))

const store = createStore(rootReducer, middleWare);
export { store };
