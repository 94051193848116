import React from "react";
import { BrowserRouter } from "react-router-dom";
import { RouterConfig } from "./Navigation/RouterConfig";
import { ProvideAuth } from "./Navigation/Auth/ProvideAuth";
import { ToastContainer } from "react-toastify";


function App() {
  
    // console.log("Your screen resolution is: " + window.screen.width * window.devicePixelRatio + "x" + window.screen.height * window.devicePixelRatio);
  
  return (
    <ProvideAuth>
      <ToastContainer autoClose={1500} />
      <BrowserRouter>
        <RouterConfig />
      </BrowserRouter>
    </ProvideAuth>

  );
}

export default App;
